import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Injectable } from '@angular/core';
import { EmployeeClockingV2, EmployeePunchRequest, EmployeePunchResponse, EmployeeClockingSearchCriteria } from '../../model/v2/employee.clocking';
import { EmployeeClockingSearch } from '../../../shared/model/search/employee.clocking.search';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from '../../../shared/model/PageResponse/base.response';
import { EmployeeSummaryDetail } from '../../../overrtime-dashboard/model/employee-summary-detail';
import * as MOMENT from 'moment';
import { EmployeeDetailExport } from './../../model/employee-detail-export.model';
import * as moment from 'moment';
import * as fs from 'file-saver';

@Injectable({ providedIn: 'root' })
export class EmployeeClockingServiceV2 extends AbstractServiceV2<EmployeeClockingV2, EmployeeClockingSearch> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CLOCKING, '');
  }

  doPunch(employeePunch: EmployeePunchRequest): Observable<BaseResponse<EmployeePunchResponse>> {
    //return this._http.post<BaseResponse<EmployeePunchResponse>>('http://localhost:8005/api/v2/clocking', employeePunch).pipe(map(res => res));
    return this._http.post<BaseResponse<EmployeePunchResponse>>(this.baseUrlV2, employeePunch).pipe(map(res => res));
  }

  verifyNextStep(employeeId: any, params:any): Observable<BaseResponse<EmployeePunchResponse>> {
    //const reqUrl = `http://localhost:8005/api/v2/clocking/${Constants.URI.EMPLOYEECLOCKING.DO_NEXT_STEP}/${employeeId}`;
    const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEECLOCKING.DO_NEXT_STEP}/${employeeId}`;
    return this._http.get<any>(reqUrl, {params:params}).pipe(map(resp => resp));
  }

  search(options: any): Observable<EmployeePunchResponse> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    const reqUrl = `${this.baseUrlV2}/search`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/search`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  search2(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/search`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/search`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  getSummaryDetail(options: any): Observable<BaseResponse<EmployeeSummaryDetail>> {
    // options.toDate = MOMENT(options.toDate).toISOString(true);
    // options.fromDate = MOMENT(options.fromDate).toISOString(true);
    // const timeZonefromDate = (options.fromDate.getTimezoneOffset() / 60) * -1;
    // options.fromDate.setTime(options.fromDate.getTime() + (timeZonefromDate * 60) * 60 * 1000);
    // options.fromDate.toISOString();
    // const timeZonetoDate = (options.toDate.getTimezoneOffset() / 60) * -1;
    // options.toDate.setTime(options.toDate.getTime() + (timeZonetoDate * 60) * 60 * 1000);
    // options.toDate.toISOString();
    const reqUrl = `${this.baseUrlV2}/summary-detail`;
    return this._http.post<BaseResponse<EmployeeSummaryDetail>>(reqUrl, options).pipe(map(res => res));
  }

  getOTPrevent(options: any): Observable<any> {
    const param = { page: options.page, size: options.size };
    const reqUrl = `${this.baseUrlV2}/prevention`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/prevention`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  getLunchTime(employeeId: Number): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/lunch-hour/${employeeId}`;
    return this._http.get<any>(reqUrl).pipe(map(resp => resp));
  }

  exportEmployee(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/prevention/export-employee`;
    //return this._http.post<any>('http://localhost:8005/api/v2/clocking/prevention/export-employee', params).pipe(map(resp => resp));
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  exportManager(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/prevention/export-manager`;
    //return this._http.post<any>('http://localhost:8005/api/v2/clocking/prevention/export-manager', params).pipe(map(resp => resp));
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  exportPDF(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/prevention/export-pdf`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  punchHistoryExportPDF(params: any): Observable<any> {
    // const reqUrl = `http://localhost:8005/api/v2/clocking/punch-history/export`;
    const reqUrl = `${this.baseUrlV2}/punch-history/export`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  dashBoardExportPDF(params: EmployeeDetailExport): Observable<any> {
    // const reqUrl = `http://localhost:8005/api/v2/clocking/summary-detail/export-pdf`;
    const reqUrl = `${this.baseUrlV2}/summary-detail/export-pdf`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  dashBoardExportExcelV2(params: EmployeeDetailExport): Observable<Blob> {
    // const reqUrl = `http://localhost:8005/api/v2/clocking/summary-detail/export-excel-v2`;
    const reqUrl = `${this.baseUrlV2}/summary-detail/export-excel-v2`;
    return this._http.post(reqUrl, params, {responseType: 'blob'} ).map(res => res);
  }

  dashBoardExportExcel(options: any): Observable<Blob> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    const params = {
        page: options.page,
        size: options.size
    };
    if (options.sortField != null) {
        params['sortField'] = options.sortField;
    }
    if (options.sortOrder != null) {
        params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    // const reqUrl = `http://localhost:8005/api/v2/clocking/summary-detail/export-excel`;
    const reqUrl = `${this.baseUrlV2}/summary-detail/export-excel`;
    return this._http.post(reqUrl, options, { params: params, responseType: 'blob'} ).map(res => res);
  }

  employeeTimiSheet(options: any): Observable<any> {
    options.toDate = moment(options.toDate);
    options.fromDate = moment(options.fromDate);
    const reqUrl = `${this.baseUrlV2}/timesheet/search`;
    return this._http.post(reqUrl, options).pipe(map(resp => resp));
  }

  sendFileToMail(start, end, timeSheetPDFModel): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/timesheet/export-pdf`, timeSheetPDFModel).pipe(map(resp => resp));
  }
  
  sendFileToMailAction(timeSheetPDFModel): Observable<any> {
    // return this._http.post<any>(`http://localhost:8005/api/v2/clocking/timesheet/send-to-email`, timeSheetPDFModel).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}/timesheet/send-to-email`, timeSheetPDFModel).pipe(map(resp => resp));
  }

  getOverTimeIncrease(id: any) {
    return this._http.get<any>(this.baseUrlV2 + '/ot-increase/' + id).pipe(map(resp => resp));
  }

  sendPunchHistoryDataToEmployee(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/send-employee`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  sendPunchHistoryDataToFinance(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/send-finance`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  getNumOfClocking(employeeId: any): Observable<any>  {
    const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEECLOCKING.COUNT}/${employeeId}`;
    return this._http.get<any>(reqUrl).pipe(map(resp => resp));
  }

  updateTimeCorrection(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/time-correction`;
    return this._http.put<any>(reqUrl, options).pipe(map(resp => resp));
  }

  updateHolidayManually(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/holiday-manual`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  sendLastWeekDataToEmployee(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/last-week-report`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  checkEmployeeOnline(options: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/check-online`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  getEmployeeTimeHistory(options: any): Observable<any> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    // return this._http.post<any>(`http://localhost:8005/api/v2/clocking/employee-time-history`, options).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}/employee-time-history`, options).pipe(map(resp => resp));
  }

  employeeClockingStatus(options: any) {
    return this._http.post(this.baseUrlV2 + `/employee-clocking-status`, options).pipe(map(resp => resp));
  }

  saveEmployeeClocking(options: any) {
    // return this._http.post(`http://localhost:8005/api/v2/clocking/create-list`, options).pipe(map(resp => resp));
    return this._http.post(this.baseUrlV2 + `/create-list`, options).pipe(map(resp => resp));
  }
  saveEmployeeClockingImport(options: any) {
    // return this._http.post(`http://localhost:8005/api/v2/clocking/create-list-import`, options).pipe(map(resp => resp));
    return this._http.post(this.baseUrlV2 + `/create-list-import`, options).pipe(map(resp => resp));
  }

  searchRecollection(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/search-recollection`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/search-recollection`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  exportPdfRecollection(data): Observable<any> {
    return this._http.post(`${this.baseUrlV2}/export-pdf-recollection`, data).pipe(map(resp => resp));
  }

  updateLunchHourHistory(options: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/update-lunch-hour-history`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  acceptFaceImg(id: any, type: any, lunchHistoryId: any) {
    let reqUrl = `${this.baseUrlV2}/accept-face-img/${id}?type=${type}`;
    if (lunchHistoryId) {
      reqUrl = `${reqUrl}&lunchHistoryId=${lunchHistoryId}`;
    }
    return this._http.post<any>(reqUrl, {}).pipe(map(resp => resp));
  }

  rejectFaceImg(id: any, type: any, lunchHistoryId: any) {
    let reqUrl = `${this.baseUrlV2}/reject-face-img/${id}?type=${type}`;
    if (lunchHistoryId) {
      reqUrl = `${reqUrl}&lunchHistoryId=${lunchHistoryId}`;
    }
    return this._http.post<any>(reqUrl, {}).pipe(map(resp => resp));
  }

  updateFaceImg(id: any, payload: any) {
    const reqUrl = `${this.baseUrlV2}/update-face-img/${id}`;
    return this._http.post<any>(reqUrl, payload).pipe(map(resp => resp));
  }

  confirmPunch(payload: any) {
    const reqUrl = `${this.baseUrlV2}/confirm-punch`;
    return this._http.post<any>(reqUrl, payload).pipe(map(resp => resp));
  }

  saveTimeRecollectionHistory(params: any): Observable<any> {
      const reqUrl = `${this.baseUrlV2}/save-time-recollection-upload-history`;
      return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  searchTimeRecollectionHistory(params: any): Observable<any> {
      const reqUrl = `${this.baseUrlV2}/search-time-recollection-upload-history`;
      return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

    exportTimeRecollectionPdf(params: any): Observable<any> {
        const reqUrl = `${this.baseUrlV2}/time-recollection-upload-pdf`;
        return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
    }
  
  getImportTemplate(filePath): void {
    
    this.http.get(filePath, { responseType: 'blob' }).subscribe((blob) => {
      const fileName = 'import_time_recollection_sample.xlsx';
      fs.saveAs(blob, fileName);
    });
  }

  exportEmployeeTimeHistory(options: any): Observable<any> {
      const reqUrl = `${this.baseUrlV2}/export-time-history`;
      return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  searchPunchConfirmation(options: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/search-punch-confirm`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/search-punch-confirm`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  updatePunchConfirm(payload: any) {
    // const reqUrl = `http://localhost:8005/api/v2/clocking/update-punch-confirm`;
    const reqUrl = `${this.baseUrlV2}/update-punch-confirm`;
    return this._http.post<any>(reqUrl, payload).pipe(map(resp => resp));
  }

  getAllPeriod(companyId: number, employeeId: number): Observable<any> {
    // return this._http.get<any>(`http://localhost:8005/api/v2/clocking/getPeriod?companyId=${companyId}&employeeId=${employeeId}`).pipe(map(resp => resp));
    return this._http.get<any>(`${this.baseUrlV2}/getPeriod?companyId=${companyId}&employeeId=${employeeId}`).pipe(map(resp => resp));
  }

  exportPunchConfirm(data): Observable<any> {
    // return this._http.post(`http://localhost:8005/api/v2/clocking/export-punch-confirm`, data).pipe(map(resp => resp));
    return this._http.post(`${this.baseUrlV2}/export-punch-confirm`, data).pipe(map(resp => resp));
  }
  exportPunchConfirmDetail(data): Observable<any> {
    // return this._http.post(`http://localhost:8005/api/v2/clocking/export-punch-confirm`, data).pipe(map(resp => resp));
    return this._http.post(`${this.baseUrlV2}/export-punch-confirm-detail`, data).pipe(map(resp => resp));
  }

  importRecollection(formData: FormData) {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/voter-list/import-file`, formData).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}/import-recollection`, formData).pipe(map(res => res));
  }
}
