import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { Observable } from 'rxjs';
import { VisitedPageDataModel } from '../model/visited-page-data.model';
import {AuthService} from './auth/auth.service';
import {DropDownsService} from './drop-downs/drop-downs.service';
import {ClientService} from '../../crm/service/client.service';
import {AgencyService} from '../../agency/service';
import {AgencyAdminService} from '../../admin/agency-admin/service/agency-admin.service';
import {CompanyService} from '../../company/service/company.service';
import {Company} from '../../company/model/company.model';
import {Agency} from '../../agency/model';
import { TimeUtility } from '../../shared/utility/time.utility';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class UtilsService {
    notification = new BehaviorSubject<any>('');
    transferNotification = new BehaviorSubject<any>(true);
    // getNotification = this.notification.asObservable();
    roleId: number;
    userInfo: any;
    visitedPageData: VisitedPageDataModel = {
        pageVisitStartTime: '',
        pageVisitEndTime: '',
        pageTitle: '',
        pageUrl: '',
        arguments: {},
        pageActionTime: ''
    };

    isLogoutTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLogoutTriggered$: Observable<boolean> = this.isLogoutTriggered.asObservable();
    isLogoutTriggerCompleted = false;
    
    showNotificationOnTop(data: any) {
        this.notification.next(data);
    }

    updateTransferNotifications(data: any) {
        this.transferNotification.next(data);
    }

    deepClone = (val: any) => JSON.parse(JSON.stringify(val));

    constructor(
        private authService: AuthService,
        private dropDownsService: DropDownsService,
        private clientService: ClientService,
        private agencyAdminService: AgencyAdminService,
        private agencyService: AgencyService,
        private companyService: CompanyService
    ){
        this.isLogoutTriggered.next(false);
        this.isLogoutTriggerCompleted = false;
    }
    

    // for the company and agency dropdown
    isSuperAdmin() {
        return this.roleId === 1 || this.roleId === 7;
    }

    isCompanyAdmin() {
        return this.roleId === 4 || this.roleId === 6;
    }

    isAgencyAdmin() {
        return this.roleId === 3 || this.roleId === 5;
    }

    disableCompany(): boolean {
        return !(this.authService.isSuper() || this.authService.isSubSuper());
    }

    disableAgency(): boolean {
        return this.disableCompany() && !(this.authService.isCompanyAd() || this.authService.isSubCompanyAd());
    }

    disableEmployee(): boolean {
        return !this.authService.isEmployeeRole();
    }
    companies = [];
    agencies = [];
    

    async loadCompanyAndAgencyDropdown() {
        let company = null;
        let companyId = this.authService.getCurrentCompanyId();
        if (!this.isSuperAdmin()) {
            if (companyId != null) {
                let companyRes = await this.companyService.getCompanyByCompanyId(companyId).toPromise();
                company = companyRes.data;
            }
        }

        let agency: Agency = this.authService.getCurrentAgency();
        this.userInfo = this.authService.getUserInfo();
        this.roleId = this.userInfo.role? this.userInfo.role.id : null;
        // console.log("Role : ",this.roleId);
        // console.log("User : ",this.authService.getUserInfo());
        if (this.roleId != null) {
            if (this.isSuperAdmin()) {
                console.log('SUPER_ADMIN');
                let res = await this.dropDownsService.getAllCompanyList().toPromise();
                return {
                    companyResponse: res.status == "SUCCESS"? res.data: "",
                    agencyResponse: null, 
                    companyDisabled: false,
                    agencyDisabled: false 
                }
            } else if (this.isCompanyAdmin()) {
                console.log('COMPANY_ADMIN');
                const res = await this.agencyService.readAllForDropDownList({companyId: companyId}).toPromise();
                const companyAdminRes = await this.companyService.getCompanyByCompanyId(companyId).toPromise();
                const companyAdmin = companyAdminRes.data;
                return {
                    companyResponse: [{id: companyAdmin.id, name: companyAdmin.name, status: companyAdmin.status}],
                    agencyResponse: res.status == "SUCCESS"? res.data: "",
                    companyDisabled: true, 
                    agencyDisabled: false
                };
            } else if (this.isAgencyAdmin()) {
                console.log('AGENCY_ADMIN');
                const companyAdminRes = await this.companyService.getCompanyByCompanyId(companyId).toPromise();
                const companyAdmin = companyAdminRes.data;
                return {
                    companyResponse: [{id: companyAdmin.id, name: companyAdmin.name, status: companyAdmin.status}],
                    agencyResponse: agency ? [{id: agency.id, name: agency.name, status: agency.status}] : [],
                    companyDisabled: true,
                    agencyDisabled: true
                };
            } else {
                console.log('Employee');
                const companyAdminRes = await this.companyService.getCompanyByCompanyId(companyId).toPromise();
                const companyAdmin = companyAdminRes.data;
                return {
                    companyResponse: [{id: companyAdmin.id, name: companyAdmin.name, status: companyAdmin.status}],
                    agencyResponse: agency ? [{id: agency.id, name: agency.name, status: agency.status}] : [],
                    companyDisabled: true,
                    agencyDisabled: true
                }
            }
        }
    }

    visitPage(pageTitle: string, args: { [key: string]: string }, date: Date): void {
        this.visitedPageData.pageVisitStartTime = date;
        this.visitedPageData.pageTitle = pageTitle;
        this.visitedPageData.arguments = args;
    }

    /**
     * Convert number to hour format eg: 9 ==> 09:00
     * @param data
     */
    getHoursFormat(data) {
        if (!data) {
          return '00:00';
        }
        const absData = Math.abs(data * 60);
        const hours = Math.floor(absData / 60);
        const minutes = Math.round(absData - (hours * 60));
        const dataPrefix = data < 0 ? -1 : 1;
        const hoursFormatted = data < 0 ? dataPrefix * hours : TimeUtility.checkTime(hours);
        const minutesFormatted = TimeUtility.checkTime(minutes);
        return `${hoursFormatted}:${minutesFormatted}`;
    }

    /**
     * Covnert minutes to format HH:MM
     * @param minutes
     */
    getHHMMFormat(minutes) {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        return _.padStart(hours, 2, '0') + ':' + _.padStart(minute, 2, '0');
    }

    convertWeekDaysToNumber(weekDay: any) {
        let dayNumber = 0;
        switch (weekDay) {
            case 'MONDAY':
                dayNumber = 1;
                break;
            case 'TUESDAY':
                dayNumber = 2;
                break;
            case 'WEDNESDAY':
                dayNumber = 3;
                break;
            case 'THURSDAY':
                dayNumber = 4;
                break;
            case 'FRIDAY':
                dayNumber = 5;
                break;
            case 'SATURDAY':
                dayNumber = 6;
                break;
        }
        return dayNumber;
    }

    public static   getMilitaryTime(time : any) {
        // if (time.length === 4) {
        //     return time;
        // }
        let newHours = time;
        if(isNaN(parseInt(newHours))){
            newHours = null;
        }else{
            newHours = newHours.toString();
            if (parseInt(newHours) > 2359) {
                newHours = null;
            } else {
                if (parseInt(newHours) < 10 && newHours.length < 2) {
                    newHours = "0" + newHours;
                }
                if (parseInt(newHours) >= 24 && parseInt(newHours) < 60) {
                    newHours = "00" + newHours;
                }
                if (parseInt(newHours) >= 60 && parseInt(newHours) < 100) {
                    newHours = '0' + newHours + '0';
                }

                if (parseInt(newHours) >= 100 && parseInt(newHours) < 999) {
                    const minute = parseInt(newHours) % 100 ;
                    if (minute < 60) {
                        newHours = '0' + parseInt(newHours);
                    }
                }

                if (parseInt(newHours).toString().length >= 3) {
                    const lastTwo = newHours.slice(-2);
                    if(parseInt(lastTwo) >= 60){
                        newHours = '';
                    }

                }

                if (newHours.length > 0) {
                    for (let i = 0; 4 - newHours.length; i++) {
                        newHours += 0;
                    }
                }
            }
        }
        return newHours;
    }


    GetSortOrder(prop) {
        return function(a, b) {
            if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
                return 1;
            } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
                return -1;
            }
            return 0;
        };
    }

    randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    AfterHour(hour: number, date: Date) {
        const time = hour * 1000 * 60 * 60;
        const now = new Date();
        if (now.getTime() - date.getTime() > time) {
            return true;
        }
        return false;
    }

    generateFirst7Character() {
        let text = '';
        const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 7; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    getFirstDayOfGivenMonth(month: any) {
        const date = new Date();
        const year = date.getFullYear();
        return new Date(year, month, 1);
    }

    getLastDayOfGivenMonth(month: any) {
        const date = new Date();
        const year = date.getFullYear();
        return new Date(year, month + 1 , 0);
    }

    generateFirst6Character() {
        let text = '';
        const possible = '0123456789';
        for (let i = 0; i < 6; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    generateByNumber(number: any) {
        let text = '';
        const possible = '0123456789';
        for (let i = 0; i < number; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
}
