<div class="card p-0 add-shadow login mx-auto">
	<div class="card-header btn-primary px-3" style="
    height: 45px;
    display: flex;
    align-items: center;">
		<span><i class="fa-solid fa-user-lock mr-2"></i>{{'Platform Login' | translate}}</span>
	</div>
	<div class="ui-g p-4 pb-0" style="text-align: center; display: inline;">
		<img src="../../../assets/images/logo.png" style="width: 135px;height: auto;"><br>
		<h1 class="m-0" translate>login_title</h1>
	</div>
	<div class="ui-g p-4">
		<div *ngIf="attemptMsg || message" class="ui-g-12">
			<div *ngIf="attemptMsg" class="alert alert-dismissible fade show" [ngClass]="attemptMsg === 'Login info still wrong. 1 attempt left.' || attemptMsg === 'This account is locked! Contact support.' ? 'alert-danger' : 'alert-warning'"  role="alert">
				<span><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Warning' | translate}}: </strong> {{attemptMsg}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div *ngIf="attemptMsg === 'Login info still wrong. 1 attempt left.'" class="alert alert-info alert-dismissible c-pointer fade show" role="alert" style="cursor: pointer !important">
				<span  (click)="forgotPassword()" class="one-liner c-pointer"><i class="fa-solid fa-circle-info mr-2"></i>{{'To reset your password.' | translate}} <span style="text-decoration: underline;">{{'Click Here' | translate}}</span></span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div *ngIf="message" class="alert alert-warning alert-dismissible fade show" role="alert">
				<span><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}: </strong> {{message}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label for="username" translate>user_name</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<input pInputText type="text" id="username" [(ngModel)]="signInFormData.username" class="w-100" [ngStyle]="{'border-bottom-color': message === 'Please enter values for all the fields' && !signInFormData.username ? '#e62a10' : '#e4e4e4'}" (keydown.enter)="loginUser($event)" (change)="signInFormData.username=signInFormData.username.trim()"/>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label for="password" translate>password</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-lock"></i>
					</button>
				</div>
				<input pInputText *ngIf="showPwd" type="text" id="password" [(ngModel)]="signInFormData.password" name="password" class="w-100" (keydown.enter)="loginUser($event)" [ngStyle]="{'border-bottom-color': message === 'Please enter values for all the fields' && !signInFormData.password ? '#e62a10' : '#e4e4e4'}"/>
				<input pInputText *ngIf="!showPwd" type="password" id="password" [(ngModel)]="signInFormData.password" name="password" class="w-100" (keydown.enter)="loginUser($event)" [ngStyle]="{'border-bottom-color': message === 'Please enter values for all the fields' && !signInFormData.password ? '#e62a10' : '#e4e4e4'}"/>
				<div class="input-group-append">
					<button *ngIf="!showPwd" (click)="showPassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-eye text-primary"></i>
					</button>
					<button *ngIf="showPwd" (click)="showPassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-eye-slash text-danger"></i>
					</button>
				</div>
			</div>
		</div>		
		<!-- <div class="ui-g-12">
			<label for="type" class="mb-0" translate>login_type</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
				<p-dropdown [options]="userTypes" styleClass="w-100" translate placeholder="{{'select_user_type' | translate}}"
							[(ngModel)]="signInFormData.userValue" [disabled]="disableLoginType"
							[ngClass]="{'required-dropdown': message === 'Please enter values for all the fields' && !signInFormData.userValue ? true : false}">
					<ng-template pTemplate="item" let-item>
						{{item.label | translate}}
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						{{item.label | translate}}
					</ng-template>
				</p-dropdown>
			</div>
		</div>				 -->
		<div class="ui-g-12" style="text-align: center;">
			<p-checkbox binary="signInFormData.rememberMe"  [(ngModel)]="signInFormData.rememberMe" (onChange)="RememberMeToggle($event)" ></p-checkbox>
			{{'remember_login' | translate}}
		</div>
		<div class="ui-g-12" style="text-align: center;">
			<a (click)="forgotPassword()" class="text-primary" href="javascript:void(0);">{{'Forgotten Password' | translate}}</a>
		</div>
	</div>
	<div class="card-footer p-4 d-flex align-items-center justify-content-between">
		<button type="button" (click)="signUp()" class="btn btn-purple mx-1"><i class="fa-solid fa-key mr-2"></i>{{'Sign Up' | translate}}</button>
		<button type="button" (click)="loginUser($event)" class="btn btn-primary mx-1"><span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Log In' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></span></button>		
	</div>
</div>
