<!--<p-toast *ngIf="!isMerchantAccount" position="bottom-right" class="concern">{{messages}}</p-toast>-->
<label style="display: none" id="refresh_noti" (click)="getPendingInvoice()"></label>
<div [class]="isPunchInOut? 'layout-topbar d-none w-100' : 'layout-topbar w-100'" style="z-index: 999999; left: 0;">
    <div *ngIf="app.freezePageWhenAway" class="away-layout-overlay"></div>
    <a href="#" (click)="onDashBoard()" *ngIf="app.isHorizontal() && !app.isMobile()" class="topbar-logo">
        <img alt="logo" src="assets/layout/images/logo-slim.png" />
        <span class="app-name">{{'OperrWork' | translate}}</span>
    </a>
    <img alt="logo" (click)="onDashBoard()" src="assets/layout/images/logo-slim.png" class="mobile-logo"/>

    <a href="#" class="menu-btn" (click)="app.onMenuButtonClick($event)">
        <i class="material-icons">&#xE5D2;</i>
    </a>

    <a href="#" class="topbar-menu-btn" (click)="app.onTopbarMobileMenuButtonClick($event)">
        <i class="material-icons">&#xE853;</i>
    </a>

    <div class="layout-topbar-menu-wrapper d-flex align-items-center">
		<div class="header-logo d-flex" style="margin-right: 20px;margin-top: -20px;">
			<a style="margin-right: 35px;" (click)="onDashBoard()">
				<img alt="logo" src="assets/layout/images/logo-slim-new.png" style="max-width: 153px;" />
			</a>
			<a href="#" class="header-anchor" title="Toggle Menu" (click)="app.onToggleMenuClick($event)" style="margin-top: 19px;"></a>
		</div>
        <ul class="topbar-menu fadeInDown flex-grow-1 flex-nowrap" [ngClass]="{'topbar-menu-active': app.topbarMenuActive}" (click)="app.onTopbarMenuClick($event)">
            <li #profile class="profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}" (click)="app.onTopbarRootItemClick($event, profile)">
                <a href="#">
					<span class="profile-image-wrapper icon-container">
						<img id="userIMG" [src]="photoURL" (error)="updateUrl($event)"/>
                        <div *ngIf="isEmployee && empClockingStatus === 'OFF'" class='status-circle-off'></div>
                        <div *ngIf="isEmployee && empClockingStatus === 'DUTY'" class='status-circle-duty'></div>
                        <div *ngIf="isEmployee && empClockingStatus === 'LUNCH_BREACK'" class='status-circle-lunch-breack'></div>
					</span>
                    <span class="topbar-item-name one-liner profile-name"><b>{{username}}</b></span>
                </a>
                <ul class="fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarMenuProfileClick($event)" routerLink="{{getRouterLink()}}">
							<i class="fa-solid fa-circle-user"></i>
                            <span>{{'profile' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onResume($event)" *ngIf="!isClientAccount" routerLink="['app/resume']">
							<i class="fa-solid fa-briefcase"></i>
                            <span>{{'My Resume' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a (click)="openUserAgreement()">
							<i class="fa-solid fa-square-check"></i>
                            <span>{{'User Agreement' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a (click)="openUserPrivacy()">
                            <i class="fa-solid fa-shield"></i>
                            <span>{{'Privacy' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem" routerLinkActive="active" *ngIf="!isClientAccount">
                        <a href="#" [routerLink]="['/app/employee/setting']">
							<i class="fa-solid fa-gear"></i>
                            <span>{{'settings' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="isEmployee">
                        <a (click)="openComplianceEmployeeWizard()">
                            <i class="fa-solid fa-shield"></i>
                            <span>{{'Compliance Wizard' | translate}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a style="cursor: pointer;"  (click)="actionLogout()">
							<i class="fa-solid fa-power-off text-danger"></i>
                            <span>{{'logout' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>
			<li *ngIf="!isClientAccount && !isChildAccount" class="show-big m-0 mx-0">
				<a [routerLink]="['/app/employee-clocking/punch']">
					<i class="topbar-icon material-icons animated swing punch-icon" icon pTooltip="{{'Punch Clock' | translate}}" position="left">apps</i>
					<span class="topbar-item-name">{{'Punch Clock' | translate}}</span>
				</a>
			</li>
			<li #systemSearch [ngClass]="{'active-topmenuitem':app.activeTopbarItem === systemSearch}" (click)="app.onTopbarRootItemClick($event,systemSearch)" class="px-3 topbar-search" style="margin-top: -5px">
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-magnifying-glass"></i>
						</button>
					</div>
                    <p-autoComplete [(ngModel)]="searchSelected" scrollHeight="350px" autoHighlight="true" panelStyle="width: 100%" [style]="{'width':'100%'}" [suggestions]="systemSearchList" (completeMethod)="instantSearch($event)" field="label" [minLength]="1" (onSelect)="toRusltSearch()" placeholder="{{'Search' | translate}}"></p-autoComplete>
                </div>
            </li>
			<li *ngIf="!isClientAccount && !isChildAccount" class="show-small">
				<a [routerLink]="['/app/employee-clocking/punch']">
					<i class="topbar-icon material-icons animated swing punch-icon" icon pTooltip="{{'Punch Clock' | translate}}" position="left">apps</i>
					<span class="topbar-item-name">{{'Punch Clock' | translate}}</span>
				</a>
			</li>
            <li #settings *ngIf="!disableSetting" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === settings}" (click)="app.onTopbarRootItemClick($event, settings)">
                <ng-container *ngIf="disable === true; else enableSettings">
                    <a>
                        <i class="fa-solid fa-gear" (click)="goToAgencySetting()" pTooltip="{{'Settings' | translate}}"></i>
						<span class="topbar-item-name">{{'Settings' | translate}}</span>
                    </a>
                </ng-container>
                <ng-template #enableSettings>
                    <a>
                        <i class="fa-solid fa-gear" (click)="goToAgencySetting()" pTooltip="{{'Settings' | translate}}"></i>
						<span class="topbar-item-name">{{'Settings' | translate}}</span>
                    </a>
                </ng-template>
            </li>
            <li style="cursor: pointer;" #working_status [ngClass]="{'active-topmenuitem':app.activeTopbarItem === working_status}" *ngIf="showWorkingStatus && isCompanyAdmin()" >
                <a (click)="app.onTopbarRootItemClick($event, working_status)" pTooltip="{{'Working status' | translate}}">
                    <i class="fa-solid fa-eye"></i>
                    <span class="topbar-item-name">{{'Working status' | translate}}</span>
                </a>
                <ul class="fadeInDown" style="height: 400px; overflow-y: auto">
                    <!--<li>-->
                        <!--<div class="country-item" class="row mx-0 mb-2 align-items-center">-->
                            <!--<div class="ui-g-9">{{currentAdmin.firstName + ' ' + currentAdmin.lastName}}</div>-->
                            <!--<div class="ui-g-3" style="height: 20px; width: 20px; border-radius: 50%; background: #00b607" pTooltip="{{'Working' | translate}}"></div>-->
                        <!--</div>-->
                    <!--</li>-->
                    <li>
                        <div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
                            <div class="input-group-prepend p-0">
                                <button class="btn btn-white" id="button-addon2" type="button">
                                    <i class="fa-solid fa-filter"></i>
                                </button>
                            </div>
                            <input type="text" [(ngModel)]="wsTextSearch" pInputText placeholder="{{'Filter' | translate}}" (input)="filterWorkingStatus()">
                        </div>
                    </li>
                    <li *ngFor="let item of workStatusData.employeeClockIn" >
                        <div class="country-item" class="row mx-0 mb-2 align-items-center">
                            <div class="ui-g-9">{{item.fullName}}</div>
                            <div class="ui-g-3" style="height: 20px; width: 20px; border-radius: 50%; background: #00b607"  pTooltip="{{'Working' | translate}}"></div>
                        </div>
                    </li>
                    <li *ngFor="let item of workStatusData.employeeClockOut" >
                        <div class="country-item" class="row mx-0 mb-2 align-items-center">
                            <div class="ui-g-9">{{item.fullName}}</div>
                            <div class="ui-g-3" style="height: 20px; width: 20px; border-radius: 50%; background: #FFA726"  pTooltip="{{'On Break' | translate}}"></div>
                        </div>
                    </li>
                    <li *ngFor="let item of workStatusData.endWorks" >
                        <div class="country-item" class="row mx-0 mb-2 align-items-center">
                            <div class="ui-g-9">{{item.fullName}}</div>
                            <div class="ui-g-3" style="height: 20px; width: 20px; border-radius: 50%; background: #d43f3a"  pTooltip="{{'Work Ended' | translate}}"></div>
                        </div>
                    </li>
                    <li *ngFor="let item of workStatusData.employeeAbsent" >
                        <div class="country-item" class="row mx-0 mb-2 align-items-center">
                            <div class="ui-g-9">{{item.fullName}}</div>
                            <div class="ui-g-3" style="height: 20px; width: 20px; border-radius: 50%; background: #8898aa"  pTooltip="{{'Absent' | translate}}"></div>
                        </div>
                    </li>

                </ul>
            </li>
            <li #messages *ngIf="!isMerchantAccount && !maintain" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === messages}" (click)="app.onTopbarRootItemClick($event, messages);"  pTooltip="{{'Chat Messages' | translate}}" tooltipPosition="left">
                <ng-container *ngIf="disable === true; else enableA">
                    <a>
						<i class="fa-solid fa-message"></i>
                        <span *ngIf="numberChatMsgNotification > 0" class="badge badge-danger">{{numberChatMsgNotification}}</span>
                        <span class="topbar-item-name">{{'Messages' | translate}}</span>
                    </a>
                </ng-container>
                <ng-template #enableA>
                    <a href="#">
						<i class="fa-solid fa-comment"></i>
                         <span *ngIf="taggedNotificationCount > 0" class="badge badge-danger">{{taggedNotificationCount}}</span>
                        <span class="topbar-item-name">{{'Messages' | translate}}</span>
                    </a>
                </ng-template>
                <ul class="fadeInDown" style="width:280px;">
                    <li role="menuitem">
                        <a [routerLink]="['/app/phonesystem/my-message']">
                            <span>{{'View All Messages' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li #notifications id="notificationButton" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === notifications}" (click)="app.onTopbarRootItemClick($event, notifications)" *ngIf="!maintain">
                <ng-container *ngIf="disable === true; else enableNotifi">
                    <a>
						<i class="fa-solid fa-bell-slash" pTooltip="{{'Notifications' | translate}}"></i>
                        <span *ngIf="(isClientAccount || isAdminAccnt) && (filteredNotifications.length + notificationTextCnt + notificationCount + pendingTransaction.length + newPaymentTransaction.length) > 0"
                            class="badge badge-danger {{ this.notificationCountBackgroundChange? ' badge-danger ' : '' }}">
                            {{filteredNotifications.length + notificationTextCnt + notificationCount + pendingTransaction.length + newPaymentTransaction.length}}
                        </span>
                        <span *ngIf="!(isClientAccount || isAdminAccnt) && (filteredNotifications.length + notificationCount + notificationTextCnt) > 0"
                            class="badge badge-danger {{ this.notificationCountBackgroundChange? ' badge-danger ' : '' }}">
                            {{filteredNotifications.length + notificationCount + notificationTextCnt}}
                        </span>
                        <span class="topbar-item-name">{{'Notifications' | translate}}</span>
                    </a>
                </ng-container>
                <ng-template #enableNotifi>
                    <a href="#">
						<i class="fa-solid fa-bell" pTooltip="{{'Notifications' | translate}}"></i>

                        <span *ngIf="(isClientAccount || isAdminAccnt) && (filteredNotifications.length + notificationCount + pendingTransaction.length + newPaymentTransaction.length + totalNewVoterAssigned + totalNewNotificationMessage + notificationTextCnt) > 0"
                            class="badge badge-danger {{ this.notificationCountBackgroundChange? ' badge-danger ' : '' }}">
                            {{filteredNotifications.length + notificationTextCnt + notificationCount + pendingTransaction.length + newPaymentTransaction.length + totalNewVoterAssigned + totalNewNotificationMessage}}
                        </span>
                        <span *ngIf="!(isClientAccount || isAdminAccnt) && (filteredNotifications.length + notificationCount + totalNewVoterAssigned + totalNewNotificationMessage) > 0"
                            class="badge badge-danger {{ this.notificationCountBackgroundChange? ' badge-danger ' : '' }}">
                            {{filteredNotifications.length + notificationTextCnt + notificationCount + totalNewVoterAssigned + totalNewNotificationMessage}}
                        </span>
                        <span class="topbar-item-name">{{'Notifications' | translate}}</span>
                    </a>
                </ng-template>
                <ul style="max-height: 600px; overflow-y: auto" class="fadeInDown">
                    <li role="menuitem" class="w-100">
                        <button style="float: right; margin-bottom: 20px" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="app.onTopbarRootItemClick($event, notifications);$event.stopPropagation();"></button>
                    </li>
                    <li role="menuitem" *ngIf="missingCount <= 0 && totalNewNotificationMessage <= 0 && missingInOutCount <= 0 && totalNewVoterAssigned <= 0">
                        <span>{{'New notification' | translate}}</span>
                    </li>
                    <li role="menuitem" *ngIf="missingCount > 0">
                        <a [routerLink]="['/app/employee-clocking/punch-history']" [queryParams]="{ term: 'missing' }">
                            <i class="material-icons">bug_report</i>
                            <span>{{'Punch In-Out Missing' | translate}} {{missingCount}}</span>
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="totalNewNotificationMessage > 0">
                        <a [routerLink]="['/app/notification/display']" (click)="readNotification()">
                            <i class="material-icons">notifications</i>
                             <span>{{'Notifications' | translate}} {{totalNewNotificationMessage}}</span>
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="isAdmin() && missingInOutCount > 0" >
                        <a [routerLink]="['/app/employee-clocking/punch-history']">
                            <i class="material-icons">edit</i>
                            <span>{{'Check in/out edits' | translate}} {{missingInOutCount}}</span>
                        </a>
                    </li>
                    <li role="menuitem" *ngFor="let fn of filteredNotifications">
                        <a (click)="acceptTransfers(fn)">
                            <i class="material-icons">swap_horiz</i>
                            <span>{{occurencesMessage(fn.oldAssignee)}}</span>
                        </a>
                    </li>
                    <li role="menuitem" *ngFor="let item of notificationText">
                        <a [class]="item.backgroundChange? 'backgroundRed' : ''" (click)="onClickNotification(item)">
                            <i class="material-icons">{{item.iconName}}</i>
                            <span>{{item.text}}</span>
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="isClientAccount || isAdminAccnt">
                        <!-- <a [routerLink]="['/app/employee-clocking/punch-history']" [queryParams]="{ term: 'missing' }"> -->
                        <a (click)="goToPendingInvoice()" *ngIf="pendingTransaction.length > 0">
                            <i class="material-icons">payment</i>
                            <span>{{'Pending Invoice' | translate}} {{pendingTransaction.length}}</span>
                        </a>
                        <a (click)="goToNewPaymentInvoice()" *ngIf="newPaymentTransaction.length > 0">
                            <i class="material-icons">payment</i>
                            <span>{{'New Payment' | translate}} {{newPaymentTransaction.length}}</span>
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="totalNewVoterAssigned > 0">
                        <a (click)="goToWalker()">
                            <i class="material-icons">payment</i>
                            <span>{{'New Voter Assigned' | translate}} ({{totalNewVoterAssigned}})</span>
                        </a>
                    </li>

                </ul>
            </li>

            <li style="cursor: pointer;" *ngIf="showEmail && !isGuess" >
                <a (click)="onClickSendEmail()" pTooltip="{{'Mail' | translate}}">
					<i class="fa-solid fa-inbox"></i>
                    <span class="topbar-item-name">{{'Send Email' | translate}}</span>
                </a>
            </li>

            <li style="cursor: pointer;" #anydesk [ngClass]="{'active-topmenuitem': (app.activeTopbarItem === anydesk && app.freezePageWhenAway),
            'is-disable': !showHourGlass}" (click)="app.onTopbarRootItemClick($event, anydesk)" *ngIf="!isGuess">
                <ng-container *ngIf="disable === false; else enableAnydesk">
                    <a>
						<i class="fa-solid fa-hand" pTooltip="{{'Turn-Off Away' | translate}}"></i>
						<span class="topbar-item-name">{{'Turn-Off Away' | translate}}</span>
                    </a>
                </ng-container>
                <ng-template #enableAnydesk>
                    <a *ngIf="!isMerchantAccount && !maintain">
						<i class="fa-solid fa-hourglass-half" pTooltip="{{'Away Messages' | translate}}"></i>
						<span class="topbar-item-name">{{'Away Message' | translate}}</span>
                    </a>
                </ng-template>
                <ul class="fadeInDown" [ngClass]="{'active-topmenuitem':app.activeTopbarItem === null, 'enable-overlay': app.freezePageWhenAway}" (click)="$event.stopPropagation()">
                    <div class="desk-header">
                        <h6 class="mb-0">{{'AwayFromFesk' | translate}} </h6>
                    </div>
                    <div class="ui-g ui-fluid">
                        <div style="margin-left: 25px;" >
                            <div class="ui-g-12">{{'appropriateOption' | translate}}</div>
                            <div class="ui-g-12">
                                <p-radioButton *ngIf="awayTime > 0"
                                    name="awayOption"
                                    value="GO_TO_BATH_ROOM"
                                    label="{{'GO_TO_BATH_ROOM' | translate}}"
                                    [disabled]="awayTime > 0"
                                    [(ngModel)]="awayOption"></p-radioButton>
                
                                <p-radioButton
                                        name="awayOption" *ngIf="awayTime ==  0"
                                        value="GO_TO_BATH_ROOM"
                                        label="{{'GO_TO_BATH_ROOM' | translate}}"
                                        (click)="startCounting()"
                                        [(ngModel)]="awayOption"></p-radioButton>
                            </div>
                            <div class="ui-g-12">
                                <p-radioButton *ngIf="awayTime > 0"
                                    name="awayOption"
                                    value="MEETING"
                                    label="{{'MEETING' | translate}}"
                                    [disabled]="awayTime > 0"
                                    [(ngModel)]="awayOption"></p-radioButton>
                
                                <p-radioButton *ngIf="awayTime == 0"
                                        name="awayOption"
                                        value="MEETING"
                                        label="{{'MEETING' | translate}}"
                                        (click)="startCounting()"
                                        [disabled]="awayTime > 0"
                                        [(ngModel)]="awayOption"></p-radioButton>
                            </div>
                            <div class="ui-g-12">
                                <p-radioButton *ngIf="awayTime > 0"
                                    name="awayOption"
                                    value="TAKE_A_BREAK"
                                    label="{{'TAKE_A_BREAK' | translate}}"
                                    [disabled]="awayTime > 0"
                                    [(ngModel)]="awayOption"></p-radioButton>
                
                                <p-radioButton *ngIf="awayTime == 0"
                                        name="awayOption"
                                        value="TAKE_A_BREAK"
                                        label="{{'TAKE_A_BREAK' | translate}}"
                                        (click)="startCounting()"
                                        [disabled]="awayTime > 0"
                                        [(ngModel)]="awayOption"></p-radioButton>
                            </div>
                            <div class="ui-g-12">
                                <p-radioButton *ngIf="awayTime > 0"
                                        name="awayOption"
                                        value="OTHER"
                                        label="{{'other' | translate}}"
                                        [disabled]="awayTime > 0"
                                        [(ngModel)]="awayOption"></p-radioButton>
                
                                <p-radioButton
                                        *ngIf="awayTime == 0"
                                        name="awayOption"
                                        value="OTHER"
                                        label="{{'other' | translate}}"
                                        [(ngModel)]="awayOption"></p-radioButton>
                                <input *ngIf="awayOption === 'OTHER'" type="text" class="form-control" pInputText
                                       [(ngModel)]="awayOther" [readOnly]="awayTime > 0" />
                            </div>
                            <div class="ui-g-12">
                                <i class="material-icons" style="margin-right: 20px;">av_timer</i>
                                <span *ngIf="awayTime === 0">--:--</span>
                                <span *ngIf="awayTime > 0">{{ awayTime * 1000 | date:'mm:ss':'UTC'}}</span>
                            </div>
                        </div>
                        <div class="ui-g-12 ui-md-12 text-center pb-3">
                            <button pButton class="w-auto" label="{{'Done' | translate}}" (click)="startCounting(); $event.stopPropagation()" *ngIf="awayOption === 'OTHER' && awayTime === 0" [disabled]="!awayOther || awayOther === ''"></button>
                            <button style="width: auto;" pButton class="w-auto" label="{{awayTime === 0 ? ('close' | translate) : 'BackToMyDesk' | translate}}" (click)="closeAwayFromDesk()"></button>
                        </div>
                    </div>
                </ul>
            </li>
            <li style="cursor: pointer;" #language [ngClass]="{'active-topmenuitem':app.activeTopbarItem === language}"
                (click)="app.onTopbarRootItemClick($event, language)" *ngIf="!maintain">
                <a href="#">
					<i class="fa-solid fa-earth-americas" pTooltip="{{'Language' | translate}}"></i>
                    <span class="topbar-item-name">{{'Language' | translate}}</span>
                </a>
                <ul class="fadeInDown">
                    <li *ngFor="let item of languages" >
                        <a href="#" (click)="switchLanguage(item.value)">
                            <div class="country-item">
	                            <div>{{item.label | translate}}</div>
                            </div>
                        </a>
                    </li>

                </ul>
            </li>
            <li style="cursor: pointer;" *ngIf="!isMerchantAccount && !maintain">
                <a (click)="onClickNote()" pTooltip="{{'My Notes' | translate}}">
					<i class="fa-solid fa-note-sticky"></i>
					<span class="topbar-item-name">{{'My Notes' | translate}}</span>
                </a>
            </li>
            <li style="cursor: pointer;">
				<a>
					<i class="fa-solid fa-circle-question" (click)="onClickFaq()" pTooltip="{{'FAQ & Help Items' | translate}}"></i>
					<span class="topbar-item-name">{{'FAQ & Help Items' | translate}}</span>
				</a>
            </li>
            <li style="cursor: pointer;" *ngIf="!isRecording && !maintain">
				<a>
					<i class="fa-solid fa-clapperboard" id="record-screen" (click)="startRecordingScreen();" pTooltip="{{'Screen Capture' | translate}}"></i>
					<span class="topbar-item-name">{{'Screen Capture' | translate}}</span>
				</a>
            </li>
            <li style="cursor: pointer;" *ngIf="isRecording && !maintain">
                <a (click)="stopRecording()">
					<i class="fa-solid fa-circle-pause" pTooltip="{{'Stop Capture' | translate}}"></i>
                    <span class="topbar-item-name">{{'Stop Capture' | translate}}</span>
                </a>
            </li>

			<li style="cursor: pointer;" *ngIf="contactLink && !maintain" class="pt-1" >
				<a href="javascript:void(0)" routerLink="/app/email" style="font-size:17px;color:white;">
					{{'Contact Us' | translate}}
				</a>
			</li>

			<li style="cursor: pointer;" *ngIf="!maintain">
				<a (click)="isRefresh=true" pTooltip="{{'Refresh' | translate}}">
					<i class="fa-solid fa-rotate"></i>
					<span class="topbar-item-name">{{'Refresh' | translate}}</span>
				</a>
			</li>	
			<li style="cursor: pointer;" *ngIf="isVisible && !maintain">
				<opr-call-handler></opr-call-handler>
			</li>
			<li style="cursor: pointer;" *ngIf="(isAdminAccnt || showShortArray) && (shortArray?.length > 0)">
				<div class="dropdown">
					<button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
						<i class="fa-solid fa-bars"></i>
					</button>
					<div class="dropdown-menu" style="min-width: 14em !important">
						<li *ngFor="let ele of shortArray" href="javascript:void(0)" class="p-0 dropdown-item d-flex align-items-center">
							<div *ngIf="ele.value === 20" (click)="showCompliancePopUp()" class="dropdown-item d-flex align-items-center">
                                <i class="{{ele.icon}}"></i><span class="one-liner">{{ele.label}}</span>
                            </div>
                            <div *ngIf="ele.value === 21" (click)="processTimerForTimeRecording()" class="dropdown-item d-flex align-items-center">
                                <i class="{{ele.icon}}"></i><span class="one-liner">{{ele.label}}</span>
                            </div>
                            <div *ngIf="ele.value !== 20 && ele.value !== 21" class="dropdown-item d-flex align-items-center" routerLink="{{ele.link}}">
                                <i class="{{ele.icon}}"></i><span class="one-liner">{{ele.label}}</span>
                            </div>
						</li>
					</div>
                </div>
			</li>

            <li *ngIf="openTimerForTimeRecording">
				<button class="btn btn-primary text-nowrap" (click)="toggleSoundTimerForTimeRecording($event)" type="button">
                    <!-- <span> {{ getFormattedTime() }} </span> -->
                    <span> {{ getFormattedTime1() }} </span>
                    <!-- <i *ngIf="showIconStart" class="fa-solid fa-play mr-2" (click)="startTimerForTimeRecording()" pTooltip="{{'Start' | translate}}"></i> -->
                    <i *ngIf="showIconStart" class="fa-solid fa-play mr-2" (click)="startFirstTimerRecord()" pTooltip="{{'Start' | translate}}"></i>
                    <i *ngIf="showIconPause" class="fa-solid fa-pause mr-2" (click)="resetTimerRecords()" pTooltip="{{'Pause' | translate}}"></i>
                    <!-- <i *ngIf="showIconPause" class="fa-solid fa-pause mr-2" (click)="stopTimerForTimeRecording()" pTooltip="{{'Pause' | translate}}"></i> -->
				</button>
			</li>
            
			<li class="alert-timer-wrapper position-relative" *ngIf="showOTTimer && isTimeToDisplayClock">
				<a class="ui-g align-items-center">
					<i class="topbar-icon material-icons alert-timer">
						access_time
					</i>
					<p class="my-0 mx-1 countdown-time" appCounter
					[counter]="countTimeLeftClock" [showVolume] = "showVolume" [interval]="1000" (value)="count = $event" (updateMinute)="updateTimeCount($event)">
						<span> {{ count }} </span>
					</p>
					<i *ngIf="showVolume" class="topbar-icon material-icons alert-timer" (click)="toggleSound($event)" style="cursor: pointer;">
						volume_up
					</i>
					<i *ngIf="muteVolume" class="topbar-icon material-icons alert-timer" (click)="toggleSound($event)" style="cursor: pointer;">
						volume_off
					</i>
				</a>
			</li>

			<li *ngIf="showOTTimer && isTimeToDisplayClockIncrease">
				<button class="btn btn-danger text-nowrap" (click)="toggleSoundInCrease($event)" type="button" pTooltip="{{'Overtime' | translate}}">
					<i class="fa-solid fa-triangle-exclamation mr-2"></i><b class="over">{{'OVER!' | translate}} </b>
					<span appCounterIncrease [counter]="countIncreaseTimeLeft" [showVolume]="showVolume" [interval]="1000" (value)="countIncrease = $event" (updateMinute)="updateTimeCounterIncrease($event)">
						<span> {{ countIncrease }} </span>
					</span>
				</button>
			</li>

            <li style="cursor: pointer;" #balance [ngClass]="{'active-topmenuitem':app.activeTopbarItem === balance}" *ngIf="!maintain">
				<a pTooltip="{{'Available' | translate}}: {{ (accountBalance?.availableBalance) || 0 | currency}} {{accountBalance?.pendingBalance ? ('Pending: ' + (accountBalance?.pendingBalance | currency)) : '' | translate}}">
					<i class="fa-solid fa-plus-circle" (click)="addBalanceModal.toggle($event)"></i>
					<span (click)="redirectToWallet()" style="font-size: large; padding-top: 0px; padding-left: 6px;">{{'Balance' | translate}}: {{ (accountBalance?.availableBalance) || 0 | currency}}</span>
				</a>
			</li>
            <li *ngIf="maintain" class="mq-maintain">
                <a class="ui-g align-items-center"><marquee><b><h5>{{textMaintain}}</h5></b></marquee></a>
            </li>

        </ul>
    </div>
</div>

<p-dialog [(visible)]="showPopUp" styleClass="w-50" id="id_show_pop_up" [modal]="true" [baseZIndex]="10000">
    <div class="card card-w-title p-0 border-0 m-0">
        <p class="tabCardHead">
            <span>{{'Event Reminder' | translate}}</span>
        </p>
        <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
            <div *ngFor="let item of popupList?.data">
                <div class="ui-g-12 ui-md-12">
                    <div class="alert alert-info" role="alert">
                        <span>{{item.message}}</span>
                    </div>
                </div>
            </div>
            <div class="ui-g-12 ui-md-12 text-center pb-3">
                <button pButton class="w-auto" label="Ok" (click)="closePopUp()"></button>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="awayFromDesk" [positionTop]="200" [modal]="true" [baseZIndex]="10000" [showHeader]="true" header="Away from desk" modal="modal"
          [dismissableMask]="false">
    <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1" style="width: 300px">
        <div style="width: 200px; margin-left: 50px;">
            <div class="ui-g-12">{{'Select the appropriate option' | translate}}</div>
            <div class="ui-g-12">
                <p-radioButton *ngIf="awayTime > 0"
                    name="awayOption"
                    value="GO_TO_BATH_ROOM"
                    label="Go to bathroom"
                    [disabled]="awayTime > 0"
                    [(ngModel)]="awayOption"></p-radioButton>

                <p-radioButton
                        name="awayOption" *ngIf="awayTime ==  0"
                        value="GO_TO_BATH_ROOM"
                        label="Go to bathroom"
                        (click)="startCounting()"
                        [(ngModel)]="awayOption"></p-radioButton>
            </div>
            <div class="ui-g-12">
                <p-radioButton *ngIf="awayTime > 0"
                    name="awayOption"
                    value="MEETING"
                    label="Meeting"
                    [disabled]="awayTime > 0"
                    [(ngModel)]="awayOption"></p-radioButton>

                <p-radioButton *ngIf="awayTime == 0"
                        name="awayOption"
                        value="MEETING"
                        label="Meeting"
                        (click)="startCounting()"
                        [disabled]="awayTime > 0"
                        [(ngModel)]="awayOption"></p-radioButton>
            </div>
            <div class="ui-g-12">
                <p-radioButton *ngIf="awayTime > 0"
                    name="awayOption"
                    value="TAKE_A_BREAK"
                    label="Take a break"
                    [disabled]="awayTime > 0"
                    [(ngModel)]="awayOption"></p-radioButton>

                <p-radioButton *ngIf="awayTime == 0"
                        name="awayOption"
                        value="TAKE_A_BREAK"
                        label="Take a break"
                        (click)="startCounting()"
                        [disabled]="awayTime > 0"
                        [(ngModel)]="awayOption"></p-radioButton>
            </div>
            <div class="ui-g-12">
                <p-radioButton *ngIf="awayTime > 0"
                        name="awayOption"
                        value="OTHER"
                        label="Other"
                        [disabled]="awayTime > 0"
                        [(ngModel)]="awayOption"></p-radioButton>

                <p-radioButton
                        *ngIf="awayTime == 0"
                        name="awayOption"
                        value="OTHER"
                        label="Other"
                        (click)="startCounting()"
                        [(ngModel)]="awayOption"></p-radioButton>
                <input *ngIf="awayOption === 'OTHER'" type="text" class="form-control" pInputText
                       [(ngModel)]="awayOther" [readOnly]="awayTime > 0" />
            </div>
            <div class="ui-g-12">
                <i class="material-icons" style="margin-right: 20px;">av_timer</i>
                <span *ngIf="awayTime === 0">--:--</span>
                <span *ngIf="awayTime > 0">{{ awayTime * 1000 | date:'mm:ss':'UTC'}}</span>
            </div>
        </div>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Done" (click)="startCounting()" *ngIf="awayOption === 'OTHER' && awayTime === 0" [disabled]="!awayOther || awayOther === ''"></button>
            <button pButton class="w-auto" label="{{awayTime === 0 ? 'Close' : 'Back to my desk'}}" (click)="closeAwayFromDesk()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="overtimeAlert" [positionTop]="200" [modal]="true" [baseZIndex]="99999999" [showHeader]="true" [style]="{'width' : '100%', 'max-width' : '600px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Overtime Alert' | translate}}</span>
	</p-header>
    <div class="ui-g">
		<div *ngIf="confirmationStep === 0"  class="ui-g-12 pb-0">
			<div class="alert alert-warning alert-dismissible d-flex align-items-center" role="alert">
				<i class="fa-solid fa-triangle-exclamation mr-2"></i>
				<div><b>{{'Alert' | translate}}:</b> {{'You are approaching overtime hours' | translate}}</div>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
		</div>
		<div *ngIf="confirmationStep === 0" class="ui-g-12 pt-0">
			<span style="font-size: 16px">{{'If you continue with todays daily work schedule, you will accrue overtime at' | translate}} {{overtimeDate}}. {{'All overtime hours must be have prior authorization by company management' | translate}}</span>
		</div>
		<div *ngIf="confirmationStep === 1" class="ui-g-12 py-4">
			<span style="font-size: 16px">{{'Will you need to work overtime?' | translate}}</span>
		</div>
		<div *ngIf="confirmationStep === 2" class="ui-g-12 py-4">
			<span style="font-size: 16px">{{'Are you authorized for overtime? If not, please click the "Authorize Me" button below.' | translate}}</span>
		</div>
		<div *ngIf="confirmationStep === 3" class="ui-g-12">
			<div class="ui-g">
				<div *ngIf="confirmationError !== ''"  class="ui-g-12 pb-0">
					<div class="alert alert-danger alert-dismissible d-flex align-items-center" role="alert">
						<i class="fa-solid fa-triangle-exclamation mr-2"></i>
						<div><b>{{'Alert' | translate}}:</b> {{confirmationError}}</div>
						<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
					</div>
				</div>
				<div class="ui-g-12">
					<span style="font-size:16px">{{'Please enter your overtime authorization info...' | translate}}</span>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Authorized by' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<input type="text" pInputText [(ngModel)]="confirmation.confirmedBy" class="form-control">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Overtime Allowed' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-clock"></i>
							</button>
						</div>
						<input type="text" numbersOnly [timeFormat]="true" minNumber="0" maxNumber="23" pInputText [(ngModel)]="confirmation.confirmHour" placeholder="{{'Hours' | translate}}" maxlength="2" class="form-control">
						<input type="text" numbersOnly [timeFormat]="true" minNumber="0" maxNumber="59" pInputText [(ngModel)]="confirmation.confirmMinute" placeholder="{{'Minutes' | translate}}"  maxlength="2" class="form-control">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Authorization Number' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-hashtag"></i>
							</button>
						</div>
						<input type="text" pInputText [(ngModel)]="confirmation.authorizedNumber" class="form-control">
					</div>
				</div>
				<div class="ui-g-12 ui-sm-12">
					<label>{{'Reason for Overtime' | translate}}</label>
					<textarea [rows]="3" [cols]="30" pInputTextarea class="inputTextarea" [(ngModel)]="confirmation.reason"></textarea>
				</div>
				<div *ngIf="showTakeOTBack" class="ui-g-6 ui-sm-12">
					<label>{{checkBoxLabel}}</label>
					<p-radioButton name="showTakeOT" value="0" label="{{'Agree' | translate}}" [(ngModel)]="showTakeOT"></p-radioButton>
					<p-radioButton name="showTakeOT" value="0" label="{{'Disagree' | translate}}" [(ngModel)]="showTakeOT"></p-radioButton>
				</div>
			</div>
		</div>
		<div *ngIf="confirmationStep === 4" class="ui-g-12">
			<div class="ui-g">
				<div *ngIf="confirmationError !== ''"  class="ui-g-12 pb-0">
					<div class="alert alert-danger alert-dismissible d-flex align-items-center" role="alert">
						<i class="fa-solid fa-triangle-exclamation mr-2"></i>
						<div><b>{{'Alert' | translate}}:</b> {{confirmationError}}</div>
						<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Period' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-clock"></i>
							</button>
						</div>
						<input type="text" numbersOnly [timeFormat]="true" minNumber="0" maxNumber="23" pInputText [(ngModel)]="confirmation.confirmHour"  placeholder="{{'Hours' | translate}}" maxlength="2" class="form-control">
						<input type="text" numbersOnly [timeFormat]="true" minNumber="0" maxNumber="59" pInputText [(ngModel)]="confirmation.confirmMinute"  placeholder="{{'Minutes' | translate}}" maxlength="2" class="form-control">
					</div>
				</div>
				<div class="ui-g-12 ui-sm-12">
					<label>{{'Reason for Overtime' | translate}}</label>
					<textarea [rows]="3" [cols]="30" pInputTextarea class="inputTextarea" [(ngModel)]="confirmation.reason" style="font-size: 16px"></textarea>
				</div>
				<div *ngIf="showTakeOTBack" class="ui-g-6 ui-sm-12">
					<label>{{checkBoxLabel}}</label>
					<p-radioButton name="showTakeOT" value='1' label="{{'Agree' | translate}}" [(ngModel)]="showTakeOT"></p-radioButton>
					<p-radioButton name="showTakeOT"value='0' label="{{'Disagree' | translate}}" [(ngModel)]="showTakeOT"></p-radioButton>
				</div>
			</div>
		</div>
    </div>
	<p-footer *ngIf="confirmationStep === 0" class="d-flex align-items-center justify-content-end">
        <button class="btn btn-primary" (click)="nextStep(1)"><i class="fa-solid fa-check mr-2"></i>{{'Aknowledge' | translate}}</button>
	</p-footer>

	<p-footer *ngIf="confirmationStep === 1" class="d-flex align-items-center justify-content-between">
		<button class="btn btn-danger pull-left" (click)="confirm(); overtimeAlert = false" *ngIf="popupClosable"><i class="fa-solid fa-xmark mr-2"></i>{{'No' | translate}}</button>
        <button class="btn btn-primary pull-right" (click)="nextStep(2)"><i class="fa-solid fa-check mr-2"></i>{{'Yes' | translate}}</button>
	</p-footer>

	<p-footer *ngIf="confirmationStep === 2" class="d-flex align-items-center justify-content-between">
		<span>
			<button class="btn btn-danger pull-left mx-1" (click)="overtimeAlert = false" *ngIf="popupClosable"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button class="btn btn-orange pull-left mx-1"  (click)="nextStep(1)" pTooltip="{{'Back' | translate}}"><i class="fa-solid fa-arrow-left mr-2"></i>{{'Back' | translate}}</button>
		</span>
		<span>
			<button class="btn btn-primary pull-right mx-1" (click)="nextStep(3)"><i class="fa-solid fa-check mr-2"></i>{{'Yes' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
			<button class="btn btn-purple pull-right mx-1" (click)="nextStep(4)"><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Authorize Me' | translate}}</button>
		</span>
	</p-footer>

	<p-footer *ngIf="confirmationStep === 3" class="d-flex align-items-center justify-content-between">
		<span>
			<button class="btn btn-danger pull-left mx-1" (click)="overtimeAlert = false" *ngIf="popupClosable"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button class="btn btn-orange pull-left mx-1"  (click)="nextStep(2)" pTooltip="{{'Back' | translate}}"><i class="fa-solid fa-arrow-left mr-2"></i>{{'Back' | translate}}</button>
		</span>
        <button class="btn btn-primary pull-right" (click)="confirm()" [disabled]="disabledConfirm()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>

	<p-footer *ngIf="confirmationStep === 4" class="d-flex align-items-center justify-content-between">
		<span>
			<button class="btn btn-danger pull-left" (click)="overtimeAlert = false" *ngIf="popupClosable"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button class="btn btn-orange pull-left mx-1"  (click)="nextStep(2)" pTooltip="{{'Back' | translate}}"><i class="fa-solid fa-arrow-left mr-2"></i>{{'Back' | translate}}</button>
		</span>
        <button class="btn btn-primary pull-right" (click)="confirm()" [disabled]="disabledConfirm()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="overtimeConfirmationAlert" [positionTop]="200" [modal]="true" [baseZIndex]="99999999" [showHeader]="true" [style]="{'width' : '100%', 'max-width' : '600px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-info mr-2"></i>{{'Overtime Request' | translate}}</span>
	</p-header>
    <div class="ui-g pb-3">
		<div class="ui-g-12 pb-0">
			<div  *ngIf="overtimeConfirmation.status === 'REJECTED'" class="alert alert-danger  alert-dismissible d-flex align-items-center" role="alert">
				<i class="fa-solid fa-triangle-exclamation mr-2"></i>
				<div>{{'Your overtime request has been rejected.' | translate}}</div>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div *ngIf="overtimeConfirmation.status === 'APPROVED'" class="alert alert-success  alert-dismissible d-flex align-items-center" role="alert">
				<i class="fa-solid fa-circle-check mr-2"></i>
				<div>{{'Overtime approved, authorization number sent to your email.' | translate}}</div>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div *ngIf="confirmationError !== ''" class="alert alert-danger  alert-dismissible d-flex align-items-center" role="alert">
				<i class="fa-solid fa-triangle-exclamation mr-2"></i>
				<div>{{confirmationError}}</div>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
		</div>
		<div  *ngIf="overtimeConfirmation.status === 'REJECTED'" class="ui-g-12 pt-0">
			<span style="font-size: 16px">{{'Sorry, your overtime request was not approved. You can try modifying your request and re-submitting again.'}}</span>
		</div>
        <ng-container *ngIf="overtimeConfirmation.status === 'APPROVED'">
            <div class="ui-g-12 pt-0">
                <label>{{'Authorization Number' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="confirmation.authorizedNumber" class="form-control">
	            </div>
			</div>
        </ng-container>
    </div>
    <p-footer *ngIf="overtimeConfirmation.status === 'APPROVED'"  class="d-flex align-items-center justify-content-between">
		<button class="btn btn-danger pull-left" (click)="overtimeConfirmationAlert = false"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button class="btn btn-primary pull-right" [disabled]="!confirmation.authorizedNumber || confirmation.authorizedNumber === ''" (click)="confirmAuthorizedNumber()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
	<p-footer *ngIf="overtimeConfirmation.status === 'REJECTED'" class="d-flex align-items-center justify-content-end">
		<button class="btn btn-danger pull-right" (click)="overtimeConfirmationAlert = false"><i class="fa-solid fa-xmark mr-2"></i>{{'Close' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="show" *ngIf="show" [modal]="true" [baseZIndex]="88888" [showHeader]="true" (onHide)="handleNoAction()" [style]="{'width':'90%', 'max-width': '700px'}" id="raiseConcernModal">
	<p-header>
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Raise Concern' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12 py-3">
			<h3 class="m-0">{{'Raise Concern' | translate}}</h3>
			<span style="font-size: 16px">{{'If you have any concerns or issues, let us know by providing the info below.' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'When did the issue arise?' | translate}}<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-days"></i>
					</button>
				</div>
				<p-calendar id="startsDate" [showIcon]="false" [(ngModel)]="todo.startsDate" placeholder="{{'Start Date' | translate}}" [showTime]="true" dateFormat="mm/dd/yy" hourFormat="12" readonlyInput="true"></p-calendar>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Subject' | translate}}<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-inbox"></i>
					</button>
				</div>
				<p-autoComplete id="concernSubject" [(ngModel)]="todo.regarding" [suggestions]="subjectSearchResults" (completeMethod)="filterRegardingSelect($event)" [size]="30" placeholder="{{'Enter or Select' | translate}}" [minLength]="1" class="w-100" [dropdown]="true">
					<!--<ng-template let-item pTemplate="item">-->
						<!--<span class="text-truncate">{{ item.label | translate }}</span>-->
					<!--</ng-template>-->
				</p-autoComplete>
			</div>
		</div>
		<div class="ui-g-12">
			<label>{{'Description' | translate}}<span class="text-danger">*</span></label>
			<p-editor #detailsEditor maxlength="1501" (onTextChange)="textChanged($event)" (onInit)="setEditor($event)" [(ngModel)]="todo.details" [style]="{'height':'220px'}" placeholder="{{'Please enter 25 characters minimum' | translate}}"></p-editor>
			<span class="pull-right" [ngClass]="charactersNumber < 1500 ? 'text-muted' : 'text-danger'" style="font-size: 12px;">{{charactersNumber}}/1500</span>
		</div>
	</div>
	<p-footer class="d-flex align-items-center justify-content-between">
		<button (click)="handleNoAction()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Close' | translate}}</button>
		<button [disabled]="isSubmitDisabled() || addToDoClicked" (click)="addToDo()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showEventsPopUp" [modal]="true" [showHeader]="true" [style]="{'width':'90%', 'max-width': '700px'}">
	<p-header>
		<span><i class="fa-solid fa-bell mr-2"></i>{{'Event Reminder' | translate}}</span>
	</p-header>
    <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
        <div class="ui-g" style="width:100%">
            <div class="ui-g-1"></div>
            <div class="ui-g-10" style="padding: 0px 20px 0px 20px ;">
                <div style="border-top: 1px solid black;border-bottom: 1px solid black;width:100%;line-height: 0px;">
                    <h6 style="margin: 8px 0px 8px 0px;">{{'A Friendly Reminder' | translate}}</h6>
                </div>
                <h4 style="font-size: 30px;margin-top: 5px;">{{'It is only' | translate}} {{events?.timeLeft}} {{'Away' | translate}}</h4>
                <div style="text-align: center">
                    <img src="https://livestore.operrwork.com/operrwork/2021_1_6__7_43_57__6__crm_event_reminder.png" height="250px" >
                </div>
                <div>
                    <h5> {{events?.event?.crmEventType?.name}}</h5> <br>
                </div>
                <div>
                    <div class="ui-g-1">
                        <div class="date-div">
                            <h5> {{events?.eventDay}} </h5>
                        </div>
                    </div>
                    <div class="ui-g-5">
                        <div>
                            <h6> {{events?.eventDate}}</h6>
                            <p> {{events?.event?.startDate | date:'shortTime' : 'UTC' }} UTC</p>
                            </div>
                    </div>
                    <div class="ui-g-1">
                        <div >
                            <span class="material-icons" style="font-size: 40px;">
                                location_on
                                </span>
                            </div>
                    </div>
                    <div class="ui-g-5">
                        <div>
                            <h6> {{events?.event?.location }} </h6>
                         </div>
                    </div>
                </div>
            </div>
            <div class="ui-g-1"></div>
        </div>
    </div>
</p-dialog>
<p-dialog [(visible)]="showDeadlinesPopUp" [modal]="true" [showHeader]="true" header="Deadline Reminder">
    <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1" style="width: 100%">
        <div class="ui-g" style="width:100%">
            <div class="ui-g-12" style="padding: 0px 20px 0px 20px ;text-align: center;">
                <div class="border-top border-bottom" style="line-height: 0px;">
                    <h6 style="margin: 8px 0px 8px 0px;">{{'A Friendly Reminder' | translate}}</h6>
                </div>
                <h4 style="font-size: 20px;margin-top: 5px;">{{'It is only' | translate}} {{deadlineReminder?.timeLeft}} {{'Away' | translate}}</h4>
                <div style="text-align: center;margin-right: 50px;">
                    <img src="https://livestore.operrwork.com/operrwork/2021_1_6__7_43_57__6__crm_event_reminder.png" height="150px" >
                </div>
                <div style="text-align: center; word-break: break-word;">
                    <h5> {{deadlineReminder?.event?.subjectTo}}</h5> <br>
                </div>
                <div>
                    <div class="ui-g">
                        <div class="ui-g-1"></div>
                        <div class="ui-g-5">
                            <h5>{{'Receive Date' | translate}}</h5>
                        </div>
                        <div class="ui-g-1"></div>
                        <div class="ui-g-5">
                            <h5>{{'Deadline' | translate}}</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ui-g-1"></div>
                    <div class="ui-g-1">
                        <div class="date-div">
                            <h5> {{deadlineReminder?.recieve}} </h5>
                        </div>
                    </div>
                    <div class="ui-g-4">
                        <div style="text-align: left;
						padding-left: 13px;">
                            <h6> {{deadlineReminder?.receiveDate}}</h6>
                            <label> {{deadlineReminder?.event?.receiveDate | date:'shortTime':timeZone.timezone }} {{timeZone.tzone}}</label>
                            </div>
                    </div>
                    <div class="ui-g-1"></div>
                    <div class="ui-g-1">
                        <div class="date-div">
                            <h5> {{deadlineReminder?.deadline}} </h5>
                        </div>
                    </div>
                    <div class="ui-g-4">
                        <div style="text-align: left;
						padding-left: 13px;">
                            <h6> {{deadlineReminder?.deadlineDate}}</h6>
                            <label> {{deadlineReminder?.event?.deadline | date:'shortTime':timeZone.timezone }} {{timeZone.tzone}}</label>
                            </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</p-dialog>

<!--Clock reminder popup-->

<p-dialog [(visible)]="isOpenClockingReminder" header ="Clocking Reminder" styleClass="w-50" id="id_show_pop_up1" [modal]="true" [baseZIndex]="10000">
    <div class="card card-w-title p-0 border-0 m-0">
        <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
                <div class="ui-g-12 ui-md-12">
                    <div class="alert alert-info" role="alert">
                        <span>{{reminderMessage}}</span>
                    </div>
                </div>
            <div class="ui-g-12 ui-md-12 text-center pb-3">
                <button pButton class="w-auto" label="Ok" (click)="closeReminderPopUp()"></button>
            </div>
        </div>
    </div>
</p-dialog>

<!--Agreement popup for first time login-->
<p-dialog [modal]="true" id="TOSModal" [closable]="false" [(visible)]="showAgreementDialog" [style]="{'width': '90%','max-width':'900px','max-height':'85vh' }">
	<p-header>
		<span><i class="fa-solid fa-square-check mr-2"></i>{{'Terms of Service' | translate}}</span>
	</p-header>
    <app-user-agreement></app-user-agreement>
    <p-footer class="d-flex flex-wrap align-items-center justify-content-between" style="row-gap: 10px">
		<div class="ui-g-12 text-center p-0">
			<p-checkbox [(ngModel)]="checkAgreement" (click)="showOnlyOkBtn()" binary="true" label="{{'I agree to the terms of service' | translate}}"></p-checkbox>
		</div>
		<button type="button" (click)="onClickLogout()" class="btn btn-danger text-nowrap mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" [disabled]="!showOkBtn" (click)="submitAgreement()" class="btn btn-primary text-nowrap mx-1"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	
    </p-footer>
</p-dialog>

<p-dialog [modal]="true" [closable]="true" [(visible)]="showPopupUserAgreement"  [style]="{'width': '90%','max-width':'900px','max-height':'85vh' }">
	<p-header>
		<span><i class="fa-solid fa-square-check mr-2"></i>{{'Terms of Service' | translate}}</span>
	</p-header>
    <app-user-agreement></app-user-agreement>
    <br>
    <p-footer class="flex-center">
        <button type="button" (click)="closeUserAgreement()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Continue' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [modal]="true"  [closable]="true" [(visible)]="showPopupUserPrivacy"  [style]="{'width': '90%','max-width':'900px','max-height':'85vh' }">
	<p-header>
		<span><i class="fa-solid fa-shield mr-2"></i>{{'Privacy Policy' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<app-user-privacy></app-user-privacy>
		</div>
	</div>
    <br>
    <p-footer class="flex-center">
        <button type="button" (click)="closeUserPrivacy()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Continue' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog *ngIf="showSurvey" [(visible)]="showSurvey" [modal]="true" [style]="{width: '55vw'}" [showHeader]="false">
    <div class="ui-g-12 ui-custom-panel">
        <div class="ui-g panel-border">
            <h6 class="mb-2">{{'According to your expereince during the time you are using Operrwork, how would you rate the system' | translate}}</h6>
            <p-accordion>
                <p-accordionTab header="{{'all' | translate}}" [selected]="true">
                    <p-table #dt [value]="employeePerformances" [rows]="100" dataKey="id"
                        [style]="{'margin-bottom':'20px'}" (onLazyLoad)="getPerformanceCriteria($event)" [lazy]="true"
                        [responsive]="true">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>{{'Criteria' | translate}}</th>
                                <th style="text-align: center; width:15%;">{{'NeedsImprovement' | translate}}</th>
                                <th style="text-align: center; width:15%;">{{'MeetsExpectations' | translate}}</th>
                                <th style="text-align: center; width:15%;">{{'ExceedsExpectations' | translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                            <tr class="table-subheader"
                                *ngIf="isNewCategory(rowData?.criteriaCategory?.name, rowIndex)">
                                <td colspan="4">
                                    <span style="font-weight:bold">{{rowData?.criteriaCategory?.name}}</span>
                                </td>
                            </tr>
                            <tr [pSelectableRow]="rowData">
                                <td>{{rowData?.criteriaName}}</td>
                                <td pEditableColumn style="text-align: center;">
                                    <p-cellEditor>
                                        <ng-template style="text-align: center;" pTemplate="input">
                                            <p-radioButton (click)="updateCategoryValue(rowData)"
                                                name="{{rowData?.criteria?.criteriaCategory?.name + rowData?.criteria?.criteriaName + rowIndex + rowIndex}}"
                                                value="1" [(ngModel)]="rowData.value" inputId="preopt1"></p-radioButton>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-radioButton (click)="updateCategoryValue(rowData)"
                                                name="{{rowData?.criteria?.criteriaCategory?.name + rowData?.criteria?.criteriaName + rowIndex + rowIndex}}"
                                                value="1" [(ngModel)]="rowData.value" inputId="preopt1"></p-radioButton>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn style="text-align: center;">
                                    <p-cellEditor>
                                        <ng-template style="text-align: center;" pTemplate="input">
                                            <p-radioButton (click)="updateCategoryValue(rowData)"
                                                name="{{rowData?.criteria?.criteriaCategory?.name + rowData?.criteria?.criteriaName + rowIndex + rowIndex}}"
                                                value="2" [(ngModel)]="rowData.value" inputId="preopt2"></p-radioButton>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-radioButton (click)="updateCategoryValue(rowData)"
                                                name="{{rowData?.criteria?.criteriaCategory?.name + rowData?.criteria?.criteriaName + rowIndex + rowIndex}}"
                                                value="2" [(ngModel)]="rowData.value" inputId="preopt2"></p-radioButton>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn style="text-align: center;">
                                    <p-cellEditor>
                                        <ng-template style="text-align: center;" pTemplate="input">
                                            <p-radioButton (click)="updateCategoryValue(rowData)"
                                                name="{{rowData?.criteria?.criteriaCategory?.name + rowData?.criteria?.criteriaName + rowIndex + rowIndex}}"
                                                value="3" [(ngModel)]="rowData.value" inputId="preopt3"></p-radioButton>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-radioButton (click)="updateCategoryValue(rowData)"
                                                name="{{rowData?.criteria?.criteriaCategory?.name + rowData?.criteria?.criteriaName + rowIndex + rowIndex}}"
                                                value="3" [(ngModel)]="rowData.value" inputId="preopt3"></p-radioButton>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                            <tr *ngIf="rowIndex >= (employeePerformances.length -1)">
                                <th>{{'TotalPerformancePoints' | translate}}</th>
                                <th colspan="3" style="text-align: center; width:45%;">
                                    {{calculateTotals(employeePerformances)}}</th>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
    <div class="ui-g-12 text-center">
        <button pButton label="Submit" (click)="saveSurvey()"></button> &nbsp;
        <button pButton label="Cancel" (click)="showSurvey=false"></button>
    </div>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="showCountDown" [showHeader]="false" class="count-down">
    <div style="text-align:center">
        <h6 style="font-size: 100px; color: #7e7e7e; margin-top:17%;"> {{timeLeft}} </h6> 
    </div>
</p-dialog>

<p-dialog [(visible)]="showLimit" [showHeader]="false" position="bottomright" class="limit-record"
    [style]="{width: '8vw'}">
    <div class="ui-g-12" style="padding:0;margin-bottom:-8px">
        <div class="ui-g-4" style="padding:0;text-align:right">
            <a (click)="stopRecording()">
                <i class="topbar-icon material-icons" style="color:rgb(70, 70, 236)">pause_circle_filled</i>
            </a>
        </div>
        <div class="ui-g-8" style="padding:0;text-align:center">
            <span>{{displayTime}} </span>
        </div>
    </div>
</p-dialog>

<p-dialog header="Confirmation" [modal]="true" [(visible)]="isRefresh" [closable]="true" class="refresh-dialog">
    <div style="text-align:center" class="p-2">
     <p>{{'Would you like system to log you out after refreshing the page' | translate}}</p>
    </div>
    <div class="ui-g-12 text-center">
        <button pButton label="Yes" (click)="triggerLogOut()"></button> &nbsp;
        <button pButton label="No" (click)="refreshCall()"></button>
    </div>
</p-dialog>


<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showPopupTotalJobUpload" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Hi Guys' | translate}}<br>
        {{clientNameUpload}} {{'just upload new file to the system' | translate}}<br>
        {{'Thanks' | translate}},
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Ok" (click)="acceptUploadTotalJob()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showPopupBillingReport" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Hi Guys' | translate}}<br>
        {{clientNameUpload}} {{'just upload new file to the system' | translate}}<br>
        {{'Thanks' | translate}},
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Ok" (click)="acceptUploadBillingReport()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showPopupBillingDetailReport" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Hi Guys' | translate}}<br>
        {{clientNameUpload}} {{'just upload new file to the system' | translate}}<br>
        {{'Thanks' | translate}},
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Ok" (click)="acceptUploadLogDetail()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showPopupCorrectionReport" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Hi Guys' | translate}}<br>
        {{clientNameUpload}} {{'just upload new file to the system' | translate}}<br>
        {{'Thanks' | translate}},
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Ok" (click)="acceptCorrectionReport()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showPopupLogTotalJob" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Hi Guys' | translate}}<br>
        {{clientNameUpload}} {{'just upload new file to the system' | translate}}<br>
        {{'Thanks' | translate}},
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Ok" (click)="acceptLogTotalJob()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showPopupLogDetailReport" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Hi Guys' | translate}}<br>
        {{clientNameUpload}} {{'just upload new file to the system' | translate}}<br>
        {{'Thanks' | translate}},
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Ok" (click)="acceptLogDetailReport()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Pop-ups blocked' | translate}}" [(visible)]="showPopupVideoRecording" class="modal_in"
    [modal]='true'>
    <div class="ui-g">
        {{'Pop-ups blocked, please disable your pop-up blocker' | translate}}
    </div>
    <p-footer>
        <div class="ui-g-12 ui-md-12 text-center pb-3">
            <button pButton class="w-auto" label="Open Recording" (click)="openVideoRecording()"></button>
        </div>
    </p-footer>
</p-dialog>


<p-dialog [(visible)]="showJobReportPopupReminder" class="modal_in JR" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}</span>
	</p-header>
    <div class="ui-g">
        <div class="ui-g-12 text-center">
			<h2 class="text-danger">{{'Job Report updates missing!' | translate}}</h2>
			<span style="font-size: 16px">{{'You have no job report updates in the last hour.' | translate}}</span><br>
			<span style="font-size: 16px">{{'Would you like to update your job report?' | translate}}</span><br>
			<span class="text-muted mt-2">({{'If you are not currently working, please clock out.' | translate}})</span><br>
		</div>
    </div>
    <p-footer class="d-flex justify-content-end">
		<button type="button" (click)="confirmJobReportPopupReminder()" class="btn btn-primary" #buttonConfirmJobReportPopupReminder>
			<i class="fa-solid fa-check mr-2"></i>{{'Add/Update Now' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i>
		</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="updateExpiredCardModal" [modal]="true" [baseZIndex]="88888" [showHeader]="true" (onHide)="updateExpiredCardModal = false" [style]="{'width':'90%', 'max-width': '700px'}" id="updateExpiredCardModal">
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Reminder' | translate}}</span>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-12 text-center">
            <span style="font-size: 16px">{{'It appears that the credit card on file for your account has expired. To ensure uninterrupted service and continued access to our offerings, please take a moment to update your payment information now.' | translate}}</span><br>
        </div>
    </div>

    <p-footer class="d-flex flex-wrap align-items-center justify-content-between" style="row-gap: 10px">
        <button type="button" (click)="updateExpiredCardModal = false" class="btn btn-danger text-nowrap mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="navigateToCard()" class="btn btn-primary text-nowrap mx-1"><i class="fa-solid fa-check mr-2"></i>{{'OK' | translate}}</button>
    </p-footer>
</p-dialog>

<p-overlayPanel #addBalanceModal appendTo="body" [dismissable]="false" [showCloseIcon]="true" [style]="{'margin-top':'35px'}" (onHide)="selectedAmount = null;">
    <div class="ui-g" style="max-width: 400px;">
        <div class="ui-g-12 pt-3 d-flex align-items-center justify-content-between">
            <div *ngIf="selectedMethod">
                <div *ngIf="selectedMethod?.id && selectedMethod?.id == customerCard?.id" style="font-size: 16px">{{selectedMethod?.paymentCardType | titlecase}} Ending {{selectedMethod?.lastFourNumbers}} (Primary)</div>
                <div *ngIf="selectedMethod?.id && selectedMethod?.id == customerBank?.id" style="font-size: 16px">Bank account Ending {{selectedMethod?.lastFourNumbers}} (Primary)</div>
            </div>
            <button pButton label="Change" (click)="changeMethodModal = true" [disabled]="!selectedMethod"></button>
        </div>
        <div class="ui-g-12">
            <label>{{'Amount' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar"></i>
					</button>
				</div>
                <input type="text" twoDigitDecimaNumber pInputText [(ngModel)]="selectedAmount" class="w-100" [disabled]="!selectedMethod">
                <button type="button" class="btn btn-primary ml-2" (click)="openAddAmountModal()" [disabled]="!selectedMethod || !selectedAmount">
                    {{'Add' | translate}}
                </button>
			</div>
        </div>
        <div class="ui-g-12">Please click <a *ngIf="isMerchantAccount" style="text-decoration: none; width: 100%; overflow:hidden; white-space: nowrap; text-overflow: ellipsis;" [routerLink]="['/app/payments/wallet']">here</a> to link your bank account.</div>
    </div>
</p-overlayPanel>

<p-dialog [(visible)]="changeMethodModal" [modal]="true" [baseZIndex]="88888" [showHeader]="true" (onHide)="changeMethodModal = false" [style]="{'width':'90%', 'max-width': '600px'}" id="changeMethodModal">
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Change Method' | translate}}</span>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-12 d-flex align-items-center justify-content-between">
            <p-radioButton [style]="{'width': '210px'}" *ngIf="customerCard" [(ngModel)]="changeMethodSelected" name="paymentMethod" [value]="customerCard" label="{{selectedMethod?.paymentCardType | titlecase}} Ending {{selectedMethod?.lastFourNumbers}}" [inputId]="customerCard?.id"></p-radioButton>
            <p-radioButton [style]="{'width': '210px'}" *ngIf="customerBank" [(ngModel)]="changeMethodSelected" name="paymentMethod" [value]="customerBank" label="Bank account Ending {{selectedMethod?.lastFourNumbers}}" [inputId]="customerBank?.id"></p-radioButton>
          </div>
    </div>

    <p-footer class="d-flex flex-wrap align-items-center justify-content-between" style="row-gap: 10px">
        <button type="button" (click)="onChangeMethodClose()" class="btn btn-danger text-nowrap mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="onChangeMethodSelected()" class="btn btn-primary text-nowrap mx-1"><i class="fa-solid fa-check mr-2"></i>{{'OK' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog header="Confirmation" [style]="{'width':'30%'}" [(visible)]="showAddAmountConfirmDialog" modal='modal' [modal]='true'>
    <div class="ui-g p-2 confirm">
        <div>
			{{'Are you sure you want to send' | translate}}  {{selectedAmount | currency}} {{'to your wallet' | translate}} ?
        </div>
        <div class="w-100 p-2">
            <div class="ui-g">
                <div class="ui-g-2 p-0">{{'Amount' | translate}}</div>
                <div class="ui-g-10 p-0">{{selectedAmount | currency}}</div>
            </div>
            <div class="ui-g">
                <div class="ui-g-2 p-0">{{'Fee' | translate}}</div>
                <div class="ui-g-10 p-0">{{selectedFeeAmount | currency}}</div>
            </div>
            <div class="ui-g">
                <div class="ui-g-2 p-0">{{'Net' | translate}}</div>
                <div class="ui-g-10 p-0">{{selectedAmount - selectedFeeAmount | currency}}</div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-g-12 p-0 d-flex justify-content-center align-items-center">
            <button type="button" class="btn btn-primary mx-2" (click)="addBalanceAccount()" [disabled]="!selectedMethod || !selectedAmount || addAmountSummiting">
                <i *ngIf="addAmountSummiting" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Submit' | translate}}
            </button>
            <button type="button" class="btn btn-danger mx-2" (click)="showAddAmountConfirmDialog = false">
                {{'Cancel' | translate}}
            </button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showComplianceCheckPopUp" [modal]="true" [showHeader]="true" [style]="{'width':'90%', 'max-width': '500px'}">
    <p-header>
        <span><i class="fa-solid fa-circle-info mr-2"></i>{{'Compliance Check' | translate}}</span>
    </p-header>
    <div class="ui-g p-3">
		<div *ngIf="companyInfoPage" class="ui-g-12 pb-2">
			<h4 class="m-0">{{'Company Overview' | translate}}</h4>
            <div style="font-size: 16px">{{'Please review all of your current company information below for accuracy' | translate}}:</div>
        </div>
        <div *ngIf="!companyInfoPage" class="ui-g-12 pb-2">
			<h4 class="m-0">{{'Worker Overview' | translate}}</h4>
            <div style="font-size: 16px">{{'Please review all statistical information for your company staff below' | translate}}:</div>
        </div>
    </div>
    <div class="ui-g-12 p-0" *ngIf="companyInfoPage">
        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-building text-primary mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Company'| translate}}</b>: {{company.name}}</span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-folder-tree text-primary mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Type'| translate}}</b>: {{getType(company.type)}}</span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-signs-post text-primary mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Address'| translate}}</b>: {{company.addressOne}}</span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-folder-tree text-primary mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Operational Policy'| translate}}</b>: {{company.operationalPolicy}}</span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-landmark-dome text-primary mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Federal Tax ID'| translate}}</b>: <span [ngClass]="!company.value ? 'text-muted' : ''">{{company?.value || 'none'}}</span></span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-earth-americas text-primary mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Time Zone'| translate}}</b>: {{company.timezone}}</span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-cart-shopping text-purple mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Plan'| translate}}</b>: {{getPlanName(company.planId)}}</span>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
            <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-dollar-sign text-purple mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border-radius: 50%;"></i>
                <span style="font-size: 16px" class="one-liner"><b>{{'Balance'| translate}}</b>: {{(accountBalance?.availableBalance) || 0 | currency}}</span>
            </span>
        </div>

    </div>
    <div class="ui-g-12 p-0" *ngIf="!companyInfoPage">

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/employee/list" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <span class="badge badge-primary mr-2" style="font-size: .9rem;background: #fff;position:unset">{{listEmployees.length || 0}}</span>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Employees'| translate}}</b></span>
            </span>

            <span pTooltip="{{listEmployees.length - complianceEmployeeCount}} still non-compliant" tooltipPosition="left">
                <span class="text-muted">{{'Compliant' | translate}}: </span>
                <span class="badge" [ngClass]="complianceEmployeeCount > 0 ? 'badge-success' : 'badge-muted'"  style="font-size: .9rem;background: #fff;position:unset">{{complianceEmployeeCount || 0}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/volunteer/list" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <span class="badge badge-primary mr-2" style="font-size: .9rem;background: #fff;position:unset">{{listVolunteer.length || 0}}</span>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Volunteers'| translate}}</b></span>
            </span>
            <span pTooltip="{{listVolunteer.length - complianceVolunteerCount}} still non-compliant" tooltipPosition="left">
                <span class="text-muted">{{'Compliant' | translate}}: </span>
                <span class="badge" [ngClass]="complianceVolunteerCount > 0 ? 'badge-success' : 'badge-muted'"  style="font-size: .9rem;background: #fff;position:unset">{{complianceVolunteerCount || 0}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/crm/freelancer/list" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <span class="badge badge-primary mr-2" style="font-size: .9rem;background: #fff;position:unset">{{listContractors.length || 0}}</span>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Contractors'| translate}}</b></span>
            </span>
            <span pTooltip="{{listContractors.length - complianceContractorsCount}} still non-compliant" tooltipPosition="left">
                <span class="text-muted">{{'Compliant' | translate}}: </span>
                <span class="badge" [ngClass]="complianceContractorsCount > 0 ? 'badge-success' : 'badge-muted'"  style="font-size: .9rem;background: #fff;position:unset">{{complianceContractorsCount || 0}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" (click)="goToPunchHistoryPage()">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-triangle-exclamation text-orange mr-2" style="font-size: 1.4rem"></i>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Missed Punches'| translate}}</b></span>
            </span>
            <!-- <span class="badge" [ngClass]="punchHistoryCount > 0 ? 'badge-orange' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{punchHistoryCount || 0 | number}}</span> -->
            
            <span *ngIf="missedPunchHistoryCountLoading" class="badge" [ngClass]="'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">loading...</span>
            <span *ngIf="!missedPunchHistoryCountLoading" class="badge" [ngClass]="missedPunchHistoryCount > 0 ? 'badge-orange' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{missedPunchHistoryCount || 0 | number}}</span>
            <i *ngIf="!missedPunchHistoryCountLoading" class="fa-solid fa-chevron-right text-muted ml-2"></i>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/employee-clocking/punch-history" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-house-circle-check text-success mr-2" style="font-size: 1.4rem"></i>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Started v. Ended Work'| translate}}</b> <span class="text-muted ml-1">({{'Today' | translate}})</span></span>
            </span>
            <span>
                <span class="badge mr-1"  [ngClass]="totalClockedIn > 0 ? 'badge-success' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{totalClockedIn}}</span>
                <span class="badge"  [ngClass]="totalEndWork > 0 ? 'badge-danger' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{totalEndWork}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/overtime/list" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-clock text-danger mr-2" style="font-size: 1.4rem"></i>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Workers w/ Overtime'| translate}}</b> <span class="text-muted ml-1">({{'This week' | translate}})</span></span>
            </span>
            <span>
                <span class="badge" [ngClass]="totalOvertime > 0 ? 'badge-danger' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{totalOvertime || 0 | number}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>


        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/absence/list" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-calendar-xmark text-info mr-2" style="font-size: 1.4rem"></i>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Time-Off Requests'| translate}}</b> <span class="text-muted ml-1">({{'Pending' | translate}})</span></span>
            </span>
            <span>
                <span class="badge" [ngClass]="totalAbsence > 0 ? 'badge-info' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{totalAbsence || 0 | number}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>

        <div class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer" routerLink="/app/employee/employee-job-report/list" routerLinkActive="active" target="_blank" rel="group" (click)="showComplianceCheckPopUp = false">
            <span class="d-flex flex-row align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                <i class="fa-solid fa-circle-exclamation text-warning mr-2" style="font-size: 1.4rem"></i>
                <span style="font-size: 16px" class="text-truncate"><b>{{'Missed Jop Reports'| translate}}</b></span>
            </span>
            <span>
                <span class="badge" [ngClass]="totalJobReports > 0 ? 'bg-warning' : 'badge-muted'" style="font-size: .9rem;background: #fff;position:unset">{{totalJobReports || 0 | number}}</span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </span>
        </div>
    </div>
    <div class="w-100 d-flex justify-content-center align-items-center flex-grow-1 pt-3 pb-2">
        <span class="text-muted"><span *ngIf="companyInfoPage">1</span><span *ngIf="!companyInfoPage">2</span>/2</span>
    </div>
    <p-footer class="d-flex justify-content-between">
		<button (click)="companyInfoPage = true" [disabled]="companyInfoPage" class="btn btn-orange"><i class="fa-solid fa-chevron-left mr-2"></i>{{'Prev' | translate}}</button>
		<button (click)="companyInfoPage = false" [disabled]="!companyInfoPage" class="btn btn-primary">{{'Next' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
	</p-footer>
</p-dialog>


<p-dialog [(visible)]="showComplianceEmployeeWizard" [modal]="true" [showHeader]="true" [style]="{'width':'90%', 'max-width': '500px'}">
    <p-header>
        <span><i class="fa-solid fa-magic-wand-sparkles mr-2"></i>{{'Compliance Wizard' | translate}} ({{currentPage + 1}}/{{paginatedDocumentDetails?.length || 0}})</span>
    </p-header>
	<div class="ui-g p-3">
		<div class="ui-g-12 pb-4">
			<h4 class="m-0">{{'Documents' | translate}}</h4>
			<div style="font-size: 16px">{{'Please complete all the mandatory compliance items and as many general compliance items as possible' | translate}}:</div>
            <div class="btn btn-white btn-sm border-0 mt-2 mr-2" style="position: unset;zoom:90%;cursor:default"><i class="fa-solid fa-o text-danger mr-2"></i><span class="text-dark">Mandatory</span></div>
            <div class="btn btn-white btn-sm border-0 mt-2" style="position: unset;zoom:90%;cursor:default"><i class="fa-solid fa-o text-warning mr-2"></i><span class="text-dark">Other</span></div>
        </div>                
        <div *ngIf="paginatedDocumentDetails.length" class="ui-g-12 p-0">
            <div *ngFor="let document of paginatedDocumentDetails[currentPage].data" (click)="handleShowCommmentPopup(document?.template?.name)"  class="card add-shadow flex-row align-items-center text-truncate flex-grow-1 m-2 py-2 pl-2 pr-3" id="complianceSummaryItem" style="cursor:pointer">
                <span class="d-flex align-items-center flex-grow-1 text-truncate pl-1" style="height:34px">
                    <!-- Use comNo to show completion status -->
                    <i *ngIf="!document.finish && document.complianceType === 'required'" class="fa-solid fa-circle-exclamation text-danger mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border: 2px solid #FFF;outline: 2px solid #ff000060;border-radius: 50%;"></i>
                    <i *ngIf="!document.finish && document.complianceType !== 'required'" class="fa-solid fa-circle-exclamation text-warning mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border: 2px solid #FFF;outline: 2px solid #ffe100;border-radius: 50%;"></i>

                    <!-- Show checkmark for completed items -->
                    <i *ngIf="document.finish && document.complianceType === 'required'" class="fa-solid fa-circle-check text-success mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border: 2px solid #FFF;outline: 2px solid #ff000060;border-radius: 50%;"></i>
                    <i *ngIf="document.finish && document.complianceType !== 'required'" class="fa-solid fa-circle-check text-success mr-2" aria-hidden="true" style="font-size: 1.4rem;background: #fff;border: 2px solid #FFF;outline: 2px solid #ffe100;border-radius: 50%;"></i>

                    <!-- Display document name -->
                    <span style="font-size: 16px" class="one-liner" pTooltip="{{document?.template?.name | translate}}">{{document?.template?.name | translate}}</span>
                </span>
                <i class="fa-solid fa-chevron-right text-muted ml-2"></i>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center flex-grow-1 pt-3 pb-2">
            <span class="text-muted">{{currentPage + 1}} / {{paginatedDocumentDetails?.length || 0}}</span>
        </div>
    </div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="currentPage = currentPage - 1" [disabled]="currentPage === 0" class="btn btn-orange"><i class="fa-solid fa-chevron-left mr-2"></i>{{'Prev' | translate}}</button>
		<button (click)="currentPage = currentPage + 1" [disabled]="currentPage >= paginatedDocumentDetails.length - 1" class="btn btn-primary">{{'Next' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
	</p-footer>
</p-dialog>


<p-dialog [modal]="true" *ngIf="showCommentPopup" [dismissableMask]="true" [(visible)]="showCommentPopup" (onHide)="closeCommentPopup()"
          [responsive]="true" showEffect="fade" [style]="{'width': '90%', 'max-width':'600px', 'max-height':'80vh'}" appendTo="body">
    <p-header>
        <span><i class="fa-solid fa-circle-plus mr-2"></i>{{selectedDocumentName | translate}}</span>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-12 px-3">
            <h5 class="mt-3">{{'Compliance Documents' | translate}}</h5>
        </div>
        <div class="ui-g-12 px-3">
            <label>{{'Upload Document' | translate}} <span class="text-danger">*</span></label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0 c-pointer">
                    <button class="btn btn-white" type="button" style="cursor:default !important" (click)="onUploadDocument(fileUpload)">
                        <i class="fa-solid fa-paperclip"></i>
                    </button>
                </div>
                <div (click)="onUploadDocument(fileUpload)" class="form-control d-flex justify-content-between align-items-start c-pointer" style="height: 40px;line-height: 2;overflow: hidden;"></div>
                <input type="file" hidden (change)="uploadFile($event)" placeholder="{{'Upload Document' | translate}}" accept=".docx,.pdf,.doc,.xlsx,.xls,.txt,.ppt,.pptx,.jpg,.jpeg,.png" #upload multiple>
                <div class="input-group-append">
                    <button class="btn btn-white c-pointer" type="button" style="cursor:default !important" (click)="onUploadDocument(fileUpload)">
                        <i class="fa-solid fa-upload text-primary" [ngClass]="{'text-success':  fileUploaded && fileUploaded.length >= 1}"></i>
                    </button>
                </div>
            </div>
            <p-overlayPanel [baseZIndex]="99999" #fileUpload appendTo="body">
                <ng-template pTemplate>
                    <ul class="m-0 px-1" style="list-style: none; min-width: 150px; line-height: 2">
                        <li (click)="onUploadDocument(fileUpload)" style="cursor: pointer;">
                            <span class="text-nowrap"><i class="fa-solid fa-file-upload text-primary mr-2"></i> {{'Upload' | translate}}</span>
                        </li>
                    </ul>
                </ng-template>
            </p-overlayPanel>
        </div>
        <div class="ui-g-12 px-3" *ngIf="selectedDocumentData.addExpDate">
            <label>{{'Expiration Date' | translate}}: <span class="text-danger">*</span></label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-calendar-day"></i>
                    </button>
                </div>
                <mat-form-field id="dueDateField1" class="w-100" (click)="dueDatePicker1.open()" appendTo="body">
                    <input matInput class="w-100 pl-3" [matDatepicker]="dueDatePicker1" (focus)="dueDatePicker1.open()" [(ngModel)]="selectedDocumentData.expirationDate" datePickerFormat="MM/DD/YYYY">
                    <mat-datepicker #dueDatePicker1 appendTo="body"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <app-document-form class="w-100" *ngIf="selectedDocumentData?.documentName !== 'Driver DMV Lic'"
                       #documentForm [document]="selectedDocumentData?.document"
                       [documentTemplate]="selectedDocumentData?.template"
                       [createdAt]="selectedDocumentData.createdAt">
    </app-document-form>
    <div class="ui-g" *ngIf="selectedDocumentData?.template?.name === 'Driver DMV Lic'">
        <div class="ui-g-12 px-3" >
            <label>{{'License number' | translate}}: </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button">
                        <i class="fa-solid fa-folder-tree"></i>
                    </button>
                </div>
                <input pInputText type="text" maxlength="20" [(ngModel)]="licenseNumberM" (keypress)="isNumber($event)">
            </div>
        </div>
        <div class="ui-g-12 px-3" >
            <label>{{'Exp Date' | translate}}: </label>
            <div class="input-group">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button"
                            style="cursor:default !important">
                        <i class="fa-solid fa-calendar-day"></i>
                    </button>
                </div>
                <mat-form-field class="w-100">
                    <input class="w-100" matInput (focus)="picker.open()" (keypress)="isDate($event)"
                           [matDatepicker]="picker" name="expDate" [(ngModel)]="expDateM">
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="ui-g-12 px-3" >
            <label>{{'form.state' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button"
                            style="cursor:default !important">
                        <i class="fa-solid fa-map"></i>
                    </button>
                </div>
                <p-dropdown [options]="listOfState"
                            [filter]="true" placeholder="{{'placeholder.pleaseSelect' | translate}}"
                            class="w100" [(ngModel)]="stateM">
                </p-dropdown>
            </div>
        </div>

        <div class="ui-g-12 px-3" >
            <label>{{'Send Alert' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button"
                            style="cursor:default !important">
                        <i class="fa-solid fa-folder-tree"></i>
                    </button>
                </div>
                <p-dropdown [options]="listSendAlert" [filter]="true"
                            placeholder="{{'placeholder.pleaseSelect' | translate}}"
                            class="w100" [(ngModel)]="sendAlertM">
                </p-dropdown>
            </div>
        </div>
    </div>

    <div *ngIf="selectedDocumentData && (fileUploaded.length > 0)" class="ui-g pb-3">
        <div class="ui-g-12 px-3">
            <hr>
            <h5>{{'Documents' | translate}}</h5>
            <div class="d-flex align-items-center flex-wrap flex-grow-1">
                <div *ngFor="let file of fileUploaded; let i = index" class="w-100">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <span class="one-liner" style="font-size:16px;max-height: 2rem;line-height: 2;" data-bs-toggle="collapse" data-bs-target="#uploadCollapse" aria-expanded="false" aria-controls="uploadCollapse">{{ i + 1 }}&#41; {{getFileName(file)}}</span>
                        <span class="d-flex">
							<i class="fa-solid fa-arrow-up-right-from-square text-muted text-primary mx-1" (click)="onViewDocument(file); $event.stopPropagation()" pTooltip="{{'Open' | translate}}" tooltipPosition="left" style="cursor: pointer"></i>
							<i class="fa-solid fa-xmark text-danger mx-1" (click)="removeFile(file); $event.stopPropagation()" pTooltip="{{'Delete' | translate}}" tooltipPosition="left" style="cursor: pointer"></i>
						</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer class="d-flex justify-content-between">
        <span>
            <button type="button" class="btn btn-danger mx-1" (click)="showCommentPopup = false;"><i class="fa-solid fa-xmark mr-2"></i> {{'Cancel' | translate}}</button>
            <button class="btn btn-white mx-1" type="submit" (click)="clearDocument(selectedDocumentData)" [disabled]="savingDocuments" *ngIf="selectedDocumentData.id">
                <i *ngIf="clearingDocuments" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>
                <i *ngIf="!clearingDocuments" class="fa-solid fa-trash text-danger mr-2"></i>{{ 'Clear' | translate }}
            </button>
        </span>
        <button *ngIf="selectedDocumentData?.template?.name === 'Driver DMV Lic'" pButton class="btn btn-primary mx-1" type="submit" (click)="onClickSaveDocumentDriver(selectedDocumentData, selectedDocumentData.file)"><i class="fa-solid fa-check mr-2"></i>{{ 'Save' | translate }}"</button>
        <button *ngIf="selectedDocumentData?.template?.name !== 'Driver DMV Lic'" class="btn btn-primary" type="submit" (click)="onClickSaveDocument(selectedDocumentData, selectedDocumentData.file)" [disabled]="documentForm?.formGroup?.invalid">
            <i *ngIf="savingDocuments" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>
            <i *ngIf="!savingDocuments" class="fa-solid fa-check mr-2"></i>{{ 'Save' | translate }}
        </button>
    </p-footer>
</p-dialog>
<p-dialog [(visible)]="showPunchDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'900px', 'max-height':'80vh'}" [closable]="true" id="punchConfirmationModal" appendTo="body" (onHide)="closePunchDialog()">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Confirm Weekly Hours' | translate}}</span>
    </p-header>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Week' | translate}}: <span *ngIf="punchFromDateDialog || punchToDateDialog" class="">{{punchFromDateDialog.slice(0, 5)}}<i class="fa-solid fa-right-long text-muted mx-1"></i>{{punchToDateDialog.slice(0, 5)}}</span></span>
        </p-header>
        <p-table #dtPunch [value]="punchHistoryList" [responsive]="true" dataKey="id" [customSort]="true" [lazy]="true" styleClass="punch-table" [rows]="10" [paginator]="false" [totalRecords]="totalPunchRecords" class="pioh-data">
            <ng-template pTemplate="header" let-columns let-i="index">
                <tr id="header-row">
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Date' | translate}}">{{'form.date' | translate}}</span>
                        </div>
                    </th>
                    <th style="width: 55px !important">
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Day' | translate}}">{{'Day' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Start Work' | translate}}">{{'Start Work' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Break' | translate}}">{{'Break' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="one-liner" pTooltip="{{'End Break' | translate}}">{{'End Break' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="one-liner" pTooltip="{{'Break Duration' | translate}}">{{'Break Duration' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="one-liner" pTooltip="{{'End Work' | translate}}">{{'End Work' | translate}}</span>
                        </div>
                    </th>
                    <th style="width: 70px !important">
                        <div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner">
								{{'Regular Hour(s)'  | translate}}
							</span>
                        </div>
                    </th>
                    <th style="width: 70px !important">
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'O.T.' | translate}}">{{'O.T.' | translate}}</span>
                        </div>
                    </th>
                    <th style="width: 70px !important">
                        <div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner">
								{{'total'  | translate}}
							</span>
                        </div>
                    </th>
                    <th style="width: 45px !important">
                        <div  class="d-flex align-items-center justify-content-center w-100">
                            <i class="fa-solid fa-location-dot text-muted m-auto" pTooltip="{{'Track' | translate}}" tooltipPosition="left"></i>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'form.date' | translate}}: </span>
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'MM/dd'}}"><b>{{rowData.punchDate | date: 'MM/dd'}}</b></span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Day' | translate}}: </span>
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'EEE'}}">{{rowData.punchDate | date: 'EEE'}}</span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td class="p-0">
                        <div class="d-flex align-items-center">
                            <div *ngIf="!rowData.editAllowedCheckIn && !isExceptionPunchInOut(rowData.locationName)" class=" text-left pl-lg-2 pl-sm-0">
								<span class="one-liner d-flex">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.clockInTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedStartWork && rowData.clockInTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockInTimeOriginal" pTooltip="{{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockInTimeOriginal" pTooltip="{{ rowData.missedStartWork? ('Missing' | translate) : ((rowData.clockInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
									</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedCheckIn && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy) : ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
                                    {{ rowData.locationName | translate}}
								</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2">
                                <span class="one-liner" (click)="expand(rowData)">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span>
									<span class="text-primary c-pointer">{{'Details' | translate}}</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedOutForLunch && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-2 pl-sm-0 text-left">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null && rowData.employeeClockingLunchHourHistory.length > 1))"  class="one-liner d-flex">
									<span class="mobile-table-label">{{'Break' | translate}}:</span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedLunchOutTime && rowData.lunchOutTime == null ? '--:--' : ''}}</span>
									<span class="d-flex justify-content-between align-items-center" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'" >
										<span *ngIf="rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : ((rowData.lunchOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
									</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedOutForLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger text-center' : 'text-center'" >
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span>
                                    {{ rowData.locationName | translate}}
								</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2" >
                                <span class="one-liner" (click)="expand(rowData)">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									<span class="text-primary c-pointer">{{'Details' | translate}}</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedBackFromLunch && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-2 pl-sm-0 text-left">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory !=null && rowData.employeeClockingLunchHourHistory.length > 1))" class="one-liner d-flex">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger' : ''">{{! rowData.missedLunchInTime && rowData.lunchInTime == null ? '--:--' : ''}}</span>
									<span  [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : ((rowData.lunchInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
									</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedBackFromLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
                                    {{ rowData.locationName | translate}}
								</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                        <div *ngIf="isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center pl-lg-2">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Break Total' | translate}}: </span>{{rowData.locationName | translate}}
							</span>
                        </div>
                        <div *ngIf="!isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center justify-content-between pl-lg-2 w-100">
							<span class="one-liner">
								<span class="mobile-table-label">{{'LunchTot' | translate}}: </span>
                                {{calCulateTotalLunchTothhmm(rowData)}}
							</span>
                        </div>
                    </td>
                    <td class="p-0">
                        <div class="d-flex flex-wrap align-items-center justify-content-start">
                            <span class="mobile-table-label mobile-left text-nowrap">{{'WorkEnd' | translate}}: </span>
                            <span *ngIf="(!rowData.clockOutTime || !rowData.editAllowedCheckOut) && isExceptionPunchInOut(rowData.locationName)" class="text-nowrap text-truncate text-muted pl-3">{{'no data' | translate}}</span>
                            <div *ngIf="!rowData.editAllowedCheckOut && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-3 pl-sm-0 text-left">
								<span class="one-liner d-flex">
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left"  [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedEndWork && rowData.clockOutTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'hh:mm a'}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="bottom" >{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : ((rowData.clockOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
									</span>
								</span>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowData.spreadHour && rowData.spreadHour > 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> 
                            <span *ngIf="rowData.workHourShadow" class="mobile-big">{{rowData.workHourShadow}}  <strong style="color: orangered;" [pTooltip]="rowData.spreadHourText" tooltipPosition="top"> + 01:00 </strong></span>
                            <span *ngIf="!rowData.workHourShadow" class="text-muted">--:--</span>
						</span>
                    </td>
                    <td *ngIf="rowData.spreadHour == null || rowData.spreadHour == 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> 
                            <span *ngIf="rowData.workHourShadow" class="mobile-big">{{rowData.workHourShadow}}</span>
                            <span *ngIf="!rowData.workHourShadow" class="text-muted">--:--</span>

						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Overtime' | translate}}: </span>
							<span *ngIf="rowData.overTimeStr" pTooltip="{{rowData.overTimeStr}}">{{rowData.overTimeStr}}</span>
							<span *ngIf="!rowData.overTimeStr" class="text-muted">--:--</span>
						</span>
                    </td>
                    <td *ngIf="rowData.spreadHour && rowData.spreadHour > 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> 
                            <span *ngIf="rowData.workHoursNew" class="mobile-big">{{rowData.workHoursNew}}  <strong style="color: orangered;" [pTooltip]="rowData.spreadHourText" tooltipPosition="top"> + 01:00 </strong></span>
                            <span *ngIf="!rowData.workHoursNew" class="text-muted">--:--</span>
						</span>
                    </td>
                    <td *ngIf="rowData.spreadHour == null || rowData.spreadHour == 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> 
                            <span *ngIf="rowData.workHoursNew" class="mobile-big">{{rowData.workHoursNew}}</span>
                            <span *ngIf="!rowData.workHoursNew" class="text-muted">--:--</span>
						</span>
                    </td>
                    <td>
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Location' | translate}}: </span>
							<i *ngIf="rowData.lat && rowData.lng && (!rowData.missedStartWork || !rowData.missedEndWork)" class="fa-solid fa-location-dot text-primary c-pointer"  (click)="viewMap(rowData)" pTooltip="{{'View Map' | translate}}" tooltipPosition="left"></i>
                            <i *ngIf="!rowData.lat || !rowData.lng || rowData.missedStartWork || rowData.missedEndWork" class="fa-solid fa-location-dot text-muted c-pointer"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
                <tr id="footer-row">
                    <td colspan="6">
						<div>
							<button class="btn btn-white btn-sm mx-1" (click)="redirectToPunchHistory();showPunchDialog = false;">
								<span class="text-nowrap"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{'Make Corrections' | translate}}<i class="fa-solid fa-chevron-right text-muted ml-2"></i></span>
							</button>
						</div>
					</td>
                    <td><b class="desktop-table-label pull-right">{{'total' | translate}}:</b></td>
                    <td class="bold-text">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Total Hours' | translate}}: </span>
							<span pTooltip="{{getTotalHour(punchHistoryList)}}" tooltipPosition="left">{{getTotalHour(punchHistoryList)}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Total Overtime' | translate}}: </span>
                            {{getTotalOverrtime(punchHistoryList)}}
						</span>
                    </td>
                    <td class="bold-text">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Total Hours' | translate}}: </span>
							<span pTooltip="{{getTotalHourNew(punchHistoryList)}}" tooltipPosition="left">{{getTotalHourNew(punchHistoryList)}}</span>
						</span>
                    </td>
                    <td></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!punchHistoryList">
                    <td [attr.colspan]="13" class="text-center py-5">
                        <div class="text-muted my-5">{{'no data' | translate}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
	<br>
	<div class="d-flex w-100 py-2 text-center">
		<span style="font-size: 16px"><i class="fa-solid fa-hand-point-right text-orange mr-2" style="font-size: 18px"></i><p-checkbox [binary]="true" [(ngModel)]="confirmWeeklyHoursCorrect"></p-checkbox><b>{{'I confirm (to the best of my knowledge) that the above time record for the week is accurate and reflects the hours I worked. The weekly hours are true and do not need any time corrections.' | translate}}</b></span>
	</div>


		<div class="d-flex justify-content-center py-4">
			<div class="card add-shadow p-0" id="signature-pad">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-signature text-primary mr-2"></i>{{'Signature Pad' | translate}} </h5>
					</div>
				</div>

				<div id="signLicensor" class="bg-light" style="width: 100%; height: 100%;">
					<!-- Image when signature pad is not shown -->
					<img *ngIf="!showSignaturePadConfirm" src="{{content.fileUrl}}" style="height: 350px; width: 100%;" />
				
					<!-- Signature pad when signature pad is shown -->
					<signature-pad 
						*ngIf="showSignaturePadConfirm" 
						#signaturePadConfirm 
						[options]="signaturePadConfirmConfig" 
						(onBeginEvent)="drawStartSignaturePad()" 
						(onEndEvent)="drawCompleteSignaturePad()" 
						class="signature-pad">
					</signature-pad>
				</div>
				<div class="card-footer text-center">
					<button class="btn btn-white" (click)="clearLicensorSign()">
						<i class="fa-solid fa-xmark text-danger mr-2"  aria- title="clear Signature"></i>
						<span>{{'Clear Pad' | translate}}</span>
					</button>
				</div>		
			</div>
				

		</div>

     

    <p-footer class="d-flex justify-content-end">
        <button class="btn btn-primary mx-1" (click)="submitPunchConfirmation()" [disabled]="!signaturePadConfirm || signaturePadConfirm.isEmpty() || !confirmWeeklyHoursCorrect">
            <span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></span>
        </button>
    </p-footer>
</p-dialog>
<p-dialog id="questionModal" [style]="{'width': '90%', 'max-width':'900px', 'max-height':'85vh'}" [(visible)]="showTrackingDialog" class="modal_in" [modal]="true" (onHide)="closeTrackingDialog()" [dismissableMask]="true">
    <p-header>
        <span><i class="fa-solid fa-location-dot mr-2"></i>{{selectedName}}</span>
    </p-header>
    <div class="ui-g row m-0 d-flex">
        <div class="ui-g confirm ui-g-5 ui-sm-12 p-0">
            <p-table #dt [value]="milestone_groups" [scrollable]="true" [rows]="10" [totalRecords]="100" [paginator]="true" [style]="{'height': '100%'}" selectionMode="single" dataKey="groupId">
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr style="height:30px" [pSelectableRow]="rowData" [pContextMenuRow]="rowData" (click)="toggleMarkers(rowData)">
                        <td style="position: relative;">
                            <p-panel id="table-ppanel" [toggleable]="true" [collapsed]="true">
                                <p-header>
									<span *ngIf="rowData.data.length == 1">
										{{rowData.data[0].labelText}} {{rowData.data[0].` | date:'MM/dd/yyyy hh:mm:ss a'}}
									</span>
                                    <span *ngIf="rowData.data.length > 1">
										{{rowData.data[0].labelText}} {{rowData.data[0].createdAt | date:'MM/dd/yyyy hh:mm:ss a'}} - {{rowData.data[rowData.data.length - 1].labelText}} {{rowData.data[rowData.data.length - 1].createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}
									</span>
                                    <span *ngIf="rowData.inprogress" class="badge badge-primary ml-4">{{'Pending' | translate}}</span>
                                </p-header>
                                <p-table [value]="rowData.data" >
                                    <ng-template pTemplate="body" let-rowData1>
                    <tr [pSelectableRow]="rowData1">
                        <td>
                            {{rowData1.labelText}}
                        </td>
                        <td>
                            {{rowData1.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            </p-panel>
            </td>
            </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!loadingTrackData">
                    <td [attr.colspan]="2" class="text-center py-5">
                        <div class="text-muted my-5" style="
							margin: 184px 0px !important;
						">{{'no data' | translate}}</div>
                    </td>
                </tr>
                <tr *ngIf="loadingTrackData">
                    <td [attr.colspan]="2" class="text-center py-5">
                        <div class="text-muted my-5" style="
							margin: 184px 0px !important;
						"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
                    </td>
                </tr>
            </ng-template>

            </p-table>
        </div>
        <div class="ui-g-7 ui-sm-12 p-0" *ngIf="trackingList">
            <agm-map style="width: 98%; height: 520px;" [latitude]="firstLat" [longitude]="firstLong" [zoom]="options.zoom">
                <agm-marker *ngFor="let marker of milestone_markers" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"></agm-marker>
                <agm-polyline [geodesic]="true" [strokeColor]="'#FF0000'" [strokeOpacity]="1.0" [strokeWeight]="2">
                    <agm-polyline-point *ngFor="let point of routePathMilestone" [latitude]="point.lat" [longitude]="point.lng"></agm-polyline-point>
                    <agm-icon-sequence [fixedRotation]="false" [repeat]= "'100px'" [offset]="100" [path]="'FORWARD_OPEN_ARROW'"></agm-icon-sequence>
                </agm-polyline>
                <agm-marker *ngFor="let point of routePathMilestone" [latitude]="point.lat" [longitude]="point.lng" [iconUrl]="'path/to/arrow-icon.png'"></agm-marker>
            </agm-map>
        </div>
    </div>
    <p-footer class="d-flex justify-content-end">
        <button class="btn btn-primary" (click)="closeTrackingDialog()">
            <span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
        </button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'750px'}" [closable]="true" [id]="'multipleLunches'">
    <p-header>
        <span><i class="fa-solid fa-plus mr-2"></i>{{'Multiple Breaks' | translate}}: {{selectedRowData?.employee.fullName}} [{{punchDate}}]</span>
    </p-header>
    <p-table #table [value]="selectedRowData?.employeeClockingLunchHourHistory" [rows]="(selectedRowData?.employeeClockingLunchHourHistory)?.size" [paginator]="false" [lazy]="false" [responsive]="true" [loading]="loading">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of displayLunchColumns"  [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
					</span>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr *ngIf="loading && (i+1 === 1)">
                <td [attr.colspan]="5"  class="text-center py-5">
                    <span class="text-muted my-5">{{'Loading...' | translate}}</span>
                </td>
            </tr>
            <!-- <tr *ngIf="i+1 === 2" [pSelectableRow]="rowData"> -->
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of displayLunchColumns;">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'id'">
							{{i + 1}}
						</span>
                        <span *ngSwitchCase="'lunchStart'">
							<div class="d-flex align-items-center justify-content-between">
								<span *ngIf="!rowData.editAllowedLunchStart" class="one-liner" pTooltip="{{rowData.isAdminUpdateLunchOut ? ('Change from (' + (rowData.prevLunchOutTime ? (rowData.prevLunchOutTime | date:'hh:mm a') : 'None') + ') by ' + rowData.lastModifiedBy) : ''}}">
									<span class="mobile-table-label">{{'Lunch #2 Start' | translate}}: </span>
									<span  [class]="rowData.isAdminUpdateLunchOut ? 'text-danger' : ''">{{rowData.lunchOutTime | date: 'h:mm a'}}</span>
									<img [src]="rowData?.lunchOutPhoto" *ngIf="rowData.lunchOutPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.employeeClockingId, 'LUNCH_OUT', rowData.lunchOutTime, rowData?.employee?.fullName, rowData?.lunchOutPhoto, rowData?.lunchOutPhotoStatus, rowData?.id)"/>
									<i *ngIf="rowData.lunchOutPhoto && rowData.lunchOutPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
								</span>
							</div>
						</span>
                        <span *ngSwitchCase="'lunchEnd'">
							<div class="d-flex align-items-center justify-content-between">
								<span *ngIf="!rowData.editAllowedLunchEnd" class="one-liner" pTooltip="{{rowData.isAdminUpdateLunchIn ? ('Change from (' + (rowData.prevLunchInTime ? (rowData.prevLunchInTime | date:'hh:mm a') : 'None') + ') by ' + rowData.lastModifiedBy) : ''}}">
									<span class="mobile-table-label">{{'Lunch #2 End' | translate}}: </span>
									<span [class]="rowData.isAdminUpdateLunchIn ? 'text-danger' : ''">{{rowData.lunchInTime != null?  (rowData.lunchInTime| date: 'h:mm a') : 'Missing' | translate}}</span>
									<img [src]="rowData?.lunchInPhoto" *ngIf="rowData.lunchInPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.employeeClockingId, 'LUNCH_IN', rowData.lunchInPhoto, rowData?.employee?.fullName, rowData?.lunchInPhoto, rowData?.lunchInPhotoStatus, rowData?.id)"/>
									<i *ngIf="rowData.lunchInPhoto && rowData.lunchInPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
								</span>
                            </div>
						</span>
                        <span *ngSwitchCase="'lunchTot'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>
								<span>{{calculateTotalhhmmForSecondLunchNew(rowData)}}</span>
							</span>
						</span>
                    </ng-container>
                </td>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading">
                <td [attr.colspan]="5"  class="text-center py-5">
                    <span class="text-muted my-5">{{'no data' | translate}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="p-3 w-100 text-center">
		<span style="font-size: 16px">
			<span style="font-weight: 500">{{'Combined Total' | translate}}: </span>{{calCulateTotalhhmm()}}
		</span>
    </div>
    <p-footer class="d-flex justify-content-end">
        <button class="btn btn-primary" (click)="showDialog = false">
            <span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
        </button>
    </p-footer>
</p-dialog>


<p-dialog header="Confirmation" [modal]="true" [(visible)]="openPopupConfirmLogout" 
    [style]="{'width': '20%', 'max-width':'750px'}" [closable]="true" class="refresh-dialog">
    <div style="text-align:center" class="p-2">
     <p>{{'Are you Sure want to logout' | translate}}</p>
    </div>
    <div class="ui-g-12 text-center">
        <button pButton label="Cancel" (click)="openPopupConfirmLogout=false"></button> &nbsp;
        <button pButton label="Ok" (click)="triggerLogOut()"></button>
    </div>
</p-dialog>
