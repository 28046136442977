<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Invoices' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Invoices' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12" *ngIf="!disableCompany || disableFields">
				<label>{{'companyLabel' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="allCompanyList" [disabled]="disableCompany || disableFields" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="loadAllClient()">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown [options]="allClientList" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" filterMatchMode="startsWith" [(ngModel)]="selectedClient" [showClear]="true"  (onChange)="getAllAddedProductsByClientId($event)" [disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Date Range' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'Start date' | translate}}"
								[(ngModel)]="fromDate" (focus)="picker.open()">
							<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate"
								(focus)="picker.open()">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Invoice' | translate}} #:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-file-invoice-dollar"></i>
						</button>
					</div>
<!--					<input type="text" pInputText placeholder="{{'Invoice' | translate}}" class="w-100" [(ngModel)]="invoiceNumber">-->
					<p-dropdown [options]="allInvoicesDropdownData" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}"
								[(ngModel)]="invoiceNumber" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'PaymentMethod' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-wallet"></i>
						</button>
					</div>
					<p-multiSelect [options]="paymentMethods" defaultLabel="{{'Select Items' | translate}}" id="button-addon2"  [(ngModel)]="selectedPaymentMethods" [filter]="false">	
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
				  	</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Invoice Type' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="invoiceTypes" [(ngModel)]="invoiceType" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
            <div class="ui-g-6 ui-sm-12">
				<label>{{'AssignedTo' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="assigneeList" [(ngModel)]="assigneeSelected" placeholder="{{'pleaseSelect' | translate}}" [disabled]="!isCompanyAdminRole" (onChange)="changeAssignedTo($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
                    </p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-multiSelect 
						defaultLabel="{{'pleaseSelect' | translate}}"
						[disabled]="disableAgency"
						name="statusSearch"
						[options]="statusTypes"
						[(ngModel)]="selectedStatus"
						id="id_invoice_status_search_label" class="w-100">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="resetFilters()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex flex-wrap justify-content-between align-items-center py-4 pl-2">
		<h1 class="m-0">{{'Browse Invoices' | translate}}</h1>
		<div>
			<span style="font-weight: 500;">{{'Total Amount' | translate}}: </span><span>${{totalServiceFee | number : '1.2-2'}}</span><br>
			<span style="font-weight: 500;">{{'Total Paid' | translate}}: </span><span class="text-success">${{totalServiceFeePaid | number : '1.2-2'}}</span><br>
			<span style="font-weight: 500;">{{'Balance' | translate}}: </span><span class="text-danger">${{totalOutstandingBalance | number : '1.2-2'}}</span>
		</div>
	</div>

    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Invoices' | translate}} ({{totalRecords}})</span>
        </p-header>
        <p-contextMenu #cm [model]="items"></p-contextMenu>
        <p-table [hidden]="!tableView" #dt [value]="contentList" dataKey="id" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" (onLazyLoad)="search($event)"
				 [totalRecords]="totalRecords" [contextMenu]="cm" [responsive]="true" [lazy]="true" [loading]="loading">

			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
					<div class="d-flex flex-wrap flex-grow-1" style="row-gap: 10px">
						<button type="button" (click)="op5.toggle($event)" class="btn btn-primary mx-1" id="export-button">
							<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
						</button>
						<button type="button" (click)="resetInvoicePayPOS(); invoicePayPOSDialog = true" class="btn btn-primary mx-1" id="pay-via-pos">
							<span class="text-nowrap"><i class="fa-solid fa-exchange mr-2"></i> {{'Pay Via POS' | translate}}</span>
						</button>
						<div class="input-group flex-grow-1 mx-1" style="max-width: 380px !important">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-table"></i>
								</button>
							</div>
							<p-multiSelect [options]="customizeColumns" defaultLabel="{{'Customize Columns' | translate}}"  [(ngModel)]="customColumnName" [ngModelOptions]="{standalone: true}" [filter]="false" class="w-100" (onChange)="onSelectColumn($event)">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate" style="width: 93% !important">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItemsLabel">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-multiSelect>
						</div>
						<div class="switch-view-style">
							<label class="text-style">{{'View as' | translate}}</label>
							<span class="icon-style" [ngClass]="{'icon-selected': tableView}"><i class="fa-solid fa-table-cells"></i></span>
							<span class="icon-style" [ngClass]="{'icon-selected': !tableView}" (click)="switchView('2')"><i class="fa-solid fa-chart-simple"></i></span>
						</div>
					</div>
					<div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" pInputText [(ngModel)]="searchText" (input)="searchInput()" #searchKeyInput placeholder="{{'Filter' | translate}}">
					</div>
				</div>
			</ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of getShowHeader(); let i = index;">
                        <ng-container [ngSwitch]="col.show">
                            <span *ngSwitchDefault>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col?.label | translate }}" tooltipPosition="left">{{col?.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
                            </span>
                        </ng-container>
                    </th>
					<!-- <th *ngIf="!this.authService.isClientRole()">
						<div class="align-items-center justify-content-between w-100">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</div>
					</th> -->
                </tr>
            </ng-template>
            <ng-template  pTemplate="body" let-rowData let-i="rowIndex">
                <tr *ngIf="!loading" [pSelectableRow]="rowData">
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice' | translate}}: </span> 
                            <span *ngIf="rowData.invoiceNumber" class="text-primary c-pointer" (click)="clickInvoiceRef($event,rowData);" pTooltip="{{rowData.invoiceNumber}}" style="cursor: pointer">{{rowData.invoiceNumber}}</span>
							<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td *ngIf="dataCol.invoiceType">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Type' | translate}}: </span> 
							<span *ngIf="rowData.invoiceNumber" pTooltip="{{rowData?.invoiceType}}">{{rowData?.invoiceType}}</span>
							<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Dated' | translate}}: </span>
							<span *ngIf="rowData.invoiceDate" pTooltip="{{formatDate(rowData.invoiceDate)}}">{{formatDate(rowData.invoiceDate)}}</span>
							<span *ngIf="!rowData.invoiceDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td *ngIf="!isClientRole">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client' | translate}}: </span> 
							<span *ngIf="rowData.clientName" (click)="openClientProfile(rowData)" class="text-primary c-pointer" pTooltip="{{rowData.clientName}}" style="cursor: pointer">{{rowData.clientName}}</span>
							<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
                  
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Due Date' | translate}}: </span>
							<span *ngIf="rowData.dueDate" pTooltip="{{rowData.dueDate | date: 'MM/dd/yyyy'}}">{{rowData.dueDate | date: 'MM/dd/yyyy'}}</span>
							<span *ngIf="!rowData.dueDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td *ngIf="isDisplayCompany">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Name' | translate}}: </span>
							<span *ngIf="rowData.companyName" pTooltip="{{rowData.companyName}}">{{rowData.companyName}}</span>
							<span *ngIf="!rowData.companyName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td *ngIf="dataCol.totalAmount">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Total' | translate}}: </span>
							<span *ngIf="rowData.totalAmount" pTooltip="${{rowData.totalAmount | number : '1.2-2'}}">${{rowData.totalAmount | number : '1.2-2'}}</span>
							<span *ngIf="!rowData.totalAmount" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Fees' | translate}}: </span>
							<span *ngIf="rowData.fee" pTooltip="${{rowData.totalFee | number : '1.2-2'}}">${{rowData.totalFee | number : '1.2-2'}}</span>
							<span *ngIf="!rowData.fee" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Paid' | translate}}: </span>
							<span *ngIf="rowData.paidFee" class="text-success" pTooltip="${{rowData.paidFee | number : '1.2-2'}} ">${{rowData.paidFee | number : '1.2-2'}} <i *ngIf="rowData.discount && rowData.discount > 0" pTooltip="${{rowData.discount | number : '1.2-2'}} Discount" class="fa-solid fa-circle-info text-primary"></i></span>
							<span *ngIf="!rowData.paidFee" class="text-muted">{{'$0.00' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Due In' | translate}}: </span>
							<span *ngIf="rowData.status !== 'Paid'" class="text-danger" pTooltip="{{rowData.dueIn}}">{{rowData.dueIn}}</span>
							<span *ngIf="rowData.status === 'Paid'" class="text-muted">{{'0' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Balance' | translate}}: </span> 
							<span *ngIf="rowData.balance > 0" class="text-danger">${{rowData.balance | number : '1.2-2'}}</span>
							<span *ngIf="rowData.balance == 0" class="text-success cursor-pointer" (click)="openTransactionDetails(rowData);"><i class="fa-solid fa-circle-check"></i> {{'Paid' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Quick Pay' | translate}}: </span> 
							<ng-container *ngIf="rowData.pendingTransactionId && rowData.status === 'Pending'">
								<span *ngIf="isClientRole" class="text-muted">{{'Pending' | translate}}</span>
								<a *ngIf="isCompanyAdminRole" class="text-primary" (click)="openConfirnPendingTransaction(rowData)" href="javascript:void(0);">{{'Pending' | translate}}</a>
							</ng-container>
							<ng-container *ngIf="!(rowData.pendingTransactionId && rowData.status === 'Pending')">
								<span *ngIf="rowData.status === 'Paid'" class="text-success">{{'Paid' | translate}}</span>
								<span *ngIf="rowData.status === 'Pending'" class="text-dark">{{'Pending' | translate}}</span>
								<a *ngIf="rowData.status === 'Unpaid' || rowData.status === 'Partial Paid'" class="badge badge-danger" (click)="openPaynow(rowData)" href="javascript:void(0);">{{'Pay Now' | translate}}<i class="fa-solid fa-arrow-right ml-1"></i></a>
							</ng-container>
							<ng-container *ngIf="rowData.paymentMethod === 'Other' && rowData.status !== 'Pending'">
								<span class="text-muted">{{'n/a' | translate}}</span>
							</ng-container>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Method' | translate}}: </span>
							<span *ngIf="rowData.paymentMethod" pTooltip="{{rowData.paymentMethod | translate}}">{{rowData.paymentMethod | translate}}</span>
							<span *ngIf="!rowData.paymentMethod" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td *ngIf="!isClientRole && dataCol.emailReminder">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Reminder' | translate}}: </span> 
							<span *ngIf="rowData.balance != 0 && !(rowData.pendingTransactionId && rowData.status === 'Pending')" (click)="openReminderPopup(rowData)" class="badge badge-primary" style="cursor: pointer">{{'Send' | translate}}<i class="fa-solid fa-paper-plane ml-1"></i></span>
							<span *ngIf="rowData.balance == 0 || rowData.balance == null || !rowData.balance || (rowData.balance && rowData.paymentMethod === 'Other')" class="badge badge-muted">{{'Send' | translate}}</span>
						</span>
                    </td>
                    <td *ngIf="dataCol.reminderCount">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Alert Count' | translate}}: </span>
							<span *ngIf="rowData.reminderCount" pTooltip="{{rowData.reminderCount}}">{{rowData.reminderCount | number : '1.0-0'}}</span>
							<span *ngIf="!rowData.reminderCount" class="text-muted">{{'none' | translate}}</span>
						</span>
					</td>
                    <td *ngIf="!this.authService.isClientRole()">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Actions' | translate}}: </span> 	
							<i class="fa-solid fa-plus text-primary c-pointer mx-1" (click)="openDialog(rowData, $event, true)" pTooltip="{{'Add Collections Note' | translate}}" tooltipPosition="left"></i>       
							<span class="mx-1">
								<i *ngIf="rowData.followUpNoteDate" class="fa-solid fa-note-sticky text-warning c-pointer" (click)="openDialog(rowData, $event, false)" pTooltip="{{'Follow-Ups' | translate}} {{rowData.followUpNoteDate | date: 'MM/dd/yyyy'}}"  tooltipPosition="left">		
									<span *ngIf="rowData.numOfFollowUpNote > 0" class="translate-middle badge rounded-pill bg-danger" style="font-size: 7px">
										{{rowData.numOfFollowUpNote}}
									</span>
								</i>
								<i *ngIf="rowData.followUpNoteDate" class="fa-solid fa-inbox text-purple c-pointer mx-1" (click)="redirectToFollowUptUrl(rowData)" pTooltip="{{'View Follow-Ups' | translate}}" tooltipPosition="left"></i>
							</span>							
						</span>
                    </td>
                </tr>
            </ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="getShowHeader()?.length + 1" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="getShowHeader()?.length + 1" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
            <ng-template *ngIf="!loading" pTemplate="footer" let-columns>
                <tr *ngIf="contentList.length > 0">
                    <td align="right" colspan="4">{{'Total' | translate}}:</td>
                    <td *ngIf="isPlatformAdmin"></td>
                    <td>{{sumTotalAmountTxt}}</td>
                    <td>{{sumFeeTxt}}</td>
                    <td>{{sumPaidFeeTxt}}</td>
                    <td>{{sumOutStandingBalanceTxt}}</td>
                </tr>
            </ng-template>
        </p-table>
		<div [hidden]="tableView">
			<div style="width: 100%; padding: 10px">
				<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
					<div class="d-flex flex-wrap flex-grow-1" style="row-gap: 10px">
						<div [hidden]="xViewSelected === '5'" class="input-group w-100" style="max-width: 380px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" type="button" style="cursor:default !important">
									<i class="fa-solid fa-folder-tree"></i>
								</button>
							</div>
							<p-dropdown [options]="timeOptions" [(ngModel)]="timeOptionSelected" placeholder="{{'pleaseSelect' | translate}}"
										[autoDisplayFirst]="false" (onChange)="timeOptionChange()">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="switch-view-style">
							<label class="text-style">{{'View as' | translate}}</label>
							<span class="icon-style" [ngClass]="{'icon-selected': tableView}" (click)="switchView('1')"><i class="fa-solid fa-table-cells"></i></span>
							<span class="icon-style" [ngClass]="{'icon-selected': !tableView}"><i class="fa-solid fa-chart-simple"></i></span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12" style="display: inline-flex; min-height: 400px;">
				<div class="col-8" style="overflow:auto">
					<canvas [hidden]="chartType !== '1'" id="paymentInvoiceBarChart"></canvas>
					<canvas [hidden]="chartType !== '2'" id="paymentInvoiceLineChart"></canvas>
					<div [hidden]="!loadingDataChart" class="ui-g-12 no-data-found overlay-spinner">
						<div class="spinner-custom">
							<p-progressSpinner class="center-spin"></p-progressSpinner>
							<div class="font-weight-bold">{{'Loading...' | translate}}</div>
						</div>
					</div>
				</div>
				<div class="col-4">
					<div class="col-sm-12">
						<label class="field-text">{{'Chart Type' | translate}}</label>
						<div class="switch-view-style">
							<div class="grid-chart-text" (click)="switchChartType('1')">
								<span class="chart-type" [ngClass]="{'chart-type-selected': chartType === '1'}"><i class="fa-solid fa-chart-simple"></i></span>
								<span class="grid-text">{{'Vertical Bar' | translate}}</span>
							</div>
							<div class="grid-chart-text" (click)="switchChartType('2')">
								<span class="chart-type" [ngClass]="{'chart-type-selected': chartType === '2'}"><i class="fa-solid fa-chart-line"></i></span>
								<span class="grid-text">{{'Trend Line' | translate}}</span>
							</div>
						</div>
					</div>
					<div class="col-sm-12">
						<label class="field-text">{{'Fields' | translate}}</label>
						<label style="padding: 20px 0 0 0">{{'Horizontal (X) axis' | translate}}</label>
						<div class="input-group w-100" style="max-width: 380px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" type="button" style="cursor:default !important">
									<i class="fa-solid fa-folder-tree"></i>
								</button>
							</div>
							<p-dropdown [options]="xOptions" [(ngModel)]="xViewSelected" placeholder="{{'pleaseSelect' | translate}}"
										[autoDisplayFirst]="false" (onChange)="chartDataChange()">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
						<label style="padding: 20px 0 0 0">{{'Vertical (Y) axis' | translate}}</label>
						<div class="input-group w-100" style="max-width: 380px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" type="button" style="cursor:default !important">
									<i class="fa-solid fa-folder-tree"></i>
								</button>
							</div>
							<p-dropdown [options]="yOptions" [(ngModel)]="yViewSelected" placeholder="{{'pleaseSelect' | translate}}"
										[autoDisplayFirst]="false" (onChange)="chartDataChange()">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
        <p-dialog header="Invoice Details" styleClass="w-50" [modal]="true" [responsive]="true" [autoZIndex]="true" [(visible)]="detailEnable" [contentStyle]="{'max-height':'400px'}">
            <div class="ui-g invoice-details">
                <div id="contentToConvert" style="padding:50px;">
                    <div style="text-align: center;">
                        <img src="../../../../assets/punch-images/operr_logo.jpg">
                    </div>
                    <div class="ui-g-12">
                        <p style="text-align: center;">
                            <b>{{'INVOICE' | translate}}</b>
                        </p>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-6">
                            {{'Invoice Number' | translate}}: {{invoiceDetail.invoiceNumber}}
                        </div>
                        <div style="text-align: right">
                            {{'Date' | translate}}: {{invoiceDetail.invoiceDate | date: 'dd/MM/yyyy'}}
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-8 pb-0 ui-g-offset-2">
                            <p-table [value]="details">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th><b>{{'Charge Item' | translate}}</b></th>
                                        <th><b>{{'Amount' | translate}}</b></th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-detail>
                                    <tr>
                                        <td>{{detail?.name}}</td>
                                        <td>
                                            <div style="text-align: right">
                                                {{detail?.value?.toFixed(2)}}
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" let-columns>
                                    <tr>
                                        <td>
                                            <b>{{'Total' | translate}}:</b>
                                        </td>
                                        <td>
                                            <div style="text-align: right">
                                                ${{invoiceDetail?.totalAmount?.toFixed(2)}}
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <b>{{'Please make to payment' | translate}}</b>
                    </div>
                </div>
            </div>
            <div class="ui-g-12" style="text-align: center;">
                <button pButton type="button" label="{{'Ok' | translate}}" (click)="cancelDialog()"></button>
            </div>
        </p-dialog>
    </p-panel>
    <p-overlayPanel #op1 appendTo="body">
        <p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption"
            (onChange)="sortDataSelected(selectedSortOption)">
            <ng-template let-col pTemplate="item">
                <div>
                    <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                    <span>{{col.name | translate}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>
    <p-overlayPanel #op2 appendTo="body">
        <p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption"
            (onChange)="sortDataSelected(selectedSortOption)">
            <ng-template let-col pTemplate="item">
                <div>
                    <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                    <span>{{col.name | translate}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>

    <p-dialog header="{{'Confirmation' | translate}}" [style]="{width: '500px'}" [(visible)]="reminderModal"
        class="modal_in" [modal]='true'>
        <div class="ui-g p-2" *ngIf="!disableButton">
					<div class="d-flex justify-content-between w-100 px-3">
						
						<div class="d-flex">
							<p-radioButton name="ReminderGroup" value="email" [(ngModel)]="reminderSelected" inputId="reminderEmail"></p-radioButton>
							<label for="reminderEmail">Email</label>
						</div>

						<div class="d-flex">
							<p-radioButton name="ReminderGroup" value="sms" [(ngModel)]="reminderSelected" inputId="reminderSms"></p-radioButton>
							<label for="reminderSms">SMS</label>
						</div>

						<div class="d-flex">
							<p-radioButton name="ReminderGroup" value="smsAndEmail" [(ngModel)]="reminderSelected" inputId="reminderSmsAndEmail"></p-radioButton>
							<label for="reminderSmsAndEmail">Email & SMS</label>
						</div>
					</div>
					<div *ngIf="reminderSelected === 'email'">
						{{'Reminder email will be send to' | translate}}&nbsp;<span *ngFor="let email of selectedEmail">{{email}}, </span>
						{{'are you sure you want to send' | translate}}
					</div>
					<div *ngIf="reminderSelected === 'sms'">
						{{'Reminder SMS will be send to' | translate}}&nbsp;<span *ngFor="let phone of selectedPhone">{{phone}}, </span>
						{{'are you sure you want to send?' | translate}}
					</div>
					<div *ngIf="reminderSelected === 'smsAndEmail'">
						{{'Reminder will be send to' | translate}}&nbsp;
						<ng-container *ngIf="selectedEmail.length"><span *ngFor="let email of selectedEmail">{{email}}, </span></ng-container> 
						<ng-container *ngIf="selectedPhone.length"><span *ngFor="let phone of selectedPhone">{{phone}}, </span></ng-container> 
						{{'are you sure you want to send?' | translate}}
					</div>

        </div>
        <div class="ui-g p-3" *ngIf="disableButton">
					{{'Sending' | translate}}
        </div>
        <p-footer>
            <div class="ui-g">
                <div class="ui-g-12" align="right">
                    <button type="button" pButton label="{{'yes' | translate}}" (click)="accept()"
                        [disabled]="disableButton"></button>
                    <button type="button" pButton label="{{'no' | translate}}" (click)="reject()"></button>
                </div>
            </div>
        </p-footer>
    </p-dialog>
    <!--Collection Note-->
    <p-dialog [(visible)]="isOpenCollectionNote" [responsive]="true" baseZIndex="99999" [modal]="true" [dismissableMask]="true" [style]="{'width':'100%', 'max-width':'900px'}">
		<p-header>
			<span><i class="fa-solid fa-circle-plus mr-2"></i>{{headerNote}}: {{followUpDate | date: 'MM/dd/yyyy hh:mm'}}</span>
		</p-header>
        <div class="ui-g">
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Client' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.clientName" pInputText [(ngModel)]="paymentInvoiceData.clientName" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.clientName" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice #' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.invoiceNumber" pInputText [(ngModel)]="paymentInvoiceData.invoiceNumber" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.invoiceNumber" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice Dated' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.invoiceDate"  pInputText type="text" [value]="paymentInvoiceData.invoiceDate | date: 'MM/dd/yyyy HH:mm'" disabled>
					<div *ngIf="!paymentInvoiceData.invoiceDate" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Due Date' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<p-calendar style="flex-grow: 1 !important" [inputStyle]="{'width':'100%'}" [showIcon]="false" placeholder="Date" [(ngModel)]="followUpInvoiceDueDate"
								[disabled]="collectionTypeSelected !== 'Delay Payment Requested' || followUpCount > 2 || disabledExtendDueDate || isOverTime || !isOwnerNote"></p-calendar>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Balance' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="paymentInvoiceData.balanceTxt > 0" class="fa-solid fa-dollar-sign"></i>
							<i *ngIf="paymentInvoiceData.balanceTxt <= 0" class="fa-solid fa-circle-check text-success"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.balanceTxt" pInputText [(ngModel)]="paymentInvoiceData.balanceTxt" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.balanceTxt" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.status" pInputText [(ngModel)]="paymentInvoiceData.status" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.status" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>

			<div class="ui-g-6 ui-sm-12">
                <label>{{'Collection Agent' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-tie"></i>
						</button>
					</div>
					<p-dropdown class="w-100" [options]="assignedToEmployeeList" [filter]="true" [(ngModel)]="selectedCollectionPresentative"
								[showClear]="true" style="flex-grow: 1 !important" placeholder="{{'pleaseSelect' | translate}}" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
				</div>
            </div>

			<div class="ui-g-6 ui-sm-12">
                <label>{{'Collection Type' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="collectionTypeOptions" [(ngModel)]="collectionTypeSelected" style="flex-grow: 1 !important"  placeholder="Choose method" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
				</div>
            </div>

			<div class="ui-g-6 ui-sm-12">
                <label>{{'Contact Method' | translate}}:<span class="text-danger">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-message"></i>
						</button>
					</div>
					<p-dropdown [options]="contacts" [(ngModel)]="selectedContact" style="flex-grow: 1 !important" placeholder="Choose method" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
				</div>
            </div>

			<div class="ui-g-6 ui-sm-12">
                <label>{{'Count' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="followUpCount" disabled/>
				</div>
            </div>
		
		<!-- FIELDS NOT YET FUNCTIONAL 
		
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Amount Due' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Contact Phone' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-phone"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Support' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-tie"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Assignee' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
-->
			
			<div class="ui-g-12 ui-sm-12">
                <label>{{'Note' | translate}}:<span class="text-danger">*</span> </label>
				<p-editor #notesEditor
					(onTextChange)="textChanged1($event)"
					(onInit)="setEditor($event)"
					(closed)="boldMention()"
					[mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'value'}"
					[mention]="assignedToEmployeeList"
					(opened)="customerPopup($event)"
					[(ngModel)]="followUpNote"
					[style]="{'height':'170px'}"
					[readonly]="isOverTime || !isOwnerNote">
					<p-header>
						<span class="ql-formats">
							<button class="ql-bold" aria-label="Bold"></button>
							<button class="ql-italic" aria-label="Italic"></button>
							<button class="ql-underline" aria-label="Underline"></button>
							<button class="ql-strike" aria-label="Strike"></button>
						</span>
						<span class="ql-formats">
							<select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
								<option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
								<option value="rgb(104 104 104)" label="rgb(104 104 104)"></option>
								<option value="rgb(222 58 58)" label="rgb(222 58 58)"></option>
								<option value="rgb(255 99 0)" label="rgb(255 99 0)"></option>
								<option value="rgb(90 171 29)" label="rgb(90 171 29)"></option>
								<option value="rgb(0 123 255)" label="rgb(0 123 255)"></option>
							</select>
							<span class="ql-format-separator"></span>
						</span>
						<span class="ql-formats">
							<button class="ql-list" title="List"></button>
							<button class="ql-bullet" title="Bullet"></button>         
						</span> 
					</p-header>
				</p-editor>
				<span *ngIf="500 - charactersLength > 0" class="text-muted pull-right" style="font-size: 12px">{{500 - charactersLength}} {{'characters left' | translate}}</span>
				<span *ngIf="500 - charactersLength <= 0" class="text-danger pull-right" style="font-size: 12px">{{500 - charactersLength}} {{'characters left' | translate}}</span>
            </div>
        </div>
		<p-footer class="d-flex align-items-center justify-content-between">
			<button class="btn btn-danger" (click)="closeDialog()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button class="btn btn-primary" [disabled]="followUpNote === '' || selectedCollectionPresentative === '' || selectedContact === '' || !followUpInvoiceDueDate || isOverTime || !isOwnerNote || isSaveCollectionNote"
					(click)="saveCollectionNote()"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</p-footer>
    </p-dialog>

    <p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showConfirmDialog" class="modal_in"
        [modal]='true'>
        <div class="ui-g confirm">
            {{message}}
        </div>
        <p-footer>
            <div class="ui-g">
                <div class="ui-g-12" align="right">
                    <button type="button" pButton label="{{'yes' | translate}}" (click)="deleteFollowUpNote()"></button>
                    <button type="button" pButton label="{{'no' | translate}}"
                        (click)="closeConfirmDeleteDialog()"></button>
                </div>
            </div>
        </p-footer>
    </p-dialog>

    <p-overlayPanel #op3 appendTo="body">
        <p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
            <ng-template let-col pTemplate="item">
                <div>
                    <span>{{col.name}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>

    <p-overlayPanel [appendTo]="'body'" #op5>
        <p-listbox [options]="downloadOptions" [(ngModel)]="selectedDownload" (click)="clickDownladFile()">
            <ng-template let-col pTemplate="item">
                <div>
                    <span>{{col.name | translate}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>

<p-dialog header="Outstanding Balance Payment" [showHeader]="true" showEffect="fade" styleClass="w-50" [style]="{'height': '75%', 'max-height':'800px'}"
    [closable]="true" *ngIf="showPendingTransactionConfirmDialog" [(visible)]="showPendingTransactionConfirmDialog" modal="modal"
    [dismissableMask]="true">
    <div>
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Payment Method' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="text" pInputText [(ngModel)]="pendingTransactionConfirm.paymentMethod" placeholder="{{'Payment Method' | translate}}" disabled />
            </div>
        </div>
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Invoice Number' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="text" pInputText [(ngModel)]="pendingTransactionConfirm.invoiceNumber" placeholder="{{'Invoice Number' | translate}}" disabled />
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Amount' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="text" pInputText [(ngModel)]="pendingTransactionConfirm.amount" placeholder="{{'Amount' | translate}}" disabled />
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Payment Date' | translate}}<span class="required-indicator">*</span>:</label>
                <input type="text" pInputText [value]="pendingTransactionConfirm.paymentDate | date:'MM/dd/yyyy'" placeholder="{{'Payment Date' | translate}}" disabled />
            </div>
        </div>

        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <label>{{'Note' | translate}}<span class="required-indicator" *ngIf="pendingTransactionConfirm.paymentMethod === 'other'">*</span>:</label>
                <textarea pInputTextarea style="width:100%" [rows]="7" class="acceptance-inputs"
                            autoResize="true" maxlength="250"
                            placeholder="{{'Maximum length of notes is 250' | translate}}"
                            [(ngModel)]="pendingTransactionConfirm.note" disabled>
                </textarea>
            </div>
        </div>
        <div class="row mx-0 mb-2 align-items-center">
            <div class="ui-g-12">
                <img *ngIf="pendingTransactionConfirm.documentUrl" src="{{pendingTransactionConfirm.documentUrl}}" (click)="previewImage = pendingTransactionConfirm.documentUrl; visibleImage = true"
                    style="display: block; margin-left: auto; margin-right: auto; width: 25%;"/>
            </div>
        </div>

    </div>

    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12 p-1" align="center">
                <button type="button" pButton label="Confirm" (click)="openConfirmationQuestion('Approved')"></button>
                <button type="button" pButton label="Reject" (click)="openConfirmationQuestion('Rejected')" class="p-button-warning"></button>
                <button type="button" pButton label="Cancel" (click)="this.pendingTransactionConfirm = null; this.showPendingTransactionConfirmDialog = false" class="p-button-secondary"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showPendingTransactionConfirmQuestionDialog" class="modal_in"
    [modal]='true' (onHide)="showPendingTransactionConfirmQuestionDialog = false">
    <div class="ui-g">
		{{'Are you sure you want to perform this action' | translate}}
    </div>
	<p-progressBar mode="indeterminate" [style]="{'height': '4px'}" *ngIf="isLoading"></p-progressBar>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'yes' | translate}}" (click)="acceptConfirmation()" [disabled]="isLoading"></button>
                <button type="button" pButton label="{{'no' | translate}}" (click)="this.showPendingTransactionConfirmQuestionDialog = false; showPendingTransactionConfirmDialog = true"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog class="show-image" [showHeader]="false" [(visible)]="visibleImage" [modal]="true" [dismissableMask]="true" [resizable]="true" [style]="{width: '40vw'}">
    <div><img [src]="previewImage" style="width: 100%;"/></div>
    <div class="close" (click)="visibleImage=false">X</div>
</p-dialog>

<p-dialog header="{{'Reason' | translate}}" [(visible)]="showPendingTransactionRejectConfirmDialog" class="modal_in" [style]="{width: '30vw'}"
    [modal]='true' (onHide)="showPendingTransactionRejectConfirmDialog = false" *ngIf="showPendingTransactionRejectConfirmDialog">
    <div class="ui-g">
			<div class="ui-g-12">
				<label>{{'Reason' | translate}}<span class="required-indicator">*</span>:</label>
				<textarea pInputTextarea style="width:100%" [rows]="5" class="acceptance-inputs"
										autoResize="true" maxlength="500" minlength="25"
										placeholder="{{'Maximum length of notes is 500' | translate}}"
										[(ngModel)]="pendingTransactionConfirm.reason">
				</textarea>
		</div>
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'yes' | translate}}" (click)="acceptConfirmation()" [disabled]="!pendingTransactionConfirm.reason || pendingTransactionConfirm.reason.length < 25"></button>
                <button type="button" pButton label="{{'no' | translate}}" (click)="this.showPendingTransactionRejectConfirmDialog = false; showPendingTransactionConfirmDialog = true"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>
	<p-dialog [showHeader]="false" [(visible)]="exporting" styleClass="w-50" id="id_progress_bar_dialog" [closeOnEscape]="false" [modal]="true">
		<div class="card card-w-title p-0 border-0 m-0">
			<div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
				<div class="ui-g-12 ui-md-12">
					<p style="margin: 0 0 0 50%;">{{progressDownloadAll}}%</p>
					<p-progressBar [value]="progressDownloadAll" [showValue]="true"></p-progressBar>
					<p style="margin: 10px 0 0 0;">{{'Estimate time' | translate}}:&nbsp;{{estDownloadAll}}</p>
					<p>{{'Duration time' | translate}}:&nbsp;{{durDownloadAllText}}</p>
				</div>
				<div class="ui-g-12 ui-md-12 text-center pb-3">
					<button pButton class="w-auto" label="Close" (click)="cancelExporting()"></button>
				</div>
			</div>
		</div>
	</p-dialog>

	<p-dialog header="{{'Pay Via POS' | translate}}" [(visible)]="invoicePayPOSDialog" class="modal_in"
		[style]="{width: '40vw'}" [modal]='true' (onHide)="resetInvoicePayPOS(); invoicePayPOSDialog = false" *ngIf="invoicePayPOSDialog">
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown [options]="allClientList" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" filterMatchMode="startsWith" [(ngModel)]="invoicePayPOS.clientId" [showClear]="true"  (onChange)="onPayPosClientChange()" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Invoice'| translate}}#:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-file-invoice-dollar"></i>
						</button>
					</div>
					<p-multiSelect [style]="{width: '100%'}" [options]="invoiceOptions" [(ngModel)]="invoicePayPOS.invoices" optionLabel="label"
						(onChange)="totalInvoice()" id="id_invoices_to_selected_label">
							<ng-template pTemplate="item" let-item>
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Fee' | translate}} <i class="fa-solid fa-circle-question c-pointer text-danger ml-2"
						(click)="showDialogInfoFee = true"></i></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<div class="form-control"><span class="one-liner">{{invoicePayPOS.totalFee | number : '1.2-2'}}</span></div>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Amount' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<div class="form-control"><span class="one-liner">{{invoicePayPOS.totalAmount | number : '1.2-2'}}</span></div>
				</div>
			</div>

			
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Late Fee' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<div class="form-control"><span class="one-liner">{{invoicePayPOS.totalLateFee | number : '1.2-2'}}</span></div>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Total' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<div class="form-control"><span class="one-liner">{{invoicePayPOS.totalChargeAmount | number : '1.2-2'}}</span></div>
				</div>
			</div>
		</div>
		<p-footer>
			<div class="ui-g">
				<div class="ui-g-12">
						<button type="button" class="mx-1" pButton label="{{'Submit' | translate}}" (click)="acceptInvoicePayPOS()" [disabled]="!invoicePayPOS.totalChargeAmount || invoicePayPOS.submitting"></button>
						<button type="button" class="mx-1" pButton label="{{'Close' | translate}}" (click)="resetInvoicePayPOS(); invoicePayPOSDialog = false"></button>
				</div>
			</div>
		</p-footer>
	</p-dialog>

	<p-dialog header="" [style]="{'width':'50vw'}" [(visible)]="showDialogInfoFee" modal='modal' [modal]='true'>
		<div>
			<p>
				{{'the_additional' | translate}}
			</p>
		</div>
		<p-footer>
			<div class="ui-g-12" align="right">
				<button type="button" pButton label="{{'Ok' | translate}}" (click)="showDialogInfoFee = false"></button>
			</div>
		</p-footer>
	</p-dialog><p-dialog [(visible)]="cardReaderPaymentDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'500px'}" [closeOnEscape]="false" [closable]="false">
		<p-header>
			<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>Process Payment</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-12 text-center">
							<div *ngIf="!cardPaymentStatus || cardPaymentStatus === 'connecting'">
									<div>
											<img width="100%" src="assets/images/card-reader-icon-payment.png" class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}" alt="">
									</div>
									<h3 class="pt-2">Connecting to terminal...</h3>
									<div>This might take a while</div>
							</div>
							<div *ngIf="cardPaymentStatus === 'ready' || cardPaymentStatus === 'waiting_for_input' || cardPaymentStatus === 'processing' || cardPaymentStatus === 'capturing'">
									<div>
											<img width="100%" src="assets/images/card-reader-icon-payment.png" class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}" alt="">
									</div>
									<h3 class="pt-2">Awaiting Payment</h3>
							</div>
							<div *ngIf="cardPaymentStatus === 'cancelling'">
									<div>
											<img width="100%" src="assets/images/card-reader-icon-payment.png" class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}" alt="">
									</div>
									<h3 class="pt-2">Cancelling Payment</h3>
							</div>
							<div *ngIf="cardPaymentStatus === 'not_ready' || cardPaymentStatus === 'not_connected' || cardPaymentStatus === 'no_available_readers' ">
									<div class="pt-5">
											<i class="fa fa-info-circle" aria-hidden="true" style="color: #5bc0de; font-size: 100px;"></i>
									</div>
									<h3 class="pt-2 pb-3">Card Reader is not available.</h3>
							</div>
							<div *ngIf="cardPaymentStatus === 'invalid_amount'">
									<div class="pt-5">
											<i class="fa fa-exclamation-circle" aria-hidden="true" style="color: red; font-size: 100px;"></i>
									</div>
									<h3 class="pt-2">Charge amount must be greater than or equal to 1.</h3>
							</div>
							<div *ngIf="cardPaymentStatus === 'failed'">
									<div class="pt-5">
											<i class="fa fa-exclamation-circle" aria-hidden="true" style="color: red; font-size: 100px;"></i>
									</div>
									<h3 style="color: red;" class="pt-2" *ngIf="posFailedMessage">{{posFailedMessage}}</h3>
									<h4 class="pt-1">We are sory, there was an error processing your payment.</h4>
									<h4 class="pb-3">Please try again with a different payment method</h4>
							</div>
							<div *ngIf="cardPaymentStatus === 'cancelled'">
									<div class="pt-5">
											<i class="fa fa-exclamation-circle" aria-hidden="true" style="color: gray; font-size: 100px;"></i>
									</div>
									<h3 class="pt-4 pb-3">Cancelled</h3>
							</div>
							<div *ngIf="cardPaymentStatus === 'success'">
									<div class="pt-5">
											<i class="fa fa-check-circle" aria-hidden="true" style="color: green; font-size: 100px;"></i>
									</div>
									<div class="ui-g p-4" style="color: #8898aa; text-align: left;">
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Terminal ID:</span> {{terminalId}}</div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Invoice #:</span> {{invoicePayPOS.invoiceLabel}}</div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Description:</span> {{invoicePayPOS.description}}</div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Amount:</span> {{invoicePayPOS.totalAmount | number : '1.2-2'}}</div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Fee:</span> {{(invoicePayPOS.totalChargeAmount - invoicePayPOS.totalAmount) | number : '1.2-2'}}</div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Payment Method:</span> {{invoicePayPOS.paymentMethod}}</div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Transaction Type:</span> </div>
										<div class="ui-g-12 pl-5"><span style="text-transform: uppercase;">Transaction ID:</span> {{invoicePayPOS.chargeId}}</div>
									</div>
							</div>
			</div>
		</div>
			<p-footer class="d-flex justify-content-center align-items-center">
				<button *ngIf="!cardPaymentStatus || cardPaymentStatus === 'connecting' || cardPaymentStatus === 'ready' || cardPaymentStatus === 'waiting_for_input' || cardPaymentStatus === 'processing' || cardPaymentStatus === 'capturing'" type="button" class="btn btn-danger mx-1" (click)="cancelCardReaderPayment()" [disabled]="cardPaymentStatus === 'capturing'"><i class="fa-solid fa-check mr-2"></i>Cancel Payment</button>
				<button *ngIf="cardPaymentStatus === 'no_available_readers' || cardPaymentStatus === 'invalid_amount' || cardPaymentStatus === 'cancelled' || cardPaymentStatus === 'failed' || cardPaymentStatus === 'success' || cardPaymentStatus === 'not_ready' || cardPaymentStatus === 'not_connected'" type="button" class="btn btn-primary mx-1" (click)="closeCardReaderPaymentDialog()"><i class="fa-solid fa-check mr-2"></i>Close</button>
				<button *ngIf="cardPaymentStatus === 'failed'" type="button" class="btn btn-secondary mx-1" (click)="retryCardReaderPayment()"><i class="fa-solid fa-check mr-2"></i>Retry</button>
		</p-footer>
	</p-dialog>

	<p-dialog [(visible)]="showTransactionDetails" styleClass="w-50" id="id_transaction_details" [closeOnEscape]="false"
			  [modal]="true" [style]="{'width': '90%', 'max-width':'1200px', 'max-height':'80vh'}">
		<p-header>
			<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Transaction Details' | translate}} <span *ngIf="(dataSource$|async)?.content">({{(dataSource$|async)?.content.length}})</span></span>
		</p-header>
		<p-table id="main-table" [value]="transactions" dataKey="id" selectionMode="single" [lazy]="false" [rows]="10" [paginator]="true"
				 [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalTransactions" [columns]="transactionCols" [responsive]="true" (sortFunction)="customSort($event)" [customSort]="true">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns">
						<ng-container [ngSwitch]="col.field" [style.width]="col?.width">
                        <span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="!col.sort">
									<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field"></p-sortIcon>
								</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
                        </span>
							<span *ngSwitchCase="'date'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
                        </span>
							<span *ngSwitchCase="'document'" class="text-center justify-content-center">
							<i class="fa-solid fa-paperclip text-muted m-auto" pTooltip="{{'Attachements' | translate}}" tooltipPosition="left"></i>
						</span>
							<span *ngSwitchCase="'actions'">
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr *ngIf="loading">
					<td [attr.colspan]="9" class="text-center py-5">
						<span class="text-muted">{{'Loading...' | translate}}</span>
					</td>
				</tr>
				<tr *ngIf="!loading" [pSelectableRow]="rowData">
					<td [style.width.%]="12">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span>
						<span *ngIf="rowData.date" pTooltip="{{rowData.date | date : 'M/d/yyyy h:mm:ss a'}}">{{rowData.date | date : 'M/d/yyyy h:mm:ss a'}}</span>
						<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Method' | translate}}: </span>
						<span *ngIf="rowData.transactionTypeName" pTooltip="{{rowData?.transactionTypeName}}">{{rowData?.transactionTypeName | translate}}</span>
						<span *ngIf="!rowData.transactionTypeName" class="text-muted">{{'no data' | translate}}</span>
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash In' | translate}}: </span>
						<span *ngIf="rowData.cashIn" pTooltip="{{rowData.cashIn | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.cashIn | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.cashIn" class="text-muted">$0</span>
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Net Amt' | translate}}: </span>
						<span *ngIf="rowData.netCashInOut" pTooltip="{{rowData.netCashInOut | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.netCashInOut | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.netCashInOut" class="text-muted">$0</span>
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>
						<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
						<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.nameClient" pTooltip="{{rowData.nameClient}}">{{rowData.nameClient}}</span>
						<span *ngIf="!rowData.nameClient" class="text-muted">{{'no data' | translate}}</span>
					</span>
					</td>
					<td id="attachment">
					<span class="d-flex" style="max-height: unset; justify-content: center !important;">
						<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
						<p-dropdown *ngIf="!rowData.fileUrl" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment" [options]="this.noFileOptions" id="attach"
									[style]="{'background': 'transparent', 'border' : '0px', 'width' : '20px', 'min-width' : 'unset', 'height' : '20px'}" (onChange)="onChangeUploadOption($event, rowData)" optionLabel="label" [autoWidth]="false" appendTo="body" [editable]="true" styleClass="custom-input"  pTooltip="{{'Attach File' | translate}}">
						</p-dropdown>
						<p-dropdown *ngIf="rowData.fileUrl" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment1" [options]="this.hasFileOptions" id="attach_1"
									[style]="{'background': 'transparent', 'border' : '0px', 'width' : '20px', 'min-width' : 'unset', 'height' : '20px'}" optionLabel="label" placeholder="" (onChange)="onChangeUploadOption($event, rowData)"
									[autoWidth]="false" appendTo="body" [editable]="true"  styleClass="custom-input green-icon" class="text-success" pTooltip="{{'Edit Attached' | translate}}">
						</p-dropdown>
						<input type="file" id="fileUrl" accept=".jpg, .jpeg, .png, .pdf, .doc, .xls, .xlsx, .webp" style="display: none;" (change)="onSelectDocument($event)">
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'1st Verification' | translate}}: </span>
						<span *ngIf="rowData.verification1st" pTooltip="{{getAdminName(rowData.verification1st)}}">{{rowData.verification1st}}</span>
						<span *ngIf="!rowData.verification1st" class="c-pointer text-primary" (click)="openConfirmationPopup('1', rowData.id)"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Confirm' | translate}}</span>
					</span>
					</td>
					<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'2nd Verification' | translate}}: </span>
						<span *ngIf="rowData.verification2st" pTooltip="{{getAdminName(rowData.verification2st)}}">{{rowData.verification2st}}</span>
						<span *ngIf="!rowData.verification2st" class="c-pointer text-primary" (click)="openConfirmationPopup('2', rowData.id)"><i class="fa-solid fa-check-double text-primary mr-2"></i>{{'Confirm' | translate}}</span>
					</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="transactionLoading">
					<td [attr.colspan]="columns?.length" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
		<p-footer class="d-flex justify-content-end">
			<button class="btn btn-primary" (click)="showTransactionDetails = false">
				<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
			</button>
		</p-footer>
	</p-dialog>

	<p-dialog [(visible)]="showViewFileDialog" [responsive]="true" [style]="{'width': '90%', 'max-width':'600px', 'max-height':'85vh'}" [modal]="true" id="viewAttachedModal">
		<p-header>
			<span><i class="fa-solid fa-image mr-2"></i>{{'View Attached' | translate}}</span>
		</p-header>
		<img [src]="fileUrl" *ngIf="fileUrl" class="imageShown" style="width: 100%"/>
		<p-footer class="d-flex justify-content-end">
			<button type="button" (click)="showViewFileDialog = false" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
		</p-footer>
	</p-dialog>
	<p-dialog [showHeader]="true" *ngIf="showHistoryUploadDialog" [(visible)]="showHistoryUploadDialog" [modal]="true" [dismissableMask]="true" class="deparment-history" id="attachmentHistoryModal" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'85vh'}">
		<p-header>
			<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Attachment History' | translate}} <span *ngIf="(dataSource$|async)?.content">({{(dataSource$|async)?.content.length}})</span></span>
		</p-header>
		<p-table #table [value]="(dataSource$|async)?.content" [rows]="(dataSource$|async)?.size" [paginator]="false" [totalRecords]="(dataSource$|async)?.content.length" (onLazyLoad)="loadDataSource($event)" [lazy]="true" [responsive]="true">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of displayColumns" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
						<span class="one-liner" pTooltip="{{ col?.label}}" tooltipPosition="left">{{ col?.label | translate}}</span>
						<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData">
					<td *ngFor="let col of displayColumns">
						<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'updatedDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate  | date : 'M/d/yyyy h:mm:ss a'}}">{{rowData.updatedDate | date : 'M/d/yyyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
							<span *ngSwitchCase="'userName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'User' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
							<span *ngSwitchCase="'oldValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Before' | translate}}: </span>
								<a *ngIf="rowData.oldValue && rowData.fieldName === 'fileUrl'"  class="text-primary" href="javascript:void(0)" (click)="viewFullFile(rowData.oldValue)">{{'Open' | translate}}<i class="ml-2 fa-solid fa-up-right-from-square text-primary c-pointer"></i></a>
								<span *ngIf="!rowData.oldValue || !rowData.fieldName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
							<span *ngSwitchCase="'newValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'After' | translate}}: </span>
								<a *ngIf="rowData.fieldName === 'fileUrl'" class="text-primary" href="javascript:void(0)" (click)="viewFullFile(rowData.newValue)">{{'Open' | translate}}<i class="ml-2 fa-solid fa-up-right-from-square text-primary c-pointer"></i></a>
								<span *ngIf="!rowData.newValue || !rowData.fieldName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
							<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						</ng-container>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
		<p-footer class="d-flex justify-content-end">
			<button class="btn btn-primary" (click)="showHistoryUploadDialog = false">
				<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
			</button>
		</p-footer>
	</p-dialog>
	<p-dialog [(visible)]="showConfirmationPopup" class="modal_in" [modal]='true' [style]="{'width':'90%','max-width':'500px'}">
		<p-header>
			<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Verification' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-12">
				<span style="font-size: 16px">{{'Are you verifying this transaction was received?' | translate}}</span>
			</div>
		</div>
		<p-footer class="d-flex justify-content-between">
			<button type="button" (click)="openConfirmationPopup()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button type="button" (click)="confirmTransaction()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
		</p-footer>
	</p-dialog>
</div>
