import {Component, OnInit, ViewChild} from '@angular/core';
import {Content} from '../../../content/model/content.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentService} from '../../../content/service/content.service';
import {MessageService} from 'primeng/api';
import {Editor} from 'primeng/editor';
import {TranslatePipe} from '@ngx-translate/core';
import {BreadcrumbService} from 'app/layout/service/breadcrumb.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as html2pdf from 'html2pdf.js';
import {MenuItem} from 'primeng';
import * as ckeditorCustom from 'assets/ckeditor-custom/ckeditor';
import {AuthService} from '../../../shared/service/auth/auth.service';

@Component({
  selector: 'app-faq-layout',
  templateUrl: './faq-layout.component.html',
  styleUrls: ['./faq-layout.component.scss'],
  providers: [ContentService]
})
export class FaqLayoutComponent implements OnInit {
  @ViewChild('editor', {static: true})
  editor: Editor;
  contentList: Content[] = [];
  loading: Boolean = false;
  contentFaq: any = [];
  contentShow: any = {};
  searchText: string;
  titleActive: string;
  isActive: boolean;
  idItemActive: number;
  idContentActive: number;
  showMessage: Boolean = false;
  previewImage: any;
  display: Boolean = false;
  defaultBreadCrumbs: MenuItem[] = []
  activeItem: MenuItem;
  menuItems = [];
  faqActiveId: any = '';
  editorNew = ckeditorCustom;

  constructor(private messageService: MessageService,
    private contentService: ContentService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translatePipe: TranslatePipe,
    private breadcrumbService: BreadcrumbService,
    private _sanitizer: DomSanitizer) {
      this.breadcrumbService.setItems([{label: 'Frequently Asked Questions',}]);
    }

  ngOnInit(): void {
    this.loadList();
    this.breadcrumbService.setItems([{label: 'Frequently Asked Questions',}]);

    this.menuItems = [
        { label: 'FAQs', routerLink: ['faq'], visible: true },
      ];
  }

  showContent(id?: number, indexSelect?: number) {
    this.showMessage = false;
    const idItem = id ? id : this.contentList[0].id;
    this.idItemActive = idItem;
    const getItem = this.contentList.filter(x => x.id === idItem);
    this.contentShow = {};
    this.contentShow = getItem[0];
    this.contentList.forEach((item, index) => {
      if (indexSelect === index) {
        item.isSelect = true;
      } else {
        item.isSelect = false;
      }
    });
  }
  containTitle(title: string) {
    if (!this.searchText || this.searchText.trim() == '') return false;
    return title.toLowerCase().includes(this.searchText.toLowerCase());
  }
  seachContent() {
    this.loading = true;
    const searchTextArr: any = (this.searchText || '').split(' ').filter(t => t);
    if (!searchTextArr || searchTextArr.length <= 0) {
      this.idContentActive = 0;
      this.faqActiveId = '';
      return;
    }

    this.showMessage = true;
    const keySearch = searchTextArr[0];
    const subKeySearch = searchTextArr.length > 1 ? searchTextArr.splice(1) : searchTextArr;
    for (const item of this.contentList) {
      if (item.contentFaqs) {
        const getArr = item.contentFaqs.filter(x => {
          const searchContent = x.contentData ? x.contentData.replace(/<[^>]*>?/gm, '').toLowerCase().search(keySearch.toLowerCase()) : -1;
          const searchTitle = x.title ? x.title.replace(/<[^>]*>?/gm, '').toLowerCase().search(keySearch.toLowerCase()) : -1;
          return searchContent > -1 || searchTitle > -1;
        });

        if (getArr.length > 0) {
          this.showMessage = false;
          this.showContentNew(item, subKeySearch);
          break;
        }
      } else {
        const searchTitle = item.title ? item.title.replace(/<[^>]*>?/gm, '').toLowerCase().search(keySearch.toLowerCase()) : -1;
        if (searchTitle > -1) {
          this.showMessage = false;
          this.showContentNew(item, subKeySearch);
          break;
        }
      }
    }
    this.loading = false;
  }
  loadList() {
    this.loading = true;
    const options: any = {
      companyId : this.authService.getCurrentCompanyId()
    };
    this.contentService.getContentPage(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        this.contentList = resObj.data.content.filter(x => x.contentTypeId === 4);
        this.contentList.forEach(content => {
          if (content.contentFaqs && content.contentFaqs.length) {
            content.contentFaqs.forEach(e => {
             e.contentData = (e.contentData || '').replace(/<img src=/g, `<img style="width:650px" src=`);
            });
          }
        });
      }
    });
  }

  checkOnClickImage(event) {
    if (event.target.localName === 'img') {
      this.previewImage = this._sanitizer.bypassSecurityTrustResourceUrl(event.target.currentSrc);
      this.display = true;
    }
  }

  close() {
    this.display = false;
  }

  print(index) {
    const element = document.getElementById(`print_${index}`);
    const toolbar = element.querySelector('.ql-toolbar') as HTMLElement;
    if (toolbar) {
      toolbar.style.display = 'none';
    }
    const opt = {
      margin: 1,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).output('bloburl').then((result) => {
      window.open(result, '_blank');
    });
  }

  searchFaqs(content?: any, searchTextArr?: any) {
    for (let i = 0; i < searchTextArr.length; i++) {
      if (searchTextArr[i] && searchTextArr[i].trim().length > 0 && (content || '').replace(/<[^>]*>?/gm, '').toLowerCase().search(searchTextArr[i].trim().toLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  showContentNew(item?: any, subKeySearch?: any) {
    this.idItemActive = item?.id || this.contentList[0].id;
    const itemShow = Object.assign({}, item);

    this.faqActiveId = itemShow.id;
    if (subKeySearch && subKeySearch.length > 0) {
      itemShow.contentFaqs = (itemShow.contentFaqs || []).filter(t => {
        return this.searchFaqs(t.title, subKeySearch) || this.searchFaqs(t.contentData, subKeySearch);
      });
    }

    if (itemShow.contentFaqs && itemShow.contentFaqs.length > 0) {
      this.idContentActive = itemShow.contentFaqs[0]?.id;
    }
    this.contentShow = itemShow;
  }

  ckEditorConfig: any = {
    toolbar: {
      items: []
    }
  };
}
