import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import * as MOMENT from 'moment';
import { CompanyService } from '../../service/company.service';
import { CompanySetting } from '../../model/company-setting.model';
import { DocumentsService } from '../../../shared/service/documents-upload/documents.service';
import {Constants} from '../../../shared/model/constants';
import Quill from 'quill';
import AutoLinks from 'quill-auto-links';
import { Editor } from 'primeng/editor';
import {DocumentSettingsService} from "../../service/document-settings.service";
import {UtilsService} from "../../../shared/service/utils.service";
import {MonitoringDetailsService} from "../../../admin/process-monitor/service/monitoring-details.service";
// import { NgxFaviconService } from 'ngx-favicon';

@Component({
    selector: 'app-company-setting',
    templateUrl: './company-setting.component.html',
    styleUrls: ['./company-setting.component.css'],
    providers: [AuthService, FormValidatorService, CompanyService, DocumentsService]
})
export class CompanySettingComponent implements OnInit {
    @Input()
    companyId: number;
    companySettingFormData: CompanySetting;
    companySettingsForm: UntypedFormGroup;
    displayEditModal = false;
    logoUrl: string;
    @Input()
    mode: string;
    @Input()
    readOnly: Boolean = false;
    @Input() settingsForm: ElementRef;
    days: Array<any>;
    msgs: Message[];
    @ViewChild('profilePhoto') profilePhoto: any;
    companySettingCheckbox: any[];
    showPwd = false;
    quillConfig = {
        autoLinks: true
    };
    historyType;
    drivers = [{value: 'SMTP', label: 'SMTP'}, {value: 'Mail', label: 'Mail'}, {value: 'SendMail', label: 'SendMail'}, {value: 'Mailgun', label: 'Mailgun'}];
    encryptions = [{value: 'SSL', label: 'SSL'}, {value: 'TLS', label: 'TLS'}, {value: 'NONE', label: 'NONE'}];
    editMode: Boolean = false;
    remainCharText1: number = 150;
    remainCharText2: number = 150;
    remainCharEditor: number = 500;
    maxLengthEditor = 500;
    editEditor = false;
    @ViewChild('editor', { static: true }) editor: Editor;
    cols = [
        { field: 'id', label: '#'},
        { field: 'documentName', label: 'Document Name'},
        { field: 'information', label: 'Detail'},
        { field: 'comply', label: 'Currently Comply with'}

    ];
    information;
    compliances: any = [];
    otherCompliances: any = [];
    requiredCompliances: any = [];
    company;
    isPlatformAdmin;
    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidator: FormValidatorService,
        private documentService: DocumentsService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private documentsService: DocumentsService,
        private utilsService: UtilsService,
        private monitoringDetailsService: MonitoringDetailsService,
        private documentSettingsService: DocumentSettingsService
    ) {
        Quill.register('modules/autoLinks', AutoLinks);
        this.route.params.subscribe(params => this.companyId = +params.id);
        this.companySettingCheckbox = [
            { id: 1, name: 'companySettingCheckbox', value: 'Dashboard', label: 'Dashboard', isSelected: false },
            { id: 2, name: 'companySettingCheckbox', value: 'Platform Admin', label: 'Platform Admin', isSelected: false },
            { id: 3, name: 'companySettingCheckbox', value: 'Admin', label: 'Admin', isSelected: false },
            { id: 4, name: 'companySettingCheckbox', value: 'Employee Profile', label: 'Employee Profile', isSelected: false },
            { id: 5, name: 'companySettingCheckbox', value: 'Company', label: 'Company', isSelected: false },
            { id: 6, name: 'companySettingCheckbox', value: 'Time off Review', label: 'Time off Review', isSelected: false },
            { id: 7, name: 'companySettingCheckbox', value: 'Total Job', label: 'Total Job', isSelected: false },
            { id: 8, name: 'companySettingCheckbox', value: 'CRM', label: 'CRM', isSelected: false },
            { id: 9, name: 'companySettingCheckbox', value: 'Client Invoice', label: 'Client Invoice', isSelected: false },
            { id: 10, name: 'companySettingCheckbox', value: 'Member Profile', label: 'Member Profile', isSelected: false },
            { id: 11, name: 'companySettingCheckbox', value: 'Guest', label: 'Guest', isSelected: false },
            { id: 12, name: 'companySettingCheckbox', value: 'Employee Payment', label: 'Employee Payment', isSelected: false },
            { id: 13, name: 'companySettingCheckbox', value: 'Payment', label: 'Payment', isSelected: false },
            { id: 14, name: 'companySettingCheckbox', value: 'Department', label: 'Department', isSelected: false },
            { id: 15, name: 'companySettingCheckbox', value: 'Billing', label: 'Billing', isSelected: false },
            { id: 16, name: 'companySettingCheckbox', value: 'Punch History', label: 'Punch History', isSelected: false },
            { id: 17, name: 'companySettingCheckbox', value: 'Process Monitor', label: 'Process Monitor', isSelected: false },
            { id: 18, name: 'companySettingCheckbox', value: 'Job Report', label: 'Job Report', isSelected: false },
            { id: 19, name: 'companySettingCheckbox', value: 'Billing Log', label: 'Billing Log', isSelected: false },
            { id: 20, name: 'companySettingCheckbox', value: 'Compliance Check Wizard', label: 'Compliance Check Wizard', isSelected: false },
            { id: 21, name: 'companySettingCheckbox', value: 'Timer for time recording', label: 'Timer for time recording', isSelected: false },
            { id: 22, name: 'companySettingCheckbox', value: 'Show Working Status', label: 'Show Working Status', isSelected: false }
        ];
    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.getAllDocument();
        this.initForm();
        this.companySettingFormData = new CompanySetting();
        this.getSettings(this.companyId);
        this.days = [{ label: 'Mon', value: 1 }, { label: 'Tue', value: 2 }, { label: 'Wed', value: 3 }, { label: 'Thur', value: 4 }, {
            label: 'Fri',
            value: 5
        }, { label: 'Sat', value: 6 }, { label: 'Sun', value: 7 }];
        this.msgs = [];
        this.getCompany();
    }

    getCompany() {
        this.companyService.get(this.companyId).subscribe(res => {
            // console.log(res)
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.company = resObj.data;
            }
        });
    }

    selectAll() {
        for (let i = 0; i < this.companySettingCheckbox.length; i++) {
            this.companySettingCheckbox[i].isSelected = this.companySettingFormData.selectedAllShortcuts;
        }
    }
    initForm() {
        this.companySettingsForm = new UntypedFormGroup({
            mainWhiteLabel: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            upperLeftLabel: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            clientDashboardF1: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            clientDashboardF2: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            clientDashboardF3: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            marquee: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            driver: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            host: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            fromName: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            port: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            fromAddress: new UntypedFormControl({ value: '', disabled: this.readOnly }, [this.emailValidator]),
            encryption: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            userName: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            password: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            emailSignature: new UntypedFormControl({ value: '', disabled: this.readOnly }),
        });
    }

    onCancel() {
        if (this.mode === Constants.FORM_MODE.UPDATE) {
            this.viewCompanySetting();
            this.editMode = true;
        } else {
            localStorage.removeItem('_companySetting');
            this.router.navigate(['app/company']);
            this.editMode = false;
        }

    }

    editCompanySetting () {
        this.router.navigate(['app/company', 'other', 'edit', this.companyId]);
        this.editMode = true;
    }

    viewCompanySetting () {
        this.router.navigate(['app/company', 'other', 'view', this.companyId]);
        this.editMode = false;
    }

    modifyCompanySetting(form) {
        const selectedShortcut = [];
        this.companySettingCheckbox.forEach((item) => {
            if (item.isSelected) {
                selectedShortcut.push(item.id);
            }
        });
        if (this.formValidator.validateForm(this.companySettingsForm, form)) {
            localStorage.setItem('_companySetting', JSON.stringify(this.companySettingFormData));
            let documentId = [];
            this.requiredCompliances.forEach((item) => {
                if (item.comply) {
                    documentId.push(item.id);
                }
            });
            this.otherCompliances.forEach((item) => {
                if (item.comply) {
                    documentId.push(item.id);
                }
            });
            this.companySettingFormData.shortcutsCheckBoxes = selectedShortcut;
            this.companySettingFormData.documentId = documentId;
            console.log(this.companySettingFormData);
            this.companySettingFormData.createdByUsr = this.authService.getCurrentUsername();
            this.companySettingFormData.lastModifiedBy = this.authService.getCurrentUsername();
            this.companySettingFormData.showDocumentStatus = this.companySettingFormData.showDocumentStatus ? '1' : '0';
            if (this.companySettingFormData.id == null) {
                this.companyService.saveCompanySetting(this.companySettingFormData).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Company Settings have been updated!' });
                        setTimeout(() => this.viewCompanySetting(), 2000);
                        const actionTime = this.utilsService.visitedPageData.pageActionTime;
                        this.monitoringDetailsService.monitorNavigationProcess('Other settings updated',
                            { 'other_settings_updated': this.authService.getCurrentLoggedInName() },
                            'Completed', 'Other settings updated', actionTime, false, false, true);
                    }
                    localStorage.setItem('_shortcutSetting', 'yes');
                });
            } else {
                this.companyService.updateCompanySetting(this.companySettingFormData).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Company Settings have been updated!' });
                        const actionTime = this.utilsService.visitedPageData.pageActionTime;
                        this.monitoringDetailsService.monitorNavigationProcess('Other settings updated',
                            { 'other_settings_updated': this.authService.getCurrentLoggedInName() },
                            'Completed', 'Other settings updated', actionTime, false, false, true);
                        setTimeout(() => this.viewCompanySetting(), 2000);
                    }
                    localStorage.setItem('_shortcutSetting', 'yes');
                });
            }

        }
    }

    getSettings(companyId: number) {
        if (this.mode === 'view') {
            this.companyService.getCompanySetting(companyId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    if (resObj.data) {
                        if (resObj.data) {
                            this.companySettingFormData = resObj.data;
                            this.companySettingFormData.showDocumentStatus = this.companySettingFormData.showDocumentStatus && this.companySettingFormData.showDocumentStatus === '1' ? true : false;
                        }

                        this.selectShortcuts(this.companySettingFormData?.shortcutsCheckBoxes);
                        this.logoUrl = this.companySettingFormData?.document?.fileUrl;
                        localStorage.setItem('_companySetting', JSON.stringify(this.companySettingFormData));
                    } else {
                        this.companySettingFormData = new CompanySetting();
                        this.companySettingFormData.companyId = companyId;
                        this.initForm();
                    }
                    if (this.compliances) {
                        if (this.companySettingFormData && this.companySettingFormData.documentId) {
                            for (let compliance of this.compliances) {
                                if (this.companySettingFormData.documentId.includes("" + compliance.id)) {
                                    compliance.comply = true;
                                }
                            }
                        }
                    }
                    this.requiredCompliances = this.compliances.filter(item => item.isRequired);
                    this.otherCompliances = this.compliances.filter(item => !item.isRequired);
                    this.countRemainCharacterText1(this.companySettingFormData.mainWhiteLabel);
                    this.countRemainCharacterText2(this.companySettingFormData.upperLeftLabel);
                }
            });
        }
        if (this.mode === 'update') {
            const data = JSON.parse(localStorage.getItem('_companySetting'));
            if (data) {
                this.companySettingFormData = data;
                this.selectShortcuts(this.companySettingFormData?.shortcutsCheckBoxes);
                if (data.document) { this.logoUrl = data.document.fileUrl; }
            } else {
                this.companySettingFormData = new CompanySetting();
                this.companySettingFormData.companyId = companyId;
                this.initForm();
            }
            this.countRemainCharacterText1(this.companySettingFormData.mainWhiteLabel);
            this.countRemainCharacterText2(this.companySettingFormData.upperLeftLabel);
        }
    }

    selectShortcuts(listItem) {
        this.companySettingCheckbox.forEach((item) => {
            listItem.forEach(ele => {
                if (item.id === parseInt(ele)) { item.isSelected = true; }
            });
        });
        if (this.companySettingCheckbox.length === listItem.length) { this.companySettingFormData.selectedAllShortcuts = true; 
       } else { this.companySettingFormData.selectedAllShortcuts = false; }
    }

    onBasicUpload(event) {
        for (const file of event.files) {
            console.log(file);
        }
    }

    uploadProfilePhoto(event, profileFile) {
        if (event.files[0]) {
            this.documentService.uploadFile(event.files[0],
                'CompanySetting', this.companySettingFormData.id, 'CompanySetting').subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.logoUrl = resObj.data.fileUrl;

                        if (this.authService.getCurrentCompany() && this.authService.getCurrentCompany().id === this.companyId) {
                            document.getElementById('favicon').setAttribute('href', this.logoUrl);
                        }
                        this.companySettingFormData.logoId = resObj.data.id;
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Document uploaded successfully!' });
                        profileFile.clear();
                    }
                });
        }
    }
    emailValidator(control: UntypedFormControl) {
        const mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})/;
        if (control && control.value && !mailFormat.test(control.value)) {
            return { 'invalidEmail': true };
        }
        return null;
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }
    openFileSelector() {
        console.log('Button clicked!'); // This should log when you click the button
        const fileInput = this.profilePhoto.el.nativeElement.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error('Couldn\'t find the file input');
        }
    }
    togglePwdVisibility() {
        this.showPwd = !this.showPwd;
    }
    historyClick(type) {
        this.displayEditModal = true;
        this.historyType = type;
        console.log(this.historyType);
    }

    countRemainCharacterText1(evt): boolean {
        this.remainCharText1 = this.getMaxLengthChars(150, evt);
        if (this.remainCharText1) {
            return true;
        } else {
            return false;
        }
    }

    countRemainCharacterText2(evt): boolean {
        this.remainCharText2 = this.getMaxLengthChars(150, evt);
        if (this.remainCharText2) {
            return true;
        } else {
            return false;
        }
    }

    getMaxLengthChars(length: number, event) {
        const value = event.target ? event.target.value : event;
        let numberOfWords = 0;
        if (!value.length) {
            return length;
        }
        if (value) {
            numberOfWords = value.length;
        }
        if (numberOfWords >= length) {
            return 0;
        }
        return length - numberOfWords;
    }

    checkMaxLength(event) {
        this.editEditor = true;
        if (event.textValue.length > this.maxLengthEditor) {
            if (event.delta.ops[0].retain) {
                this.editor.getQuill().deleteText(event.delta.ops[0].retain, event.textValue.length - this.maxLengthEditor)
            } else {
                this.editor.getQuill().deleteText(0, event.textValue.length - this.maxLengthEditor)
            }
            
            this.remainCharEditor = 0
        } else {
            this.remainCharEditor = this.maxLengthEditor - event.textValue.length;
        }
      }

    getAllDocument() {
        this.documentSettingsService.search({type: 'default', companyId: this.companyId, forPolicy: true}).subscribe(res => {
            this.compliances = res.data || [];
            this.compliances = this.compliances.filter(item => item.isVisible);
            if (this.companySettingFormData && this.companySettingFormData.documentId) {
                for (let compliance of this.compliances) {
                    if (this.companySettingFormData.documentId.includes("" + compliance.id)) {
                        compliance.comply = true;
                    }
                }
            }
            this.requiredCompliances = this.compliances.filter(item => item.isRequired);
            this.otherCompliances = this.compliances.filter(item => !item.isRequired);
        });
    }

    fileUrl;
    showViewFileDialog;
    onViewDocument(documentId) {
        this.documentsService.getUploadedFile(documentId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data.fileType === 'pdf'
                    || resObj.data.fileType === 'txt'
                    || resObj.data.fileType === 'docx'
                    || resObj.data.fileType === 'doc'
                    || resObj.data.fileType === 'xls'
                    || resObj.data.fileType === 'xlsx'
                ) {
                    window.open(resObj.data.fileUrl);
                } else {
                    this.fileUrl = resObj.data.fileUrl;
                    this.showViewFileDialog = true;
                }
            }
        }, error => {
            // error
        });
    }
    hasCompliancePermission() {
        return this.authService.getLoggedInUserMenuList().includes('1681');
    }
}
