import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { PayrollHolidayService } from '../../service/payroll-holiday.service';
import { PayrollHoliday } from '../../model/payroll-holiday.model';
import { EmployeeService } from '../../../employee/service/employee.service';
import { Employee } from '../../../employee/model/employee.model';
import { DepartmentService } from '../../../department/service/department.service';
import { Department } from '../../../department/model/department.model';
import { PayrollSettingService } from '../../service/payroll-setting.service';
import { Mileage, PayrollSetting } from '../../model/payroll-setting.model';
import { AgencyHolidayService } from '../../../agency/service';
import { AgencyHoliday } from '../../../agency/model';
import { PositionService } from '../../../position/service/position.service';
import { Position } from '../../../position/model/position.model';
import { CompanyHolidayService } from 'app/company/service/company-holiday.service';
import { PositionV2Service } from 'app/position/service/v2/position.v2.service';
import { DepartmentServicev2 } from 'app/department/service/v2/department.v2.service';
import { PayrollHolidayV2 } from '../../model/payroll-holiday-v2.model';
import { MessageService } from 'primeng/api';
import {PositionSearch} from '../../../shared/model/search/position.search';
import {DepartmentSearch} from '../../../shared/model/search/department.search';
@Component({
    selector: 'app-payroll-holiday-form-new',
    templateUrl: './payroll-holiday-form-new.component.html',
    styleUrls: ['./payroll-holiday-form-new.component.css'],
    providers: [PayrollHolidayService, FormValidatorService, AuthService,
        EmployeeService, DepartmentService, PayrollSettingService, PositionService]
})
export class PayrollHolidayFormNewComponent implements OnInit {
    @Input() mode: String;
    @Input() readOnly: Boolean = false;
    @Input() companyId: any;
    @Input() payrollHolidayId: number;
    @Input() companyDataForm: ElementRef;
    @ViewChild('fdt', { static: true }) table: Table;
    msgs = [];
    @Input() payrollSetting: PayrollSetting = new PayrollSetting();
    companyForm: UntypedFormGroup;
    payrollHolidays: PayrollHolidayV2[] = [];
    holidays: AgencyHoliday[] = [];
    holiday: AgencyHoliday;
    employees: Employee[] = [];
    departments: any[] = [];
    userPermission: boolean;
    operationDays: Array<any> = [];
    statusTypes: Array<any> = [];
    payrollHoliday: PayrollHolidayV2;
    agencyHolidays: AgencyHoliday[];
    positions: any[] = [];
    returnUrl: string;
    constructor(
        private payrollHolidayService: PayrollHolidayService,
        private employeeService: EmployeeService,
        private positionV2Service: PositionV2Service,
        private departmentService: DepartmentService,
        private departmentServicev2: DepartmentServicev2,
        private payrollSettingService: PayrollSettingService,
        private agencyHolidayService: AgencyHolidayService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,

        private companyHolidayService: CompanyHolidayService) {
    }

    ngOnInit() {
        if (!this.payrollHolidayId) {
            this.payrollHolidays.push(new PayrollHolidayV2());
            this.employeeService.getAllByAgency(this.companyId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employees = resObj.data;
                }
            });
        }

        this.holidays = [];
        const search: PositionSearch = {
            companyId: this.companyId,
            status: 1
        };
        this.positionV2Service.getDropdown(search).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.positions = [];
                resObj.data.forEach(rs => {
                    this.positions.push({ value: rs.key, label: rs.value });
                });
            }
        });

        const serachFilter = <any>{};
        serachFilter.status = 'ACTIVE';
        this.companyHolidayService.getHoliDay( this.companyId, serachFilter).subscribe(
            (data: any) => {
                const resObj = data.data.content;
                if (resObj.length > 0) {
                this.holidays = resObj;
                }
            }
        );
        const departmentSearch: DepartmentSearch = {
            companyId: this.companyId,
            status: 1
        };
        this.departmentServicev2.getDropdown(departmentSearch).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.departments = [];
                resObj.data.forEach(rs => {
                    this.departments.push({ value: rs.key, label: rs.value });
                });
            }
        });

        if (this.payrollHolidayId) {

            this.payrollHolidayService.findById(this.payrollHolidayId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.payrollHoliday = resObj.data;
                    this.payrollHoliday.positions = this.payrollHoliday.positions.map(Number);

                    this.payrollHoliday.departments = this.payrollHoliday.departments.map(Number);
                    this.holiday = this.payrollHoliday.holiday;
                    // this.departmentService.get(this.payrollHoliday.departmentId).subscribe(res1 => {
                    //     const resObj1: any = res1;
                    //     if (resObj1.status === 'SUCCESS') {
                    //         this.payrollHoliday.department = resObj1.data;
                    //     }
                    // });
                    setTimeout(() =>{
                        this.changeDepartments( this.payrollHoliday.departments);
                        this.changePositions( this.payrollHoliday.positions);
                    },0)
                    this.payrollHolidays.push(this.payrollHoliday);
                }
            });
        }
        this.route.queryParams.subscribe((params) => {
            if (params.returnUrl !== undefined) {
                this.returnUrl = params.returnUrl;
            } else {
                this.returnUrl = "/app/agency/list";
            }
        });
    }
    
    changeHoliday(event) {
        // tslint:disable-next-line: no-console
    }

    changePositions(value) {
        if (value.length === this.positions.length) {
            const x = document.getElementById('selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${value.length} items selected`;
        }
    }

    changeDepartments(value) {
        if (value.length === this.departments.length) {
            const x = document.getElementById('department_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('department_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${value.length} items selected`;
        }
    }

    addRowPayrollHoliday() {
        this.payrollHolidays.push(new PayrollHolidayV2());
    }

    updateCompany() {


    }

    getCompany() {

    }

    updatePayrollHoliday() {
        if (this.isSavingPayrollHoliday) {
            return;
        }
        this.isSavingPayrollHoliday = true;
        this.messageService.clear();
        let hasError = false;
        this.payrollHolidays.forEach(payrollHoliday => {
            // payrollHoliday.payrollSettingsId = this.payrollSetting.id;
            payrollHoliday.holidayId = this.holiday.id;
            payrollHoliday.positionNames = [];
            if (!payrollHoliday.positions || !payrollHoliday.positions.length) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select position' });
                hasError = true;
                return;
            }
            if (!payrollHoliday.departments || !payrollHoliday.departments.length) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select department' });
                hasError = true;
                return;
            }
            payrollHoliday.positions.forEach(pos => {
                const position: any = this.positions.find(p => p.value === parseInt(pos, 10));
                payrollHoliday.positionNames.push(position.label);
            });
            payrollHoliday.departmentNames = [];
            payrollHoliday.departments.forEach(dep => {
                const position: any = this.departments.find(p => p.value === parseInt(dep, 10));
                payrollHoliday.departmentNames.push(position.label);
            });
            // payrollHoliday.departmentId = payrollHoliday.department.id;
            // payrollHoliday.position = payrollHoliday.position;
        });
        if (hasError) {
            this.isSavingPayrollHoliday = false;
            return;
        }
        this.payrollHolidayService.updateHoliday(this.payrollHolidays[0]).subscribe(res1 => {
            const resObj1: any = res1;
            if (resObj1.status === 'SUCCESS') {
                this.messageService.add({ severity: 'info', summary: 'updated', detail: 'Payroll Holiday updated successfully!' });
                setTimeout(() => {
                    this.router.navigateByUrl(this.returnUrl);
                    this.isSavingPayrollHoliday = false;
                }, 2000);
            } else {
                this.isSavingPayrollHoliday = false;
            }
        }, error => {
            this.isSavingPayrollHoliday = false;
        });
    }

    isSavingPayrollHoliday;
    addPayrollHoliday() {
        if (this.isSavingPayrollHoliday) {
            return;
        }
        this.isSavingPayrollHoliday = true;
        this.messageService.clear();
        this.payrollSettingService.getbyId(this.companyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.payrollSetting = resObj.data;
                let hasError = false;
                this.payrollHolidays.forEach(payrollHoliday => {
                    payrollHoliday.payrollSettingsId = this.payrollSetting.id;
                    if ( this.holiday) {
                        payrollHoliday.holidayId = this.holiday.id;
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select holiday' });
                        hasError = true;
                        return;
                    }
                    if (!payrollHoliday.positions || !payrollHoliday.positions.length) {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select position' });
                        hasError = true;
                        return;
                    }
                    if (!payrollHoliday.departments || !payrollHoliday.departments.length) {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select department' });
                        hasError = true;
                        return;
                    }
                    payrollHoliday.positionNames = [];
                    payrollHoliday.positions.forEach(pos => {
                        const position: any = this.positions.find(p => p.value === parseInt(pos, 10));
                        payrollHoliday.positionNames.push(position.label);
                    });
                    payrollHoliday.departmentNames = [];
                    payrollHoliday.departments.forEach(dep => {
                        const position: any = this.departments.find(p => p.value === parseInt(dep, 10));
                        payrollHoliday.departmentNames.push(position.label);
                    });
                    // payrollHoliday.departmentId = payrollHoliday.department.id;
                    // payrollHoliday.position = payrollHoliday.position;
                    payrollHoliday.holiday = this.holiday;
                });
                if (hasError) {
                    this.isSavingPayrollHoliday = false;
                    return;
                }
                this.payrollSettingService.savePayrollHoliday(this.companyId, this.payrollSetting.id, this.payrollHolidays).subscribe(res1 => {
                    const resObj1: any = res1;
                    if (resObj1.status === 'SUCCESS') {
                        console.log('resObj1', resObj1);
                        const dataInsert = resObj1.data;
                        const payrollHolidaysString = localStorage.getItem('_payrollHolidaysCache');
                        if (payrollHolidaysString) {
                            const payrollHoliday = JSON.parse(payrollHolidaysString);
                            dataInsert.push(...payrollHoliday);
                        }
                        localStorage.setItem('_payrollHolidaysCache', JSON.stringify(dataInsert));

                        this.messageService.add({ severity: 'info', summary: 'created', detail: 'Payroll Holiday added successfully!' });
                        setTimeout(() => {
                            this.router.navigateByUrl(this.returnUrl);
                            this.isSavingPayrollHoliday = false;
                        }, 2000);
                    } else {
                        this.isSavingPayrollHoliday = false;
                    }

                }, error => {
                    this.isSavingPayrollHoliday = false;
                });
            }
        }, error => {
            this.isSavingPayrollHoliday = false;
        });

    }
    goBack() {
        this.router.navigateByUrl(this.returnUrl);

    }

    isNumberKey(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
           return false;
        return true;
    }
}
