<p-tabMenu [model]="menuItems" id="tabs">
    <ng-template pTemplate="item" let-item>
        {{item.label | translate}}
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
        {{item.label | translate}}
    </ng-template>
</p-tabMenu>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Frequently Asked Questions' | translate}}</h1>
	<div class="ui-g">
		<div class="ui-g-3 ui-sm-12">
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-folder-tree text-primary mr-2"></i>{{'Topics' | translate}}</span>
				</p-header>
				<ul class="list-group list-group-flush">
					<li *ngFor="let item of contentList; let i = index;" class="list-group-item" style="background-color: {{item.isSelect ? 'grey' : 'white'}}">
						<div (click)="showContent(item.id, i)" class="d-flex justify-content-between align-items-center w-100" >
                            <span class="one-liner c-pointer" [ngClass]="{'highlight': containTitle(item.title)}">{{item.title}}</span>
                            <span *ngIf="item.contentFaqs?.length > 0" class="badge badge-primary">{{item.contentFaqs?.length}}</span>
                        </div>
					</li>
				  </ul>
            </p-panel>
		</div>
		<div class="ui-g-9 ui-sm-12">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-circle-question text-primary mr-2"></i>{{'Ask a Question' | translate}}</span>
				</p-header>
				<div class="ui-g pb-3">
					<div class="ui-g-12">
						<h2 class="m-0 mb-3">{{'Ask a question or browse by category below' | translate}}</h2>
						<div class="input-group flex-grow-1">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button">
									<i class="fa-solid fa-comment"></i>
								</button>
							</div>
							<input type="text" pInputText placeholder="{{'Start typing your question' | translate}}" [(ngModel)]="searchText" size="100" (keyup)="seachContent()">
						</div> 
					</div>
				</div>
			</p-panel>
			<br>
			<div class="ql-editor bg-white" style="padding: 15px;" *ngIf="showMessage">{{'no data' | translate}}</div>
            <p-panel *ngIf="!showMessage" [toggleable]="true">
				<p-header>
					<span *ngIf="!contentShow.title"><i class="fa-solid fa-question text-primary mr-2"></i>{{'Help Content' | translate}}</span>
					<span *ngIf="contentShow.title"><i class="fa-solid fa-book-open text-primary mr-2"></i>{{contentShow.title}}</span>
				</p-header>
                <div style="padding: 15px;" *ngIf="contentShow?.contentFaqs?.length === 0" class="text-muted">{{'no data' | translate}}</div>
				<div class="d-flex flex-column p-4">
					<h4 *ngIf="contentShow?.contentFaqs?.length === 0" class="m-0 mb-2">{{'Contents' | translate}}</h4>
					<a *ngFor="let content of contentShow?.contentFaqs; let i = index" class="m-0" href="#item_{{i}}"
					   [ngClass]="{'highlight': content.id === idContentActive}" (click)="idContentActive = content.id">{{content.title}}</a>
				</div>
                <div *ngFor="let content of contentShow?.contentFaqs; let i = index">
					<hr id="item_{{i}}">
					<div class="p-4">
						<h4 class="m-0 mb-2" [ngClass]="{'highlight': content.id === idContentActive}">{{content.title}}</h4>
<!--						<p-editor id="print_{{i}}" [(ngModel)]="content.contentData" [readonly]="true" (click)="checkOnClickImage($event)"></p-editor>-->
<!--						 <div (click)="checkOnClickImage($event)" id="print_{{i}}" [innerHTML]="content.contentData" class="print-image"></div>-->
						<ckeditor id="print_{{i}}" [editor]="editorNew" [(ngModel)]="content.contentData" (click)="checkOnClickImage($event)" [config]="ckEditorConfig" [contentEditable]="false"></ckeditor>
						<button class="btn btn-primary" (click)="print(i)">
							<i class="fa-solid fa-print text- mr-2"></i>{{ 'Print' | translate }}
						</button>
						<a class="btn btn-white ml-2" href="#tabs">
							<i class="fa-solid fa-chevron-up text-primary mr-2"></i>{{ 'Top' | translate }}
						</a>
					</div>
				</div>
            </p-panel> 
        </div>
	</div>
</div>
<p-dialog [showHeader]="false" [(visible)]="display"  [style]="{width: '93%',top:'30px', left:'15px'}" modal='true'>
    <div class="close" (click)="display=false">X</div>
    <img [src]="previewImage" width="100%">
</p-dialog>
