import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { CrmCommService } from '../../../crm/service/crm-comm.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-employee-clocking-layout',
    templateUrl: './employee-clocking-layout.component.html',
    styleUrls: ['./employee-clocking-layout.component.scss']
})
export class EmployeeClockingLayoutComponent implements OnInit {
    employeeTabMenuItems: MenuItem[] = [];
    msgs: Message[] = [];
    isPunchInOut: Boolean;
    isRouterPunch: Boolean = false;
    defaultBreadCrumbs: MenuItem[] = [];

    initBreadCrumbs() {
        this.defaultBreadCrumbs = [
            { label: 'Employee' },
        ];
    }
    routerEventsSubscribe;

    constructor(private router: Router, private crmCommService: CrmCommService, private authService: AuthService,
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private translatePipe: TranslatePipe) {
        this.routerEventsSubscribe = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                console.log(event.url);
                if (event.url.indexOf('/app/employee-clocking/summary-view') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Employee ST' });
                } else if (event.url.indexOf('/app/employee-clocking/employee-ot') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Employee OT' });
                } else if (event.url === '/app/employee-clocking/punch') {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Employee Punch', id: 'punch_io', routerLink: ['punch'] });
                } else if (event.url === '/app/employee-clocking/ontime-late') {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Employee Punch' });
                    this.defaultBreadCrumbs.push({ label: 'Punctuality', id: 'ontime_late', routerLink: ['ontime-late'] });
                } else if (event.url === '/app/employee-clocking/punch-history') {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Employee Punch' });
                    this.defaultBreadCrumbs.push({ label: 'punchHistory', id: 'punch_history', routerLink: ['punch-history'] });
                } else if (event.url === '/app/employee-clocking/punch-tracking') {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Employee Punch', routerLink: ['punch-tracking'] });
                    this.defaultBreadCrumbs.push({ label: 'Punch Tracking', id: 'punch_tracking', routerLink: ['punch-tracking'] });
                } else if (event.url === '/app/employee-clocking/punch-tracking') {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Punch Tracking', id: 'protest-hour', routerLink: ['protest-hour'] });
                } else if (event.url === '/app/employee-clocking/protest-hour') {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Time Corrections', id: 'protest-hour' });
                } else if (event.url.indexOf('/app/employee-clocking/work-confirmation') >= 0) {
                    this.initMenuTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'Work Time Confirmation', id: 'work-confirmation' });
                } else {
                    this.initBreadCrumbs();
                    this.initMenuTab();
                }
                setTimeout(() => {
                    this.breadcrumbService.setItems(this.defaultBreadCrumbs);
                }, 1000)

            }
        });
    }

    ngOnInit() {
        // console.log()
        const tempData = JSON.parse(localStorage.getItem('_globals'));
        if (tempData && tempData.level === 8) {
            this.isPunchInOut = true;
        }
    }

    ngOnDestroy() {
        if (this.routerEventsSubscribe) {
            this.routerEventsSubscribe.unsubscribe();
        }
    }
    initMenuTab() {
        const isSummaryViewVisible = this.checkIsSummaryViewVisible();
        const isEmployeeOTVisible = this.checkIsEmployeeOTVisible();
        this.employeeTabMenuItems = [
            { label: 'Punch Clock', id: 'punch_io', routerLink: ['punch'] },
            { label: 'punchHistory', routerLink: ['punch-history'] },
            {
                label: 'Time Corrections',
                id: 'time_correction',
                routerLink: ['protest-hour'],
                visible: (this.authService.getLoggedInUserMenuList().indexOf('45') > -1 ? true : false)
            },
            { label: 'Work Time Confirmation', id: 'work-confirmation', routerLink: ['work-confirmation']},
            { label: 'Job Reports', id: 'job-reports', routerLink: ['/app/employee/employee-job-report/list']},
        ];
        if (isSummaryViewVisible && isEmployeeOTVisible) {
            this.employeeTabMenuItems = [
                { label: 'Punch Clock', id: 'punch_io', routerLink: ['punch'] },
                { label: 'punchHistory', id: 'punch_history', routerLink: ['punch-history'] },
                {
                    label: 'Punctuality', id: 'ontime_late', routerLink: ['ontime-late'],
                    visible: this.authService.isEmployeeRole() ? true :
                        this.authService.getLoggedInUserMenuList().includes('1690')
                },
                {
                    label: 'Punch Tracking', id: 'punch_io_tracking', routerLink: ['punch-tracking'],
                    visible: this.authService.isEmployeeRole() ? true :
                        this.authService.getLoggedInUserMenuList().includes('1171'),
                },
                {
                    label: 'Time Corrections',
                    id: 'time_correction',
                    routerLink: ['protest-hour'],
                    visible: (this.authService.getLoggedInUserMenuList().indexOf('45') > -1 ? true : false)
                },
                { label: 'Work Time Confirmation', id: 'work-confirmation', routerLink: ['work-confirmation']},
                { label: 'Job Reports', id: 'job-reports', routerLink: ['/app/employee/employee-job-report/list']},
            ];
        } else if (isSummaryViewVisible && !isEmployeeOTVisible) {
            this.employeeTabMenuItems = [
                { label: 'Punch Clock', id: 'punch_io', routerLink: ['punch'] },
                { label: 'punchHistory', id: 'punch_history', routerLink: ['punch-history'] },
                { label: 'Punctuality', id: 'ontime_late', routerLink: ['ontime-late'] },
                { label: 'EmployeeST', id: 'employee_st', routerLink: (isSummaryViewVisible) ? ['summary-view'] : null },
                {
                    label: 'Time Corrections',
                    id: 'time_correction',
                    routerLink: ['protest-hour'],
                    visible: (this.authService.getLoggedInUserMenuList().indexOf('45') > -1 ? true : false)
                },
                { label: 'Work Time Confirmation', id: 'work-confirmation', routerLink: ['work-confirmation']},
                { label: 'Job Reports', id: 'job-reports', routerLink: ['/app/employee/employee-job-report/list']},
            ];
        } else if (!isSummaryViewVisible && isEmployeeOTVisible) {
            this.employeeTabMenuItems = [
                { label: 'Punch Clock', id: 'punch_io', routerLink: ['punch'] },
                { label: 'punchHistory', id: 'punch_history', routerLink: ['punch-history'] },
                { label: 'Punctuality', id: 'ontime_late', routerLink: ['ontime-late'] },
                { label: 'EmployeeOT', id: 'employee_ot', routerLink: (isEmployeeOTVisible) ? ['employee-ot'] : null },
                {
                    label: 'Time Corrections',
                    id: 'time_correction',
                    routerLink: ['protest-hour'],
                    visible: (this.authService.getLoggedInUserMenuList().indexOf('45') > -1 ? true : false)
                },
                { label: 'Work Time Confirmation', id: 'work-confirmation', routerLink: ['work-confirmation']},
                { label: 'Job Reports', id: 'job-reports', routerLink: ['/app/employee/employee-job-report/list']},
            ];
        }
    }

    checkIsSummaryViewVisible() {
        const menuList = this.authService.getLoggedInUserMenuList();
        return (menuList.indexOf('73') >= 0) ? true : false;
    }

    checkIsEmployeeOTVisible() {
        const menuList = this.authService.getLoggedInUserMenuList();
        return (menuList.indexOf('74') >= 0) ? true : false;
    }

    action(event) {
        if (event.srcElement.innerHTML === this.translatePipe.transform('EmployeeST')) {
            if (!this.checkIsSummaryViewVisible()) {
                this.messageService.add({
                    severity: 'error', summary: this.translatePipe.transform('EmployeeMessage'),
                    detail: this.translatePipe.transform('donotAccess')
                });
            }
        }
        if (event.srcElement.innerHTML === this.translatePipe.transform('EmployeeOT')) {
            if (!this.checkIsEmployeeOTVisible()) {
                this.messageService.add({
                    severity: 'error', summary: this.translatePipe.transform('EmployeeMessage'),
                    detail: this.translatePipe.transform('donotAccess')
                });
            }
        }
    }
}
