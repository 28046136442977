import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeJobReport } from '../model/employee-job-report.model';
import * as moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class EmployeeJobReportService extends AbstractService<EmployeeJobReport> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.EMPLOYEE_JOB_REPORT, '');
  }

  search(options: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8004/api/v2/employee/employee-job-report/search`, options).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  createJobReport(entity: any): Observable<any> {
    // entity.reportDate = moment(entity.reportDate).utc(true).toDate();
    // return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  updateJobReportNew(entity: any): Observable<any> {
    // entity.reportDate = moment(entity.reportDate).utc(true).toDate();
    // return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report/update-job-report', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2 + 'update-job-report', entity).pipe(map(res => res));
  }

  updateJobReport(entity: any): Observable<any> {
    // return this._http.put<any>(`http://localhost:8004/api/v2/employee/employee-job-report`, entity).pipe(map(res => res));
    return this._http.put<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  getEmployeeJobReportById(id: any) {
    // return this._http.get<any>(`http://localhost:8004/api/v2/employee/employee-job-report/${id}`).pipe(map(res => res));
    return this._http.get<any>(this.baseUrlV2 + `${id}`).pipe(map(res => res));
  }

  getListHour(entity: any) {
    // return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report/get-list-hour', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2 + 'get-list-hour', entity).pipe(map(res => res));
  }

  getJobReportExistedToDay(entity: any) {
    //return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report/get-job-report-existed-today', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2 + 'get-job-report-existed-today', entity).pipe(map(res => res));
  }

  getMissedJobReport(entity: any) {
    // return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report/get-job-report-missed-today', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2 + 'get-job-report-missed-today', entity).pipe(map(res => res));
  }

  getJobReportNoteByDate(entity: any) {
    // return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report/get-job-report-note-by-date', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2 + 'get-job-report-note-by-date', entity).pipe(map(res => res));
  }

  getHistory(options: any) {
      return this._http.post<any>(this.baseUrlV2 +  "history", options).pipe(map(rsp => rsp));
  }

  adminAddReply(entity: any) {
      //return this._http.post<any>('http://localhost:8004/api/v2/employee/employee-job-report/get-job-report-existed-today', entity).pipe(map(res => res));
      return this._http.post<any>(this.baseUrlV2 + 'admin-reply', entity).pipe(map(res => res));
  }

  getRepliesByJobId(id: any) {
    // return this._http.get<any>(`http://localhost:8004/api/v2/employee/employee-job-report/reply/${id}`).pipe(map(res => res));
    return this._http.get<any>(this.baseUrlV2 + `reply/${id}`).pipe(map(res => res));
  }
}
