<p-toast></p-toast>
<div class="spinner-overlay" *ngIf="loading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>


<div class="card p-0 add-shadow login mx-auto">
	<div class="card-header btn-primary px-3" style="
    height: 45px;
    display: flex;
    align-items: center;">
		<span><i class="fa-solid fa-user-lock mr-2"></i>{{'Reset Password' | translate}}</span>
	</div>
	<div class="ui-g p-4 pb-0" style="text-align: center; display: inline;">
		<img src="../../../assets/images/logo.png" style="width: 135px;height: auto;"><br>
		<h1 class="m-0" translate>Create New Password</h1>
	</div>
	<div class="ui-g p-0 px-4 pb-4">
		<div *ngIf="successMsg || message" class="ui-g-12">
			<div *ngIf="successMsg" class="alert alert-success alert-dismissible fade show" role="alert">
				<span><i class="fa-solid fa-circle-check mr-2"></i>{{successMsg}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div *ngIf="message" class="alert alert-warning alert-dismissible fade show" role="alert">
				<span><i class="fa-solid fa-triangle-exclamation mr-2"></i> {{message}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
			<label for="password" translate>password</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<input pInputText *ngIf="!isShowPassword" type="password" id="password" [(ngModel)]="resetPassworReq.password" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
				(keypress)="checkInput($event)" autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
				<input pInputText *ngIf="isShowPassword" type="text" id="password" [(ngModel)]="resetPassworReq.password" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
				(keypress)="checkInput($event)" autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
				<div class="input-group-append">
					<button *ngIf="!isShowPassword" (click)="showPassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-eye text-primary"></i>
					</button>
					<button *ngIf="isShowPassword" (click)="showPassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-eye-slash text-danger"></i>
					</button>
				</div>
			</div>
			<span *ngIf="maxLimit" class="text-danger" style="font-size: 12px">Passwords can't exceed 30 characters.</span>
		</div>
		<div class="ui-g-12 ui-sm-12">
			<label for="re-password" translate>Repeat Password</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-lock"></i>
					</button>
				</div>
				<input pInputText *ngIf="!isShowRePassword" type="password" id="re-password" [(ngModel)]="resetPassworReq.rePassword" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
				(keypress)="checkInput($event)" autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
				<input pInputText *ngIf="isShowRePassword" type="text" id="re-password" [(ngModel)]="resetPassworReq.rePassword" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
				(keypress)="checkInput($event)" autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>		
				<div class="input-group-append">
					<button *ngIf="!isShowRePassword" (click)="showRePassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-eye text-primary"></i>
					</button>
					<button *ngIf="isShowRePassword" (click)="showRePassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-eye-slash text-danger"></i>
					</button>
				</div>
			</div>
		</div>		
		<div class="ui-g-12">
			<label>Enter Capcha Text</label>
			<div class="captcha-actions">
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-keyboard"></i>
						</button>
					</div>
					<input pInputText type="text" [(ngModel)]="captch_input"/>
					<div class="input-group-append">
						<button class="btn btn-white" (click)="playCaptcha()" pTooltip="Play Captcha" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-volume-high text-primary"></i>
						</button>
						<button class="btn btn-white" (click)="createCaptcha()" pTooltip="Get New Captcha"  id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-rotate text-orange"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="ui-g-12 text-center pt-4">
			<div class="ui-float-label {{ configCaptcha.cssClass}}">
				<input type="text" [(ngModel)]="captch_input" hidden/>
				<canvas id="captcahCanvas" width="310" height="80"></canvas>
			</div>
		</div>
	</div>

	<div class="card-footer p-4 d-flex align-items-center justify-content-between">
		<button type="button" class="btn btn-danger" (click)="backToLogin()"><i class="fa-solid fa-xmark mr-2"></i>Cancel</button>
		<button type="button" (click)="resetPassword()" [disabled]="!resetPassworReq.password || !resetPassworReq.rePassword || resetPassworReq.password.trim() == '' || resetPassworReq.rePassword.trim() == '' " class="btn btn-primary mx-1"><span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></span></button>		
	</div>
</div>

