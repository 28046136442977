import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { Constants } from '../../shared/model/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimesheetHistoryService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.TIMESHEET_HISTORY, '');
  }

  save(payload: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}`, payload).pipe(map(res => res));
  }

  search(payload: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/search`, payload).pipe(map(res => res));
  }

  findById(id: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}/${id}`).pipe(map(res => res));
  }
}