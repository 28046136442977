import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import {DatePipe} from "@angular/common";
import {CompanySettingHistoryService} from '../../service/company-setting-history.service';
import {CompanySettingHistory} from '../../model/company-setting-history.model';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as MOMENT from 'moment';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';

@Component({
    selector: 'app-company-setting-history',
    templateUrl: './company-setting-history.component.html',
    styleUrls: ['./company-setting-history.component.scss'],
    providers: [CompanySettingHistoryService]
})
export class CompanySettingHistoryComponent implements OnInit {
    @Input() companySettingId: number;
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    size: number;
    page: number;
    rangeDates: any;
    rows: number;
    fromDate;
    toDate;
    historyFor: any = {};
    clientHistory: CompanySettingHistory[];
    cols: any[] = [
        { field: 'updatedDate', label: 'Updated'},
        { field: 'userName', label: 'User' },
        { field: 'oldValue', label: 'Old Value' },
        { field: 'newValue', label: 'New Value' },
        { field: 'action', label: 'Action' }
    ];
    first = 0;
    maxDate: any;
    fieldNames: any[];
    selectedField = 'Field Name';
    @Output() onHideHistory: EventEmitter<any> = new EventEmitter();
    @Input() historyType: string;
    loading = false;
    totalRecords = 0;
    fileUrl: any;
    showViewFileDialog = false;
    fieldNamesForNotification = [
        { label: 'Email Notifications', value: 'employeeMissingPunchNotificationEmail' },
        { label: 'SMS Notifications', value: 'smsNumber' },
        { label: 'Send At', value: 'sendAt' },
        { label: 'Add Support email', value: 'supportEmail' }
    ];
    fieldNamesForPlanAndPrice = [
        { label: 'Credits', value: 'credits' },
        { label: 'Auto Call Minutes', value: 'autoCallMinutes' },
        { label: 'Assistance Hours', value: 'assistanceHours' }
    ];
    fieldNamesForMisc = [
        { label: 'Mobile visit verification', value: 'toleranceRange' },
        { label: 'Update Tracking Every', value: 'updateTrackingEveryStr' },
        { label: 'Stationary Time - Alert After', value: 'sendAlertStr' },
        { label: 'Overtime validation', value: 'promptPasswordAt' }
    ];

    fieldNamesPunchHistory = [
        { label: 'Enable', value: 'sendActive' },
        { label: 'Send To', value: 'emailSendTo' },
        { label: 'Finance Email', value: 'financeEmail' },
        { label: 'On', value: 'dayOfWeek' },
        { label: 'At', value: 'atHours' }
    ];
    fieldNamesSMS = [
        { label: 'Available Credits', value: 'smsQuota' },
        { label: 'Reply SMS', value: 'replySms' },
        { label: 'Cycle Period', value: 'smsQuotaFrequency' },
        { label: 'Price', value: 'smsPrice' },
        // { label: 'Usage Cost', value: 'smsUsageCost' },
        { label: 'When Quota Reached', value: 'smsAfterQuota' },
        { label: 'Outgoing Messages', value: 'smsCount' },
        { label: 'Bulk Message Limit', value: 'bulkMessageLimit' },
        { label: 'SMS Credits used', value: 'smsCreditsUsed' },
        { label: 'MMS Credits used', value: 'mmsCreditsUsed' },
        // { label: 'Overage Pricing', value: 'smsAvailableAfterQuota' }
    ];
    fieldNamesMMS = [
        { label: 'Quota', value: 'mmsQuota' },
        // { label: 'Count', value: 'mmsCount' },
        { label: 'Cycle Period', value: 'mmsQuotaFrequency' },
        { label: 'Price', value: 'mmsPrice' },
        // { label: 'Usage Cost', value: 'mmsUsageCost' },
        { label: 'When Quota Reached', value: 'mmsAfterQuota' },
        { label: 'Outgoing Messages', value: 'mmsAfterQuota' },
        // { label: 'Overage Pricing', value: 'mmsAvailableAfterQuota' }
    ];
    fieldNamesCompanyLogo = [
      { label: 'Logo', value: 'logoId' },
      { label: 'Badge', value: 'badge' },
    ];

    fieldNamesCreditSetup = [
      { label: 'Dollar Value', value: 'creditDollarValue' },
      { label: 'Credit Value', value: 'creditValue' },
      { label: 'SMS/Credit', value: 'creditSmsValue' },
      { label: 'MMS/Credit', value: 'creditMmsValue' }
    ];

    constructor(
        private datePipe: DatePipe,
        private breadcrumbService: BreadcrumbService,
        private agencySettingService: CompanySettingHistoryService,
        private documentService: DocumentsService,
    ) {
        this.maxDate = new Date();
    }

    ngOnInit() {
        this.breadcrumbService.setItems([
            { label: 'Agencies', routerLink: ['/app/agency/']},
            { label: 'Agency Settings', routerLink: ['/app/agency/settings/:id']}
        ]);
        this.fieldNames = this.fieldNamesForMisc;
        if (this.historyType === 'misc') {
            this.fieldNames = this.fieldNamesForMisc;
        } else if(this.historyType === 'punch_history'){
            this.fieldNames = this.fieldNamesPunchHistory;
        } else if(this.historyType === 'sms'){
            this.fieldNames = this.fieldNamesSMS;
        } else if(this.historyType === 'mms'){
            this.fieldNames = this.fieldNamesMMS;
        } else if(this.historyType === 'companyLogo'){
          this.fieldNames = this.fieldNamesCompanyLogo;
        } else if(this.historyType === 'plan_history'){
            this.fieldNames = this.fieldNamesForPlanAndPrice;
        } else if(this.historyType === 'credit_setup'){
          this.fieldNames = this.fieldNamesCreditSetup;
        } else {
            this.fieldNames = this.fieldNamesForNotification;
        }
        this.loadDataSource();
      }

      selectField(event) {
        this.historyFor = this.fieldNames.find(field => event.value === field.value)
       }
      loadDataSource(event?: any) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        const options: any = {
          size: this.size,
          page: this.page,
          fieldName: this.selectedField
        };

          options.fromDate = this.fromDate? new Date(this.fromDate).getTime() : null;
          options.toDate = this.toDate? new Date(this.toDate).getTime() : null;

        if (this.companySettingId) options.companySettingId = this.companySettingId;
        if (!options.companySettingId || !this.selectedField) {
          this.dataSourceSubject.next([]);
          return;
        }
         this.loading = true;
        this.agencySettingService.getClientHistoryPage(options).subscribe(
          (data: any) => {
            this.dataSourceSubject.next(data.data);
            this.loading = false;
          }
        );
      }

      reset(){
        this.rangeDates = null;
        this.selectedField = null;
        this.fromDate = null;
        this.toDate = null;
        this.loadDataSource();
      }

      checkField(value, type){
          if(type === 'updateTrackingEveryStr' || type === 'sendAlertStr' ){
            return MOMENT.utc(Number(value)).format('HH:mm');
          }
          return value;
      }

      onViewDocument(data) {
        if (data) {
          this.documentService.getUploadedFile(data).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
              if (resObj.data.fileType === 'pdf') {
                window.open(resObj.data.fileUrl);
              } else {
                this.fileUrl = resObj.data.fileUrl;
                this.showViewFileDialog = true;
              }
            }
          }, error => {
            // error
          });
        }
      }

}
