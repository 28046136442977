import * as moment from 'moment';

type AdjustType = 'workStart' | 'workEnd' | 'breakStart' | 'breakEnd';

export class DailyTimesheet {
  date: moment.Moment;
  rate: number;
  worked = true;
  workStart: moment.Moment;
  workStartOrigin: moment.Moment;
  workEnd: moment.Moment;
  workEndOrigin: moment.Moment;
  breakStart: moment.Moment;
  breakStartOrigin: moment.Moment;
  breakEnd: moment.Moment;
  breakEndOrigin: moment.Moment;

  workEnd2ndDay: boolean;
  breakStart2ndDay: boolean;
  breakEnd2ndDay: boolean;
  stoppedAdjust = false;

  constructor(date: moment.Moment, rate: number) {
    this.date = date.clone();
    this.rate = rate;
  }

  get dateFormatted(): string {
    return this.date.format('YYYY-MM-DD');
  }

  get month(): string {
    return this.date.format('MMM YYYY');
  }

  convertFromShift(shift: any): void {
    this.stoppedAdjust = shift.lockTime;
    if (shift.workStart) {
      this.workStart = moment(shift.workStart).clone();
      this.workStartOrigin = moment(shift.workStart).clone();
    }
    if (shift.breakStart) {
      this.breakStart = moment(shift.breakStart).clone();
      this.breakStartOrigin = moment(shift.breakStart).clone();
      this.breakStart2ndDay = shift.breakStart2ndDay;
    }
    if (shift.breakEnd) {
      this.breakEnd = moment(shift.breakEnd).clone();
      this.breakEndOrigin = moment(shift.breakEnd).clone();
      this.breakEnd2ndDay = shift.breakEnd2ndDay;
    }
    if (shift.workEnd) {
      this.workEnd = moment(shift.workEnd).clone();
      this.workEndOrigin = moment(shift.workEnd).clone();
      this.workEnd2ndDay = shift.workEnd2ndDay;
    }
  }

  adjust(type: AdjustType, adjustInMinutes: number): void {
    if (type === 'workStart' && this.workStart) {
      this.workStart = this.workStart.clone().add(adjustInMinutes, 'minutes');
    }
    if (type === 'workEnd' && this.workEnd) {
      this.workEnd = this.workEnd.clone().add(adjustInMinutes, 'minutes');
    }
    if (type === 'breakStart' && this.breakStart) {
      this.breakStart = this.breakStart.clone().add(adjustInMinutes, 'minutes');
    }
    if (type === 'breakEnd' && this.breakEnd) {
      this.breakEnd = this.breakEnd.clone().add(adjustInMinutes, 'minutes');
    }
  }

  revert(type: AdjustType): void {
    if (type === 'workStart' && this.workStartOrigin) {
      this.workStart = this.workStartOrigin.clone();
    }
    if (type === 'workEnd' && this.workEndOrigin) {
      this.workEnd = this.workEndOrigin.clone();
    }
    if (type === 'breakStart' && this.breakStartOrigin) {
      this.breakStart = this.breakStartOrigin.clone();
    }
    if (type === 'breakEnd' && this.breakEndOrigin) {
      this.breakEnd = this.breakEndOrigin.clone();
    }
  }

  useAdjust(type: AdjustType): void {
    if (type === 'workStart' && this.workStart) {
      this.workStartOrigin = this.workStart.clone();
    }
    if (type === 'workEnd' && this.workEnd) {
      this.workEndOrigin = this.workEnd.clone();
    }
    if (type === 'breakStart' && this.breakStart) {
      this.breakStartOrigin = this.breakStart.clone();
    }
    if (type === 'breakEnd' && this.breakEnd) {
      this.breakEndOrigin = this.breakEnd.clone();
    }
  }

  ableToAdjust(): boolean {
    if (this.worked && this.hasBreak() && this.breakDuration() * 60 >= 30) {
      return !this.stoppedAdjust;
    }
    return false
  }

  hasBreak(): boolean {
    if (this.breakStart && this.breakEnd) {
      return true;
    }
    return false;
  }

  workDuration(): number {
    if (!this.worked) {
      return 0;
    }
    if (this.workStart && this.workEnd) {
      const start = this.workStart.toDate().getTime();
      let end = this.workEnd.toDate().getTime();
      if (this.workEnd2ndDay) {
        end += 24 * 60 * 60 * 1000;
      }
      const duration = (end - start) / (1000 * 60 * 60);
      return duration > 0 ? duration : 0;
    }
    return 0;
  }

  breakDuration(): number {
    if (!this.worked) {
      return 0;
    }
    if (this.breakStart && this.breakEnd) {
      let start = this.breakStart.toDate().getTime();
      if (this.breakStart2ndDay) {
        start += 24 * 60 * 60 * 1000;
      }
      let end = this.breakEnd.toDate().getTime();
      if (this.breakEnd2ndDay) {
        end += 24 * 60 * 60 * 1000;
      }
      const duration = (end - start) / (1000 * 60 * 60);
      return duration > 0 ? duration : 0;
    }
    return 0;
  }

  workStartBreakDuration(): number {
    if (!this.worked) {
      return 0;
    }
    if (this.workStart && this.breakStart) {
      let start = this.workStart.toDate().getTime();
      let end = this.breakStart.toDate().getTime();
      if (this.breakStart2ndDay) {
        end += 24 * 60 * 60 * 1000;
      }
      const duration = (end - start) / (1000 * 60 * 60);
      return duration > 0 ? duration : 0;
    }
    return 0;
  }

  breakWorkEndDuration(): number {
    if (!this.worked) {
      return 0;
    }
    if (this.breakEnd && this.workEnd) {
      let start = this.breakEnd.toDate().getTime();
      if (this.breakEnd2ndDay) {
        start += 24 * 60 * 60 * 1000;
      }
      let end = this.workEnd.toDate().getTime();
      if (this.workEnd2ndDay) {
        end += 24 * 60 * 60 * 1000;
      }
      const duration = (end - start) / (1000 * 60 * 60);
      return duration > 0 ? duration : 0;
    }
    return 0;
  }

  isOver10Hours(): boolean {
    return this.workDuration() >= 10;
  }

  totalWorkedHours(): number {
    return this.workDuration() - this.breakDuration();
  }
}

export class WeeklyTimesheet {
  days: DailyTimesheet[] = [];
  hourlyRate: number;
  targetAmount: number;

  constructor(days, hourlyRate: number) {
    this.days = days;
    this.hourlyRate = hourlyRate;
  }

  get overtimeHourlyRate() {
    return this.hourlyRate * 1.5;
  }

  numberOfSpreadOfHours(): number {
    let count = 0;
    this.days.forEach(day => {
      if (day.isOver10Hours()) {
        count++;
      }
    });
    return count;
  }

  totalWorkedHours(): number {
    let total = 0;
    this.days.forEach(day => {
      total += day.totalWorkedHours();
    });
    return total;
  }

  totalAmount(): number {
    if (this.totalWorkedHours() > 40) {
      return ((40 + this.numberOfSpreadOfHours()) * this.hourlyRate) + ((this.totalWorkedHours() - 40) * this.overtimeHourlyRate);
    }
    return (this.totalWorkedHours() + this.numberOfSpreadOfHours()) * this.hourlyRate;
  }
}

export class MonthlyTimesheet {
  month: string;
  targetAmount: number;
  weeks: WeeklyTimesheet[] = [];
  stoppedAdjust = false;
  originAmount;

  constructor(month: string) {
    this.month = month;
    this.originAmount = this.totalAmount();
  }

  ableToAdjust(): boolean {
    const targetAmount = this.roundNumber(Number(this.targetAmount));
    let diff = this.originAmount - targetAmount;
    let currentAmount = this.roundNumber(this.totalAmount());
    if (this.stoppedAdjust || (diff < 0 && currentAmount < targetAmount) || (diff > 0 && currentAmount > targetAmount)) {
      return false;
    }
    for (let week of this.weeks) {
      for (let day of week.days) {
        if (day.ableToAdjust()) {
          return true;
        }
      }
    }
  }

  totalAmount() {
    let total = 0;
    for (let week of this.weeks) {
      const workedDays = week.days.filter(day => day.worked);
      if (workedDays.length === 0) {
        continue;
      }
      const daysWithinMonth = workedDays.filter(day => day.month === this.month);
      if (daysWithinMonth.length === 0) {
        continue;
      }
      total += week.totalAmount() * (daysWithinMonth.length / workedDays.length);
    }
    return total;
  }

  adjust(type: AdjustType) {
    if (this.stoppedAdjust) {
      return;
    }

    const targetAmount = this.roundNumber(Number(this.targetAmount));
    let diff = this.originAmount - targetAmount;
    let currentAmount = this.roundNumber(this.totalAmount());
    if ((diff < 0 && currentAmount < targetAmount) || (diff > 0 && currentAmount > targetAmount)) {
      this.stoppedAdjust = true;
      return;
    }
    diff = targetAmount - currentAmount;
    for (let i = this.weeks.length - 1; i >= 0; i--) {
      const week = this.weeks[i];
      const workedDays = week.days.filter(day => day.worked && day.month === this.month);
      if (workedDays.length === 0) {
        continue;
      }
      for (let j = workedDays.length - 1; j >= 0; j--) {
        const day = workedDays[j];
        if (!day.ableToAdjust()) {
          continue;
        }
        const oldValue = day[type + 'Origin'].clone();
        currentAmount = this.roundNumber(this.totalAmount());
        let existAdjustAmount = false;
        for (let adjustAmount of [30, 15, 10, 5]) {
          if (existAdjustAmount) {
            continue;
          }
          let adjustInMinutes = adjustAmount;
          if (diff > 0) {
            if (type === 'breakEnd' || type === 'workStart') {
              adjustInMinutes = -adjustInMinutes;
            }
          } else if (diff < 0) {
            if (type === 'breakStart' || type === 'workEnd') {
              adjustInMinutes = -adjustInMinutes;
            }
          }
          day.adjust(type, adjustInMinutes);
          if (day.breakDuration() * 60 < 30) {
            day.revert(type);
            continue;
          }
          if (day.breakStart.isBefore(day.workStart) || day.breakEnd.isBefore(day.breakStart) || day.workEnd.isBefore(day.breakEnd)) {
            day.revert(type);
            continue;
          }
          if (day.workStartBreakDuration() * 60 < 10 || day.breakWorkEndDuration() * 60 < 10) {
            day.revert(type);
            continue;
          }
          // if (Math.abs(originAmount - this.totalAmount()) > 5) {
          //   day.revert(type);
          //   continue;
          // }
          const newDiff = targetAmount - this.roundNumber(this.totalAmount());
          if (diff * newDiff < 0 && Math.abs(newDiff) > 1) {
            day.revert(type);
            continue;
          }
          day.useAdjust(type);
          existAdjustAmount = true;
          console.log("Adjusted: ", [type, day.dateFormatted, oldValue.format('HH:mm'), day[type].format('HH:mm')]);
        }
        if (!existAdjustAmount) {
          day.stoppedAdjust = true;
          console.log("Stopped: ", [type, day.dateFormatted, oldValue.format('HH:mm'), day[type].format('HH:mm')]);
        }
      }
    }
    console.log("adjustedMonthTotalAmount: ", this.totalAmount());
  }

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }
}