import { SEARCH_TASK_DATE_TYPE, TICKET_TYPE } from './../../service/task-management.service';
import { ITaskSearchTerm } from './../../model/task-model';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { CommonCode } from '../../model/type-code.enum';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-task-management-top-bar',
  templateUrl: './task-management-top-bar.component.html',
  styleUrls: ['./task-management-top-bar.component.scss']
})
export class TaskManagementTopBarComponent implements OnInit, OnChanges {
  @Input() boards: any;
  @Input() currentBoardId: any;
  @Input() showBackground: boolean;
  @Input() showMenu: boolean;
  @Input() activityData: any[] = [];
  @Input() viewMore: any[] = [];
  @Input() isLoadingActivity: boolean;
  @Output() onAdd = new EventEmitter();
  @Output() onOpenBackground = new EventEmitter();
  @Output() loadActivityEvent = new EventEmitter();
  @Output() onShowTaskUrl = new EventEmitter();
  @Input() childTemplate: TemplateRef<any>;
  @Input() labels: any[];
  @Input() members: any[];
  @Input() allowUnhide: boolean;
  @Input() loadProject: boolean;
  @Input() isAdmin: boolean;
  @Output() onSearch = new EventEmitter();
  @Output() onUnhide = new EventEmitter();
  commonCode = CommonCode;
  searchTermBoard: String = '';
  searchTermTask = '';
  showResultSearchTask: Boolean = false;
  sidebarTab: 'background' | 'search' | 'description' | '' = '';
  seachTerm: ITaskSearchTerm = {
    name: '',
    labels: [],
    members: [],
    date: 0,
    ticketTypes: []
  };
  noMember: any = {
    id: 0,
    name: '',
    avatar: 'assets/layout/images/default-avatar.png'
  };
  searchByDateOptions = [
    {
      title: 'Due in the next day',
      type: SEARCH_TASK_DATE_TYPE.NEXT_DAY
    },
    {
      title: 'Due in the next week',
      type: SEARCH_TASK_DATE_TYPE.NEXT_WEEK
    },
    {
      title: 'Due in the next month',
      type: SEARCH_TASK_DATE_TYPE.NEXT_MONTH
    },
    {
      title: 'Overdue',
      type: SEARCH_TASK_DATE_TYPE.OVERDUE
    },
    {
      title: 'Has no due date',
      type: SEARCH_TASK_DATE_TYPE.NO_DUE
    }
  ];

  TICKET_TYPE = TICKET_TYPE;

  private inputChanged: Subject<string> = new Subject<string>();
  private inputChangedSubscription: Subscription;
  filteredTaskBoard = [];

  constructor(private router: Router,
    private taskManagementService: TaskManagementService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.inputChangedSubscription = this.inputChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.onSerchTask();
    });
  }
  ngOnChanges() {
  }

  ngOnDestroy() {
    if (this.inputChangedSubscription) {
      this.inputChangedSubscription.unsubscribe();
    }
  }
  gotoHome() {
    this.router.navigate(['/app/tm']);
  }
  gotoBoard(p) {
    this.router.navigate(['/app/tm', this.taskManagementService.encodeId(p.id)]);
  }

  onSearchInputChange(event) {
    const newValue = event.target.value;
    this.inputChanged.next(newValue);
  }

  onSerchTask() {
    /*
    this.boards.forEach(board => {
      const buckets = board?.buckets || [];
      let matchingTasks = [];
      buckets.forEach(bucket => {
        if (isNaN(parseInt(this.searchTermTask))) {
          matchingTasks = matchingTasks.concat(bucket.tasks.filter(x =>
            x.name.toLowerCase().includes(this.searchTermTask.toLowerCase())));
        } else {
          matchingTasks = matchingTasks.concat(bucket.tasks.filter(x =>
            x.internalId === parseInt(this.searchTermTask)));
        }
      });

      if (!matchingTasks.length) {
        buckets.forEach(bucket => {
          bucket.tasks.forEach(x => {
            const tasks = [];
            x.assignedUsers.forEach(u => {
              if (u.name.toLowerCase().includes(this.searchTermTask.toLowerCase())) {
                tasks.push(x);
              }
            });
            matchingTasks = matchingTasks.concat(tasks);
          });
        });
      }
      if (!matchingTasks.length) {
        buckets.forEach(bucket => {
          if (bucket.name.toLowerCase().includes(this.searchTermTask.toLowerCase())) {
            matchingTasks = matchingTasks.concat(bucket.tasks);
          }
        });
      }
      board.matchingTask = matchingTasks;
    });
    */
    if (this.currentBoardId) {
        this.boards.forEach(board => {
            const buckets = board?.buckets || [];
            let matchingTasks = [];
            buckets.forEach(bucket => {
                if (isNaN(parseInt(this.searchTermTask))) {
                    matchingTasks = matchingTasks.concat(bucket.tasks.filter(x =>
                        x.name.toLowerCase().includes(this.searchTermTask.toLowerCase())));
                } else {
                    matchingTasks = matchingTasks.concat(bucket.tasks.filter(x =>
                        x.internalId === parseInt(this.searchTermTask)));
                }
            });
            board.matchingTask = matchingTasks;
        });
      //const matchBoards = this.boards.filter(b => b.id === Number(this.currentBoardId));
      //const nonMatchBoards = this.boards.filter(b => b.id !== Number(this.currentBoardId));
      //this.filteredTaskBoard = [...matchBoards, ...nonMatchBoards];
      this.filteredTaskBoard = this.boards.filter(b => b.id === Number(this.currentBoardId));
      if (this.filteredTaskBoard && this.filteredTaskBoard.length && this.filteredTaskBoard[0].matchingTask && this.filteredTaskBoard[0].matchingTask.length) {
          this.filteredTaskBoard[0].matchingTask = this.filteredTaskBoard[0].matchingTask.sort((a, b) => {
                if (a.createdAt < b.createdAt) {
                  return 1;
                } else if (a.createdAt > b.createdAt) {
                  return -1;
                } else {
                  return 0;
                }
          });
      }
      // filteredTaskBoard = filteredTaskBoard.sort((a, b) => {
      //   if (a.id === this.currentBoardId && b.id !== this.currentBoardId) {
      //     return -1;
      //   } else if (a.id !== this.currentBoardId && b.id === this.currentBoardId) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
    } else {
        this.boards.forEach(board => {
            const buckets = board?.buckets || [];
            let matchingTasks = [];
            buckets.forEach(bucket => {
                if (isNaN(parseInt(this.searchTermTask))) {
                    matchingTasks = matchingTasks.concat(bucket.tasks.filter(x =>
                        x.name.toLowerCase().includes(this.searchTermTask.toLowerCase())));
                } else {
                    matchingTasks = matchingTasks.concat(bucket.tasks.filter(x =>
                        x.internalId === parseInt(this.searchTermTask)));
                }
            });

            if (!matchingTasks.length) {
                buckets.forEach(bucket => {
                    bucket.tasks.forEach(x => {
                        const tasks = [];
                        x.assignedUsers.forEach(u => {
                            if (u.name.toLowerCase().includes(this.searchTermTask.toLowerCase())) {
                                tasks.push(x);
                            }
                        });
                        matchingTasks = matchingTasks.concat(tasks);
                    });
                });
            }
            if (!matchingTasks.length) {
                buckets.forEach(bucket => {
                    if (bucket.name.toLowerCase().includes(this.searchTermTask.toLowerCase())) {
                        matchingTasks = matchingTasks.concat(bucket.tasks);
                    }
                });
            }
            board.matchingTask = matchingTasks;
        });
      this.filteredTaskBoard = this.boards;
    }
    this.showResultSearchTask = this.boards.find(x => (x.matchingTask && x.matchingTask.length > 0));
  }

  addAction() {
    this.onAdd.emit();
  }
  openTask(task, board) {
    const slug = `${task.internalId}-${task.name.replace(/ /g, '-')}`;
    const queryParams = { t: slug };
    this.router.navigate(['/app/tm',
      this.taskManagementService.encodeId(board.id)], {
      queryParams
    });
  }
  openPickBackground(event) {
    this.onOpenBackground.emit(event);
  }

  onLoadActivityEvent(event) {
    this.loadActivityEvent.emit(event);
  }

  closeTimeSelector(event, element) {
    element.hide(event);
  }

  showTaskUrl(event) {
    this.onShowTaskUrl.emit(event);
  }
  changeSidebarTab(tab) {
    this.sidebarTab = tab;
  }
  selectLabel(labelId) {
    const idx = this.seachTerm.labels.findIndex(x => x === labelId);
    if (idx > -1) {
      this.seachTerm.labels.splice(idx, 1);
    } else {
      this.seachTerm.labels.push(labelId);
    }
    this.onSearch.emit(this.seachTerm);
  }
  selectMember(memberId) {
    const idx = this.seachTerm.members.findIndex(x => x === memberId);
    if (idx > -1) {
      this.seachTerm.members.splice(idx, 1);
    } else {
      this.seachTerm.members.push(memberId);
    }
    this.onSearch.emit(this.seachTerm);
  }
  selectSearchDate(type) {
    if (this.seachTerm.date === type) {
      this.seachTerm.date = 0;
    } else {
      this.seachTerm.date = type;
    }
    this.onSearch.emit(this.seachTerm);
  }

  selectTicketType(type) {
    const idx = this.seachTerm.ticketTypes.findIndex(x => x === type);
    if (idx > -1) {
      this.seachTerm.ticketTypes.splice(idx, 1);
    } else {
      this.seachTerm.ticketTypes.push(type);
    }
    this.onSearch.emit(this.seachTerm);
  }

  onSearchChange() {
    this.labels.forEach(l => {
      l.hide = !l.name.toLocaleLowerCase().includes(this.seachTerm.name.toLocaleLowerCase());
    });
    this.members.forEach(m => {
      m.hide = !m.name.toLocaleLowerCase().includes(this.seachTerm.name.toLocaleLowerCase());
    });
    this.onSearch.emit(this.seachTerm);
  }

  unhideList() {
    this.onUnhide.emit(true);
  }

  removeTermTask() {
  this.searchTermTask = '';
  }
}
