import { Component, Input, OnInit } from "@angular/core";
import { MessageService } from "primeng";
import * as moment from 'moment';
import { DatePipe } from "@angular/common";
import { DocumentsServicev2 } from './../../../shared/service/documents-upload/document.v2.service';
import { forkJoin } from "rxjs";
import { FileUtility } from './../../../shared/utility/file.utility';
import { DailyTimesheet, WeeklyTimesheet, MonthlyTimesheet } from './../../../working-time-calculator/model/timesheet.model';
import { TimesheetHistoryService } from './../../../working-time-calculator/service/timesheet-history.service';
import { AuthService } from "../../../shared/service/auth/auth.service";

@Component({
  selector: 'app-timesheet-generate',
  templateUrl: './timesheet-generate.component.html',
  styleUrls: ['./timesheet-generate.component.scss']
})
export class TimesheetGenerateComponent implements OnInit {

  @Input()
  dataForm: any = {
    exception: {
      otherDay: null,
      otherDayStr: '',
      otherDays: []
    }
  };

  languages = [
    { label: 'language_vietnam', value: 'vi', flag: 'vn' },
    { label: 'language_chinese_simplified', value: 'zh-cn', flag: 'cn' },
    { label: 'language_chinese_traditional', value: 'zh-tw', flag: 'cn' },
    { label: 'language_spanish', value: 'es', flag: 'es' },
    { label: 'language_france', value: 'fr', flag: 'fr' },
    { label: 'language_arabic', value: 'ar', flag: 'ar' },
    { label: 'language_japanese', value: 'ja', flag: 'jp' },
    { label: 'language_korean', value: 'ko', flag: 'kr' },
    { label: 'language_russia', value: 'ru', flag: 'ru' },
    { label: 'language_hindi', value: 'hi', flag: 'in' }
  ];

  federalHolidays: any[] = [];

  workedDayCheckedAll = false;



  calendarWeeklyPopup = false;
  calendarMonthlyPopup = false;
  showDialogException = false;

  employeeList = [];

  generating = false;

  constructor(
    private documentsServicev2: DocumentsServicev2,
    private timesheetHistoryService: TimesheetHistoryService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {
    const day = new Date();
    const year = day.getFullYear();

    this.federalHolidays = [
      { label: 'New Year Day(January 1)', value: '01/01/' + year },
      { label: 'Birthday of Martin Luther King, Jr.(Third Monday in January)', value: this.getDayOfWeek(year, 1, 3, 1) },
      { label: 'Inauguration Day (January 20, every 4 years following a presidential election)', value: '01/20/' + year },
      { label: 'Washington`s Birthday (Also known as Presidents Day, Third Monday in February)', value: this.getDayOfWeek(year, 2, 3, 1) },
      { label: 'Memorial Day (Last Monday in May)', value: this.getLastMondayOfMonth(year, 5) },
      { label: 'Juneteenth National Independence Day (June 19)', value: '06/19/' + year },
      { label: 'Independence Day (July 4)', value: '07/04/' + year },
      { label: 'Labor Day (First Monday in September)', value: this.getDayOfWeek(year, 9, 1, 1) },
      { label: 'Columbus Day (Second Monday in October)', value: this.getDayOfWeek(year, 10, 2, 1) },
      { label: 'Veterans Day (November 11)', value: '11/11/' + year },
      { label: 'Thanksgiving Day (Fourth Thursday in November)', value: this.getDayOfWeek(year, 11, 4, 4) },
      { label: 'Christmas Day (December 25)', value: '12/25/' + year },
    ];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  generate() {
    if (!this.dataForm.fromDate || !this.dataForm.toDate) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the date range' });
      return;
    }
    if (!this.dataForm.amountEachWeek && !this.dataForm.amountEachMonth && (!this.dataForm.calendarWeekly || !this.dataForm.calendarWeekly.length) && (!this.dataForm.calendarMonthly || !this.dataForm.calendarMonthly.length)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please input the amount' });
      return;
    }
    if (!this.dataForm.hourlyRate) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please input the hourly rate' });
      return;
    }
    if (this.generating) {
      return;
    }
    this.generating = true;

    if (this.dataForm.amountEachWeek) {
      this.dataForm.calendarWeekly = this.generateCalendarWeekly(this.dataForm.fromDate, this.dataForm.toDate);
    } else if (this.dataForm.amountEachMonth) {
      this.dataForm.calendarMonthly = this.generateCalendarMonthly();
    }
    let payload: any = {
      chineseName: this.dataForm.chineseName || '',
      englishName: this.dataForm.englishName || '',
      companyName: this.dataForm.companyName || '',
      hourlyRate: this.dataForm.hourlyRate,
      position: this.dataForm.position,
      startWorkDate: this.dataForm.startWorkDate ? moment(this.dataForm.startWorkDate).format('MMM D, YYYY') : '',
      leftCompanyDate: this.dataForm.leftCompanyDate ? moment(this.dataForm.leftCompanyDate).format('MMM D, YYYY') : '',
      workTimeNote: this.dataForm.workTimeNote ? this.dataForm.workTimeNote.replace(/\n/g, '<br>') : '',
      wagesPaymentNote: this.dataForm.wagesPaymentNote ? this.dataForm.wagesPaymentNote.replace(/\n/g, '<br>') : '',
      lang: this.dataForm.lang || 'en'
    };
    const monthlyTimesheets = this.adjustMonthlyTimesheet(this.dataForm.hourlyRate, moment(this.dataForm.startWorkDate));
    payload = this.getPayload(monthlyTimesheets, payload);
    console.log('payload: ', payload);
    this.documentsServicev2.timesheetGeneratorPdf(payload).subscribe((resExport: any) => {
      this.generating = false;
      const blob = FileUtility.b64toBlob(resExport.data.body, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }, () => this.generating = false);
  }

  getPayload(calculatedMonths: any[], payload: any) {
    const monthlyScheduleList: any[] = [];
    const wagesPaymentList: any[] = [];
    let total = 0;
    for (let calculatedMonth of calculatedMonths) {
      const scheduleList: any[] = [];
      for (let week of calculatedMonth.weeks) {
        scheduleList.push(...this.getScheduleList(calculatedMonth.month, week));
      }
      monthlyScheduleList.push(scheduleList)
      const month = moment(calculatedMonth.month, 'MMM YYYY');
      let start = month.clone().startOf('month');
      if (start.isBefore(this.dataForm.fromDate)) {
        start = moment(this.dataForm.fromDate);
      }
      let end = month.clone().endOf('month');
      if (end.isAfter(this.dataForm.toDate)) {
        end = moment(this.dataForm.toDate);
      }
      const monthAmount = this.roundNumber(calculatedMonth.totalAmount());
      const targetAmount = Number(calculatedMonth.targetAmount);
      // const totalAmount = (targetAmount > monthAmount ? targetAmount : monthAmount);
      total += targetAmount;
      let bonusTips = this.roundNumber(targetAmount - monthAmount);
      const wagesPayment = {
        payPeriod: start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'),
        wages: '$' + monthAmount.toFixed(2),
        bonusTips: '$' + bonusTips.toFixed(2),
        total: '$' + targetAmount.toFixed(2),
        negative: bonusTips < 0
      }
      wagesPaymentList.push(wagesPayment);
    }
    payload.monthlyScheduleList = monthlyScheduleList;
    payload.wagesPaymentList = wagesPaymentList;
    if (25 - wagesPaymentList.length > 0) {
      for (let i = 0; i < 25 - wagesPaymentList.length; i++) {
        wagesPaymentList.push({
          start: null,
          end: null,
          payPeriod: ''
        });
      }
    }
    payload.total = '$' + this.roundNumber(total).toFixed(2);
    return payload;
  }

  adjustMonthlyTimesheet(hourlyRate: number, startWorkDate: moment.Moment) {
    const monthlyTimesheets: MonthlyTimesheet[] = [];
    if (this.isAmountEachWeekValid()) {
      const weeks: WeeklyTimesheet[] = [];
      this.dataForm.calendarWeekly.forEach(week => {
        const calculatedWeek: WeeklyTimesheet = this.getWeeklyTimesheet(startWorkDate, Number(hourlyRate), week.value, week);
        calculatedWeek.targetAmount = Number(week.value);
        weeks.push(calculatedWeek);
      });
      let current = moment(this.dataForm.fromDate).startOf('month');
      while (current.isBefore(this.dataForm.toDate)) {
        const monthlyTimesheet: MonthlyTimesheet = new MonthlyTimesheet(current.format('MMM YYYY'));
        monthlyTimesheet.weeks = weeks.filter(week => week.days.find(day => day.month === current.format('MMM YYYY')));
        let targetAmount = 0;
        for (let week of monthlyTimesheet.weeks) {
          const workedDays = week.days.filter(day => day.worked);
          if (workedDays.length === 0) {
            continue;
          }
          const daysWithinMonth = workedDays.filter(day => day.month === monthlyTimesheet.month);
          if (daysWithinMonth.length === 0) {
            continue;
          }
          targetAmount += week.targetAmount * (daysWithinMonth.length / workedDays.length);
        }
        monthlyTimesheet.targetAmount = targetAmount;
        monthlyTimesheets.push(monthlyTimesheet);
        current.add(1, 'month');
      }
    } else if (this.isAmountEachMonthValid()) {
      this.dataForm.calendarMonthly.forEach(month => {
        const monthlyTimesheet: MonthlyTimesheet = new MonthlyTimesheet(month.month);
        const weeks: WeeklyTimesheet[] = [];
        monthlyTimesheet.weeks = weeks;
        month.weeks.forEach(week => {
          const calculatedWeek: WeeklyTimesheet = this.getWeeklyTimesheet(startWorkDate, Number(hourlyRate), month.value, week);
          weeks.push(calculatedWeek);
        });
        monthlyTimesheet.targetAmount = Number(month.value);
        monthlyTimesheets.push(monthlyTimesheet);
      });
    }

    monthlyTimesheets.forEach(calculatedMonth => {
      if (calculatedMonth.targetAmount && calculatedMonth.targetAmount > 0) {
        let diff = calculatedMonth.totalAmount() - calculatedMonth.targetAmount;
        if (diff > 0) {
          while (calculatedMonth.totalAmount() > calculatedMonth.targetAmount && calculatedMonth.ableToAdjust()) {
            calculatedMonth.adjust('breakStart');
            calculatedMonth.adjust('breakEnd');
          }
        } else {
          while (calculatedMonth.totalAmount() < calculatedMonth.targetAmount && calculatedMonth.ableToAdjust()) {
            calculatedMonth.adjust('breakStart');
            calculatedMonth.adjust('breakEnd');
          }
        }
      }
    });
    console.log('calculatedMonths: ', monthlyTimesheets);
    return monthlyTimesheets;
  }

  getScheduleList(month, week) {
    const scheduleList: any[] = [];
    const dates: any[] = [];
    const workStarts: any = [];
    const breakStarts: any = [];
    const breakEnds: any = [];
    const workEnds: any = [];
    scheduleList.push({
      date: dates,
      workStart: workStarts,
      breakStart: breakStarts,
      breakEnd: breakEnds,
      workEnd: workEnds
    });
    week.days.forEach(d => {
      const date = d.date;
      const dayValue = date.format('MM/DD/YYYY');
      dates.push(dayValue);

      if (!d.worked || d.month !== month) {
        workStarts.push(null);
        breakStarts.push(null);
        breakEnds.push(null);
        workEnds.push(null);
        return;
      }
      if (d.workStart) {
        workStarts.push(moment(d.workStart).format('h:mm A'));
      } else {
        workStarts.push(null);
      }
      if (d.breakStart) {
        breakStarts.push(moment(d.breakStart).format('h:mm A'));
      } else {
        breakStarts.push(null);
      }
      if (d.breakEnd) {
        breakEnds.push(moment(d.breakEnd).format('h:mm A'));
      } else {
        breakEnds.push(null);
      }
      if (d.workEnd) {
        workEnds.push(moment(d.workEnd).format('h:mm A'));
      } else {
        workEnds.push(null);
      }
    });
    return scheduleList;
  }

  getWeeklyTimesheet(startWorkDate: moment.Moment, rate: number, hasValue: boolean, week: any): WeeklyTimesheet {
    const calculatedDays: DailyTimesheet[] = [];
    const calculatedWeek: WeeklyTimesheet = new WeeklyTimesheet(calculatedDays, rate);
    calculatedWeek.days = calculatedDays;
    week.days.forEach(d => {
      const value: DailyTimesheet = new DailyTimesheet(d.date, rate);
      calculatedDays.push(value);
      const date = d.date;
      let isException = false;
      if (this.dataForm.exception) {
        if (this.dataForm.exception.federalHoliday) {
          isException = this.dataForm.exception.federalHoliday.find(holiday => moment(holiday, 'MM/DD/YYYY').isSame(date));
        }
        if (!isException && this.dataForm.exception.otherDayStr) {
          this.dataForm.exception.otherDays = this.dataForm.exception.otherDayStr.split(',').map(day => day.trim());
          isException = this.dataForm.exception.otherDays.find(otherDay => moment(otherDay, 'MM/DD/YYYY').isSame(date));
        }
      }

      const notIsWithinRange: boolean = !d.isWithinRange;
      const isBeforeStartWorkDate: boolean = (startWorkDate && date.isBefore(startWorkDate));
      if (isException
          || !d.isWithinRange
          // || (startWorkDate && date.isBefore(startWorkDate))
          || !hasValue) {
        value.worked = false;
      }
      if (value.worked) {
        let dayOfWeek = date.day();
        dayOfWeek = dayOfWeek - 1;
        if (dayOfWeek < 0) {
          dayOfWeek = 6;
        }
        const workedDay = this.dataForm.workedDays[moment(date).format('YYYY-MM-DD')];
        if (workedDay) {
          value.convertFromShift(workedDay);
        }
      }
    });
    return calculatedWeek;
  }

  isAmountEachWeekValid() {
    return this.dataForm.calendarWeekly && this.dataForm.calendarWeekly.length && this.dataForm.calendarWeekly.find(week => week.value);
  }

  isAmountEachMonthValid() {
    return this.dataForm.calendarMonthly && this.dataForm.calendarMonthly.length && this.dataForm.calendarMonthly.find(month => month.value);
  }

  reset() {
    this.dataForm = {
      workedDays: [],
      exception: {
        otherDay: null,
        otherDayStr: '',
        otherDays: []
      }
    };
    this.dataForm.calendarWeekly = [];
    this.dataForm.calendarMonthly = [];
    this.dataForm.amountEachWeek = null;
    this.dataForm.amountEachMonth = null;
    this.dataForm.employees = [];
    this.dataForm.fromDate = null;
    this.dataForm.toDate = null;
    this.dataForm.companyName = null;
    this.dataForm.chineseName = null;
    this.dataForm.englishName = null;
    this.dataForm.hourlyRate = null;
    this.dataForm.position = null;
    this.dataForm.startWorkDate = null;
    this.dataForm.leftCompanyDate = null;
    this.dataForm.exception = {
      otherDay: null,
      otherDayStr: '',
      otherDays: []
    }
    this.workedDayCheckedAll = false;
  }

  onDateRangeChange() {
    this.dataForm.calendarWeekly = [];
    this.dataForm.calendarMonthly = [];
  }

  onAmountChange(type) {
    if (type === 'week') {
      this.dataForm.amountEachMonth = null;
      this.dataForm.calendarMonthly = [];
      this.dataForm.calendarWeekly = this.generateCalendarWeekly(this.dataForm.fromDate, this.dataForm.toDate);
    }
    if (type === 'month') {
      this.dataForm.amountEachWeek = null;
      this.dataForm.calendarWeekly = [];
      this.dataForm.calendarMonthly = this.generateCalendarMonthly();
    }
  }

  openEditAmountPopup(type) {
    if (!this.dataForm.fromDate || !this.dataForm.toDate) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select the date range' });
      return;
    }
    if (type === 'week') {
      const calendarWeekly = this.generateCalendarWeekly(this.dataForm.fromDate, this.dataForm.toDate);
      if (!this.dataForm.calendarWeekly || !this.dataForm.calendarWeekly.length) {
        this.dataForm.calendarWeekly = calendarWeekly;
      } else {
        calendarWeekly.forEach((week: any) => {
          const exist = this.dataForm.calendarWeekly.find(w => w.key === week.key);
          if (exist) {
            week.tempValue = exist.value;
            week.value = exist.value;
          }
        })
        this.dataForm.calendarWeekly = calendarWeekly;
      }
      this.calendarWeeklyPopup = true;
    }
    if (type === 'month') {
      const calendarMonthly = this.generateCalendarMonthly();
      if (!this.dataForm.calendarMonthly || !this.dataForm.calendarMonthly.length) {
        this.dataForm.calendarMonthly = calendarMonthly;
      } else {
        calendarMonthly.forEach((month: any) => {
          const exist = this.dataForm.calendarMonthly.find(m => m.key === month.key);
          if (exist) {
            month.tempValue = exist.value;
            month.value = exist.value;
          }
        })
        this.dataForm.calendarMonthly = calendarMonthly;
      }
      this.calendarMonthlyPopup = true;
    }

  }

  generateCalendarWeekly(fromDate: Date, toDate: Date) {
    let start = moment(fromDate).clone().startOf('isoWeek');
    let end = moment(toDate).clone().endOf('isoWeek');
    let current = start.clone();
    let week: any[] = [];
    let weeks: any[] = [];
    while (current.isBefore(end)) {
      let startWeekDay;
      let endWeekDay;
      for (let i = 0; i < 7; i++) {
        if (current.isBetween(fromDate, toDate, null, '[]')) {
          week.push({
            date: current.clone(),
            isWithinRange: true,
          });
          if (!startWeekDay) {
            startWeekDay = current.clone();
          }
          endWeekDay = current.clone();
        } else {
          week.push({
            date: current.clone(),
            isWithinRange: false,
          });
        }
        current.add(1, 'day');
      }
      if (startWeekDay && endWeekDay) {
        weeks.push({
          start: startWeekDay,
          end: endWeekDay,
          key: startWeekDay.format('YYYY-MM-DD') + '_' + endWeekDay.format('YYYY-MM-DD'),
          days: week,
          value: this.dataForm.amountEachWeek
        });
      }
      week = [];
    }
    return weeks;
  }

  generateCalendarMonthly() {
    let current = moment(this.dataForm.fromDate).startOf('month');
    let month: any[] = [];
    let start: any = null;
    let end: any = null;
    while (current.isBefore(this.dataForm.toDate)) {
      if (!start) {
        start = moment(this.dataForm.fromDate);
      } else {
        start = current.clone();
      }
      end = start.clone().endOf('month');
      if (end.isAfter(this.dataForm.toDate)) {
        end = moment(this.dataForm.toDate);
      }
      const weeks = this.generateCalendarWeekly(start.toDate(), end.toDate());
      month.push({
        month: start.format('MMM YYYY'),
        start: start,
        end: end,
        key: start.format('YYYY-MM-DD') + '_' + end.format('YYYY-MM-DD'),
        weeks: weeks,
        value: this.dataForm.amountEachMonth
      });
      current.add(1, 'month');
    }
    return month;
  }

  addAmount(type) {
    if (type === 'week') {
      this.dataForm.calendarWeekly.forEach(week => {
        week.value = week.tempValue;
      });
      this.dataForm.calendarMonthly = [];
      this.dataForm.amountEachWeek = null;
      this.dataForm.amountEachMonth = null;
      this.calendarWeeklyPopup = false;
    }
    if (type === 'month') {
      this.dataForm.calendarMonthly.forEach(month => {
        month.value = month.tempValue;
      });
      this.dataForm.calendarWeekly = [];
      this.dataForm.amountEachWeek = null;
      this.dataForm.amountEachMonth = null;
      this.calendarMonthlyPopup = false;
    }
  }

  addDateOther() {
    if (this.dataForm.exception.otherDay) {
      this.dataForm.exception.otherDayStr += moment(this.dataForm.exception.otherDay).format('MM/DD/YYYY') + ', ';
      this.dataForm.exception.otherDays.push(moment(this.dataForm.exception.otherDay).format('MM/DD/YYYY'));
      this.dataForm.exception.otherDay = null;
    }
  }

  cancelException() {
    this.dataForm.exception = {
      otherDay: null,
      otherDayStr: '',
      otherDays: []
    }
    this.showDialogException = false;
  }

  onSelectOtherDaysChange() {
    if (!this.dataForm.exception.selectOther) {
      this.dataForm.exception.otherDay = null;
      this.dataForm.exception.otherDayStr = '';
      this.dataForm.exception.otherDays = [];
    }
  }

  onEmployeeFormChange(type) {
    if (type === 1) {
      this.dataForm.employees = [];
    } else {
      this.dataForm.companyName = null;
      this.dataForm.chineseName = null;
      this.dataForm.englishName = null;
      this.dataForm.hourlyRate = null;
      this.dataForm.position = null;
      this.dataForm.startWorkDate = null;
      this.dataForm.leftCompanyDate = null;
    }
  }

  isValidEmployeeForm() {
    return this.dataForm.companyName && this.dataForm.englishName && this.dataForm.hourlyRate && this.dataForm.position && this.dataForm.startWorkDate;
  }

  pasteFromClipboard(type: string) {
    navigator.clipboard.readText().then(
      clipText => {
        if (!clipText) {
          return;
        }
        const number = clipText.trim();
        if (this.isValidNumber(number)) {
          if (type === 'week') {
            if (!this.dataForm.fromDate || !this.dataForm.toDate) {
              return;
            }
            this.dataForm.amountEachWeek = this.roundNumber(Number(number));
            this.onAmountChange(type);
          }
          if (type === 'month') {
            if (!this.dataForm.fromDate || !this.dataForm.toDate) {
              return;
            }
            this.dataForm.amountEachMonth = this.roundNumber(Number(number));
            this.onAmountChange(type);
          }
        } else {
          console.warn('Clipboard content is not a valid number');
        }
      },
      err => {
        console.error('Failed to read clipboard contents: ', err);
      }
    );
  }

  isValidNumber(value: string): boolean {
    const numberRegex = /^\d+(\.\d*)?$/;
    return numberRegex.test(value);
  }

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }


  getDayOfWeek(year: number, month: number, week, day) {
    let date = new Date(year, month - 1, 1);
    while (date.getDay() !== day) {
      date.setDate(date.getDate() + 1);
    }
    date.setDate(date.getDate() + (7 * (week - 1)));
    if (date.getMonth() !== (month - 1)) {
      return null;
    }
    return this.datePipe.transform(date, 'MM/dd/YYYY');
  }

  getLastMondayOfMonth(year: number, month: number) {
    let date = new Date(year, month, 0);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() - 1);
    }
    return this.datePipe.transform(date, 'MM/dd/YYYY');
  }

  saveCacheDataWorkTimeNote() {
    const key = 'CacheDataWorkTimeNote_' + this.authService.getCurrentLoggedInId();
    localStorage.setItem(key, this.dataForm.workTimeNote);
  }

  saveCacheDataWagesPaymentNote() {
    const key = 'CacheDataWagesPaymentNote_' + this.authService.getCurrentLoggedInId();
    localStorage.setItem(key, this.dataForm.wagesPaymentNote);
  }
}
