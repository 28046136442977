import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { AuthService } from '../shared/service/auth/auth.service';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AppEmailComponent } from './component/app-email/app-email.component';
import { EmailListComponent } from './component/app-email-list/app-email-list.component';
import { EmployeeClockingService } from '../employee-clocking/service/employee-clocking.service';
import { EmployeeClockingModule } from '../employee-clocking/employee-clocking.module';
import { AdminAuthService } from './service/calllog/admin-auth.service';
import { OvertimeConfirmationService } from '../employee/service/overtime-confirmation.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { EmailLayoutComponent } from './component/app-email-layout/email-layout.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import {ClickOutsideModule} from "ng-click-outside";

@NgModule({
    imports: [
        CommonModule,
        LayoutRoutingModule,
        SharedModule,
        PrimeNgModule,
        EmployeeClockingModule,
        ProgressSpinnerModule,
        NgxDaterangepickerMd.forRoot(),
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,
        NgxMatNativeDateModule,
        ClickOutsideModule,
        NgxSummernoteModule
    ],
    declarations: [
        DashboardComponent,
        AppEmailComponent,
        EmailListComponent,
        EmailLayoutComponent
    ],
    exports: [],
    providers: [
        AuthService,
        EmployeeClockingService,
        AdminAuthService,
        OvertimeConfirmationService
    ]
})
export class LayoutModule {
}
