<div class="card add-shadow p-4">
  <div class="ui-g">
    <div class="ui-g-4 ui-sm-12 pl-0">
      <p-panel>
        <p-header class="flex-grow-1">
          <span>{{'Step 1: Select date range' | translate}}</span>
        </p-header>
        <div class="ui-g">
          <div class="ui-g-12">
            <label>{{'Date range' | translate}}</label>
            <div class="input-group w-100">
              <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button"
                  style="cursor:default !important">
                  <i class="fa-solid fa-calendar"></i>
                </button>
              </div>
              <mat-form-field>
                <mat-date-range-input [rangePicker]="picker">
                  <input #startDateInput matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="dataForm.fromDate" name="Startdate" (focus)="picker.open()">
                  <input #endDateInput matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="dataForm.toDate" name="Enddate" (focus)="picker.open()">
                </mat-date-range-input>
                <mat-date-range-picker #picker (closed)="startDateInput.blur(); endDateInput.blur(); onDateRangeChange()"></mat-date-range-picker>
              </mat-form-field>
              <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="showDialogException = true">
                  <i class="fa-solid fa-hand"></i>
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </p-panel>
    </div>
    <div class="ui-g-8 ui-sm-12">
      <p-panel>
        <p-header class="flex-grow-1">
          <span>{{'Step 2: Give the amount for each week or month' | translate}}</span>
        </p-header>
        <div class="ui-g">
          <div class="ui-g-6 ui-sm-12">
            <div class="d-flex align-items-center"><label>{{'Amount each week' | translate}}</label> <i *ngIf="isAmountEachWeekValid()" class="fa-solid fa-check text-success ml-2"></i></div>
            <div class="input-group w-100">
              <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                  <i class="fa-solid fa-dollar-sign"></i>
                </button>
              </div>
              <input type="text" pInputText [(ngModel)]="dataForm.amountEachWeek" (ngModelChange)="onAmountChange('week')" [disabled]="!dataForm.fromDate || !dataForm.toDate" twoDigitDecimaNumber>
              <i *ngIf="dataForm.amountEachWeek" class="remove-icon fa fa-xmark text-red" style="color: red !important; right: 4px;" (click)="dataForm.amountEachWeek = null; onAmountChange('week')"></i>
              <button class="btn btn-primary" id="button-addon2" type="button" (click)="pasteFromClipboard('week')" pTooltip="{{'Paste' | translate}}">
                <i class="fa-solid fa-clipboard"></i>
              </button>
              <button class="btn btn-danger d-flex align-items-center" id="button-addon2" type="button" (click)="openEditAmountPopup('week')" pTooltip="{{'Add amount for each' | translate}}">
                <span style="padding-right: 2px;">+</span> <i class="fa-solid fa-dollar-sign"></i>
              </button>
            </div>
          </div>

          <div class="ui-g-6 ui-sm-12">
            <div class="d-flex align-items-center"><label>{{'Amount each month' | translate}}</label> <i *ngIf="isAmountEachMonthValid()" class="fa-solid fa-check text-success ml-2"></i></div>
            <div class="input-group w-100">
              <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                  <i class="fa-solid fa-dollar-sign"></i>
                </button>
              </div>
              <input type="text" pInputText [(ngModel)]="dataForm.amountEachMonth" (ngModelChange)="onAmountChange('month')" [disabled]="!dataForm.fromDate || !dataForm.toDate" twoDigitDecimaNumber>
              <i *ngIf="dataForm.amountEachMonth" class="remove-icon fa fa-xmark text-red" style="color: red !important; right: 4px;" (click)="dataForm.amountEachMonth = null; onAmountChange('month')"></i>
              <button class="btn btn-primary" id="button-addon2" type="button" (click)="pasteFromClipboard('month')" pTooltip="{{'Paste' | translate}}">
                <i class="fa-solid fa-clipboard"></i>
              </button>
              <button class="btn btn-danger d-flex align-items-center" id="button-addon2" type="button" (click)="openEditAmountPopup('month')" pTooltip="{{'Add amount for each' | translate}}">
                <span>+</span> <i class="fa-solid fa-dollar-sign"></i>
              </button>
            </div>
          </div>
        </div>
      </p-panel>
      
    </div>
  </div>

  <p-panel>
    <p-header class="flex-grow-1">
      <span>{{'Step 3: Select Employee(s)' | translate}}</span>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-6 ui-sm-12">
        <div class="ui-g-12">
          <label>{{'Company Name' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-building"></i>
              </button>
            </div>
            <input type="text" pInputText [(ngModel)]="dataForm.companyName" (ngModelChange)="onEmployeeFormChange(1)">
          </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
          <label>{{'Native Language Name' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-user"></i>
              </button>
            </div>
            <input type="text" pInputText [(ngModel)]="dataForm.chineseName" (ngModelChange)="onEmployeeFormChange(1)">
          </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
          <label>{{'English Name' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-user"></i>
              </button>
            </div>
            <input type="text" pInputText [(ngModel)]="dataForm.englishName" (ngModelChange)="onEmployeeFormChange(1)">
          </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
          <label>{{'Hourly Rate' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-dollar-sign"></i>
              </button>
            </div>
            <input type="text" pInputText [(ngModel)]="dataForm.hourlyRate" (ngModelChange)="onEmployeeFormChange(1)" twoDigitDecimaNumber>
          </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
          <label>{{'Position' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-briefcase"></i>
              </button>
            </div>
            <input type="text" pInputText [(ngModel)]="dataForm.position" (ngModelChange)="onEmployeeFormChange(1)">
          </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
          <label>{{'Start work date' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-calendar-day"></i>
              </button>
            </div>
            <mat-form-field class="w-100">
              <input class="w-100" matInput (click)="pickerStartWorkDate.open()" [matDatepicker]="pickerStartWorkDate" [(ngModel)]="dataForm.startWorkDate" (ngModelChange)="onEmployeeFormChange(1)">
              <mat-datepicker #pickerStartWorkDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
          <label>{{'End work date' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-calendar-day"></i>
              </button>
            </div>
            <mat-form-field class="w-100">
              <input class="w-100" matInput (click)="pickerLeftCompanyDate.open()" [matDatepicker]="pickerLeftCompanyDate" [(ngModel)]="dataForm.leftCompanyDate" (ngModelChange)="onEmployeeFormChange(1)">
              <mat-datepicker #pickerLeftCompanyDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="ui-g-6 ui-sm-12">
        <div class="ui-g-12">
          <label>{{'Select Employee(s)' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-user"></i>
              </button>
            </div>
            <p-multiSelect [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="employeeList" [(ngModel)]="dataForm.employees" dataKey="id" (ngModelChange)="onEmployeeFormChange(2)">
              <ng-template pTemplate="item" let-item>
                <span class="text-truncate">{{item.label | translate}}</span>
              </ng-template>
              <ng-template let-item pTemplate="selectedItem">
                <span class="one-liner">{{item.label | translate}}</span>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12">
          <label>{{'Select second Language' | translate}}</label>
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-earth-americas"></i>
              </button>
            </div>
            <p-dropdown [options]="languages" [(ngModel)]="dataForm.lang" [autoDisplayFirst]="false" [showClear]="true">
              <ng-template pTemplate="item" let-item>
                <span class="text-truncate">{{item.label | translate}}</span>
              </ng-template>
              <ng-template let-item pTemplate="selectedItem">
                <span class="one-liner">{{item.label | translate}}</span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </p-panel>

  <p-panel>
    <p-header class="flex-grow-1">
      <span>{{'Step 4: Add notes' | translate}}</span>
    </p-header>
    <div class="ui-g">
      <div class="ui-g-6 ui-sm-12">
        <h3 style="font-weight: bold;">{{'Work time confimation form Notes' | translate}}</h3>
        <textarea pInputTextarea style="width: 100%" [rows]="8" class="acceptance-inputs" (input)="saveCacheDataWorkTimeNote()"
            placeholder="{{'Maximum length of notes is 5000' | translate}}" maxlength="5000" [(ngModel)]="dataForm.workTimeNote">
        </textarea>
      </div>

      <div class="ui-g-6 ui-sm-12">
        <h3 style="font-weight: bold;">{{'Wages Payment confimation form Notes' | translate}}</h3>
        <textarea pInputTextarea style="width: 100%" [rows]="8" class="acceptance-inputs" (input)="saveCacheDataWagesPaymentNote()"
            placeholder="{{'Maximum length of notes is 5000' | translate}}" maxlength="5000" [(ngModel)]="dataForm.wagesPaymentNote">
        </textarea>
      </div>
    </div>
  </p-panel>

  <div class="ui-g mt-2">
    <div class="w-100 d-flex justify-content-center">
      <button (click)="reset()" class="btn btn-danger mr-1">{{'Reset' | translate}}</button>
      <button (click)="generate()" class="btn btn-primary mr-l" [disabled]="generating"><i *ngIf="generating" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Generate' | translate}}</button>
    </div>
  </div>
</div>

<p-dialog [(visible)]="calendarWeeklyPopup" *ngIf="calendarWeeklyPopup" [modal]="true" [style]="{ 'width': '800px' }" header="{{'Add amount for each week' | translate}}">
  <div class="calendar">
    <div class="header pt-2 pl-4" style="text-align: left;">
      <h3 style="color: red; font-weight: 600;">{{ dataForm?.fromDate | date: 'MM/dd/yyyy' }} - {{ dataForm?.toDate | date: 'MM/dd/yyyy' }}</h3>
    </div>
    <div class="ui-g">
      <div class="ui-g-8">
        <div class="week-header">
          <span *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']">{{ day }}</span>
        </div>
        <div *ngFor="let week of dataForm.calendarWeekly" class="week">
          <div *ngFor="let day of week.days;" class="day">
            <span [ngClass]="{'within-range': day.isWithinRange, 'out-of-range': !day.isWithinRange}">
              {{ day.isWithinRange ? day.date?.date() : null }}
            </span>
            
          </div>
        </div>
      </div>
      <div class="ui-g-4">
        <div class="week-header"></div>
        <div *ngFor="let week of dataForm.calendarWeekly" class="week">
          <div class="input-group w-100">
            <div class="input-group w-100">
              <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                  <i class="fa-solid fa-dollar-sign"></i>
                </button>
              </div>
              <input type="text" pInputText [(ngModel)]="week.tempValue" twoDigitDecimaNumber>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer class="d-flex justify-content-center mt-2">
    <button class="btn btn-danger mr-1" (click)="calendarWeeklyPopup = false">
			<span><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</span>
		</button>
		<button class="btn btn-primary ml-1" (click)="addAmount('week')">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Add' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="calendarMonthlyPopup" *ngIf="calendarMonthlyPopup" [modal]="true" [style]="{ 'width': '800px' }" header="{{'Add amount for each month' | translate}}">
  <div class="calendar">
    <div class="header pt-2 pl-4" style="text-align: left;">
      <h3 style="color: red; font-weight: 600;">{{ dataForm?.fromDate | date: 'MM/dd/yyyy' }} - {{ dataForm?.toDate | date: 'MM/dd/yyyy' }}</h3>
    </div>
    <div class="ui-g">
      <div class="ui-g-8 pb-0">
        <div class="week-header">
          <span *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']">{{ day }}</span>
        </div>
      </div>
    </div>
    <div class="ui-g" *ngFor="let month of dataForm.calendarMonthly">
      <div class="ui-g-8">
        <div *ngFor="let week of month.weeks" class="week">
          <div *ngFor="let day of week.days;" class="day">
            <span [ngClass]="{'within-range': day.isWithinRange, 'out-of-range': !day.isWithinRange}">
              {{ day.isWithinRange ? day.date?.date() : null }}
            </span>
            
          </div>
        </div>
      </div>
      <div class="ui-g-4 d-flex align-items-center">
        <div class="input-group w-100">
          <div class="input-group w-100">
            <div class="input-group-prepend p-0">
              <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                <i class="fa-solid fa-dollar-sign"></i>
              </button>
            </div>
            <input type="text" pInputText [(ngModel)]="month.tempValue" twoDigitDecimaNumber>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer class="d-flex justify-content-center">
    <button class="btn btn-danger mx-1" (click)="calendarMonthlyPopup = false">
			<span><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</span>
		</button>
		<button class="btn btn-primary mx-1" (click)="addAmount('month')">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Add' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>


<p-dialog showEffect="fade" [style]="{'width':'60%'}" [closable]="true" [(visible)]="showDialogException" modal="modal" [dismissableMask]="true">
  <p-header>
    <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Add Exception' | translate}}</span>
  </p-header>
  <div class="row mb-2 p-4" style="min-height: 400px;">
    <div class="row mx-0 mb-2 p-2">
      <div class="ui-g-12">
        <label>{{'Remove all the Federal Holidays' | translate}}:</label>
        <p-multiSelect defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}" [options]="federalHolidays" [(ngModel)]="dataForm.exception.federalHoliday" id="id_filteredEmployeeList_to_selected_label">
          <ng-template pTemplate="item" let-item>
            <span class="one-liner" style="width: 90% !important">{{item.label | translate}}</span>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="row mx-0 mb-2 align-items-center">
      <div class="ui-g-12">
        <span style="font-size: 16px"><p-checkbox [(ngModel)]="dataForm.exception.selectOther" (onChange)="onSelectOtherDaysChange()" [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>{{'Remove other days' | translate}}</span>
      </div>
    </div>
  
    <div class="row mx-0 mb-2 align-items-center px-0" *ngIf="dataForm.exception.selectOther">
      <div class="input-group w-100">
        <div class="input-group-prepend p-0">
          <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
            <i class="fa-solid fa-calendar-xmark"></i>
          </button>
        </div>
        <mat-form-field class="d-flex flex-grow-1">
          <input matInput (focus)="picker3.open()" [matDatepicker]="picker3" [(ngModel)]="dataForm.exception.otherDay" style="flex-grow: 1; display: flex;">
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
        <div class="input-group-prepend p-0">
          <button class="btn btn-primary" type="button" style="cursor:default !important" (click)="addDateOther()">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row mx-0 mb-2 align-items-center" *ngIf="dataForm.exception.selectOther">
      <textarea pInputTextarea [rows]="4" placeholder="{{'select other days' | translate}}" [(ngModel)]="dataForm.exception.otherDayStr"></textarea>
    </div>
  </div>
  

	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12 p-1" align="center">
				<button type="button" class="btn btn-danger mx-1" pButton label="Cancel" (click)="cancelException()" ></button>
				<button type="button" class="btn btn-primary mx-1" pButton label="Confirm" (click)="showDialogException = false"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>


