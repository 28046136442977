import { Employee } from "../../employee/model/employee.model";
import { DocumentData } from "../../shared/model/document-data.model";
import {PayrollSetting} from "../../payroll-setting/model/payroll-setting.model";

export class EmployeeClocking {
    id:number;
    createdAt: any;
    updatedAt: any;
    employee:Employee;
    checkInTime:any;
    checkOutTime:any;
    totalTime:any;
    checkInTimeOriginal:any;
    outForLunchOriginal:any;
    backFromLunchOriginal:any;
    checkOutTimeOriginal:any;
    checkInSignature:Date;
    checkOutSignature:Date;
    checkInTimeStr: string;
    checkOutTimeStr: string;
    day: number;
    list: EmployeeClocking[];
    payrollSetting: PayrollSetting;
    overTime: number;
    hasOvertime: boolean;
    lunchInTime?: any;
    lunchOutTime?: any;
    lunchHours?: string;
    modifiedByUserName: string;
    vacationAmount: any;
    sickAmount: any;
    disableEditAllowed: boolean;
    companyId: any;
    location: any;
    clockInTime: any;
    clockOutTime: any;
    totalWorkTime: any;
    spreadHour: any;
    workHours: any;
    workHourShadow: any;
    totalWorkTimeShadow: any;
    spreadHourText: any;
    locationName: any;
    updateType: number; // 0 check-in, 1 lunch-out, 2 Lunch-in, 3 Check-Out
    totalWorkingStr: string;
    totalWorking: number;
    clockInStr: any;
    clockOutStr: any;
    lunchInStr: any;
    lunchOutStr: any;
    workHoursNew: any;
}
