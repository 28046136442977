<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="d-flex align-items-center justify-content-between py-4">
	<div class="d-flex">
		<h1 class="m-0 ml-2">{{'Other Settings' | translate}}</h1>
		<h1 *ngIf="isPlatformAdmin && company && company.name" class="font-weight-bold m-0 ml-1">({{company.name}} company)</h1>
	</div>
	<button *ngIf="mode == 'view'" class="btn btn-info round" (click)="editCompanySetting()" pTooltip="{{'Edit' | translate}}" tooltipPosition="left"><i class="fa-solid fa-pen-to-square"></i></button>
	<button *ngIf="mode == 'edit' || mode == 'update'" (click)="onCancel()" class="btn btn-danger round" pTooltip="{{'Cancel' | translate}}" tooltipPosition="left"><i class="fa-solid fa-xmark"></i></button>
</div>
<form [formGroup]="companySettingsForm" #settingsForm [name]="settingsForm">
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><p-checkbox [(ngModel)]="companySettingFormData.selectedAllShortcuts" (onChange)="selectAll()" [ngModelOptions]="{standalone: true}" binary="true" [disabled]="readOnly" class="mr-2" pTooltip="{{'Select All' | translate}}"></p-checkbox>{{'Shortcuts' | translate}} ({{companySettingCheckbox ? companySettingCheckbox?.length : 0}})</span>
		</p-header>
		<div class="ui-g">
			<div *ngFor="let item of companySettingCheckbox" class="ui-g-3 ui-sm-6">
				<span class="d-flex text-truncate"><p-checkbox [(ngModel)]="item.isSelected" name="{{item.name}}" value="{{item.value}}" binary="true" aria-checked="item.isSelected" [ngModelOptions]="{standalone: true}" [disabled]="readOnly" style="display: flex; align-items: center;"></p-checkbox>{{item.label}}</span>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Company Branding' | translate}}</span>
			<span class="mr-3" (click)="historyClick('branding')" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}} </span>
		</p-header>
		<div class="ui-g align-items-center">
			<div class="ui-g-6 ui-sm-12 p-0">
				<div class="ui-g">
					<div class="ui-g-12 ui-sm-12">
						<label>{{'Upload' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-paperclip"></i>
								</button>
							</div>
							<p-fileUpload mode="basic" [style.display]="'none'" #profilePhoto name="profileFiles[]" url="./upload.php" customUpload="true" accept="image/*" (uploadHandler)="uploadProfilePhoto($event,profilePhoto)" [disabled]="readOnly" chooseLabel="Choose file" auto="auto"></p-fileUpload>
							<input *ngIf="logoUrl" type="text" pInputText placeholder="{{logoUrl}}" disabled>
							<input *ngIf="!logoUrl" type="text" pInputText placeholder="{{'Upload Logo' | translate}}" readonly>
							<div class="input-group-append">
								<button class="btn btn-white" id="button-addon2"  (click)="openFileSelector()" type="button">
									<i class="fa-solid fa-upload text-primary"></i>
								</button>
							</div>
						</div>
					</div>
					<div class="ui-g-12">
						<label>{{'Text Field 1 (Main White Label)' | translate}}</label>
						<textarea [rows]="2" pInputTextarea formControlName="mainWhiteLabel" placeholder="Main White Label" class="inputTextarea" (keyup)="countRemainCharacterText1($event)" [(ngModel)]="companySettingFormData.mainWhiteLabel" [disabled]="readOnly" maxlength="150"></textarea>
						<span class="ui-message ui-messages-error" *ngIf="companySettingsForm.controls['mainWhiteLabel'].invalid && companySettingsForm.controls['mainWhiteLabel'].dirty ">Main White Label is required</span>
						<div class="ml-1">{{remainCharText1}} {{ 'character remaining.'}}</div>
					</div>
					<div class="ui-g-12">
						<label>{{'Text Field 2 (Upper Left Label)' | translate}}</label>
						<textarea [rows]="2" pInputTextarea formControlName="upperLeftLabel" placeholder="Upper Left Label" class="inputTextarea" (keyup)="countRemainCharacterText2($event)" [(ngModel)]="companySettingFormData.upperLeftLabel" [disabled]="readOnly"  maxlength="150"></textarea>
						<div class="ml-1">{{remainCharText2}} {{ 'character remaining.'}}</div>
						<div class="ui-g-12 custom-error-message left-padding-0">
							<span class="ui-message ui-messages-error" *ngIf="companySettingsForm.controls['upperLeftLabel'].invalid && companySettingsForm.controls['upperLeftLabel'].dirty ">Upper Left Label is required</span>
						</div>
					</div>

				</div>
			</div>
			<div class="ui-g-6 ui-sm-12 text-right">
				<img *ngIf="logoUrl" src="{{logoUrl}}" class="company-logo"/>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Promotional Content' | translate}}</span>
			<span class="mr-3" (click)="historyClick('promotional')" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}} </span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client Dashboard F1' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dashboard"></i>
						</button>
					</div>
					<input type="text"  pInputText [(ngModel)]="companySettingFormData.clientDashboardF1" formControlName="clientDashboardF1" id="clientDashboardF1"/>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="companySettingsForm.controls['clientDashboardF1'].invalid && companySettingsForm.controls['clientDashboardF1'].dirty ">{{'Invalid URL' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client Dashboard F2' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-gauge-high"></i>
						</button>
					</div>
					<input type="url" pInputText [(ngModel)]="companySettingFormData.clientDashboardF2" formControlName="clientDashboardF2" id="clientDashboardF2"/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Employee Dashboard F2' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dashboard"></i>
						</button>
					</div>
					<input type="url" pInputText [(ngModel)]="companySettingFormData.clientDashboardF3" formControlName="clientDashboardF3" id="clientDashboardF3"/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Promotional Text Content' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bullhorn"></i>
						</button>
					</div>
					<input type="url" pInputText [(ngModel)]="companySettingFormData.marquee" formControlName="marquee" id="marquee" placeholder="{{'Enter text' | translate}}"/>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Email Settings' | translate}}</span>
			<span class="mr-3" (click)="historyClick('emailSetting')" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}} </span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Protocol' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-code"></i>
						</button>
					</div>
					<p-dropdown [options]="drivers" formControlName="driver" [(ngModel)]="companySettingFormData.driver" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Host' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-server"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="host" [(ngModel)]="companySettingFormData.host" maxlength="100" class="w-100" id="host-input">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Name (Sender)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="fromName" [(ngModel)]="companySettingFormData.fromName" maxlength="100" class="w-100" id="from-name-input">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Port' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-ethernet"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="port" (keypress)="numberOnly($event)" [(ngModel)]="companySettingFormData.port" maxlength="10" class="w-100" id="port-input">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Email (Sender)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-at"></i>
						</button>
					</div>
					<input type="email" pInputText formControlName="fromAddress" [(ngModel)]="companySettingFormData.fromAddress" maxlength="100" class="w-100" id="address-input">
				</div>
				<span *ngIf="(companySettingsForm.controls['fromAddress'].invalid)&& companySettingsForm.controls['fromAddress'].touched && (companySettingsForm.controls['fromAddress'].hasError('invalidEmail'))">{{'validate.pleaseEnter' | translate:{value: 'Email' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Encryption' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-laptop-code"></i>
						</button>
					</div>
                    <p-dropdown [options]="encryptions" formControlName="encryption" [(ngModel)]="companySettingFormData.encryption" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Username' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-shield"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="userName" [(ngModel)]="companySettingFormData.userName" autocomplete="off" maxlength="100" class="w-100" id="userName-input">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Password' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-lock"></i>
						</button>
					</div>
					<input *ngIf="showPwd" pInputText type="text" id="password" formControlName="password" autocomplete="false" [(ngModel)]="companySettingFormData.password" name="password" class="w-100" />
					<input *ngIf="!showPwd"  pInputText type="password" id="password" formControlName="password" autocomplete="false" [(ngModel)]="companySettingFormData.password" name="password" class="w-100">
					<div class="input-group-append">
						<button type="button" class="btn btn-white" id="button-addon2" (click)="togglePwdVisibility()" id="password" formControlName="password" pTooltip="{{'Toggle Visibility' | translate}}" tooltipPosition="left" style="cursor:default !important">
							<i *ngIf="showPwd" class="fa-solid fa-eye-slash text-danger"></i>
							<i *ngIf="!showPwd" class="fa-solid fa-eye text-primary"></i>
						</button>
					</div>
				</div>		
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" id="table-ppanel">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Email Signature' | translate}}</span>
		</p-header>
		<p-editor #editor (onTextChange)="checkMaxLength($event)" [style]="{'height':'240px'}" formControlName="emailSignature" [modules]="quillConfig" [readonly]="readOnly" [(ngModel)]="companySettingFormData.emailSignature"></p-editor>
		<div *ngIf="editEditor" class="ml-1">{{remainCharEditor}} {{ 'character remaining.'}}</div>
	</p-panel>
	<br>

	<p-panel [toggleable]="true" id="table-ppanel" *ngIf="hasCompliancePermission()">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Compliances' | translate}}</span>
		</p-header>
		<p-panel [toggleable]="false" id="table-ppanel">
			<p-header class="flex-grow-1 row">
				<span><p-checkbox [(ngModel)]="companySettingFormData.showDocumentStatus" [ngModelOptions]="{standalone: true}" binary="true" [disabled]="readOnly" class="mr-2" pTooltip="{{'Select All' | translate}}"></p-checkbox>{{'Show Document Status Wizard after Login' | translate}} <i class="fa fa-info-circle" (click)="op1.toggle($event)" aria-hidden="true"></i></span>
			</p-header>
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1 row">
					<span class="font-weight-bold" style="font-size: 16px">{{'Mandatory Compliance' | translate}}</span>
				</p-header>
				<p-table #fdt [value]="requiredCompliances" dataKey="id" [responsive]="true" [columns]="cols">
					<ng-template pTemplate="header" let-columns>
						<tr class="text-left">
							<th>#</th>
							<th>
							<span class="one-liner">
								{{'Document Name' | translate}}
							</span>
							</th>
							<th class="text-center">
							<span class="one-liner">
								{{'Details' | translate}}
							</span>
							</th>
							<th>
								<span class="one-liner" pTooltip="{{'Material' | translate}}" tooltipPosition="left">{{'Material' | translate}}</span>
							</th>
							<th>
								<span class="one-liner">{{'Currently Comply with' | translate}}</span>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-i="rowIndex">
						<tr [pSelectableRow]="rowData">
							<td>{{i + 1}}</td>
							<td>
							<span class="one-liner">
								<span class="mobile-table-label text-nowrap">{{'Document Name' | translate}}: </span>
								{{rowData.documentName}}
							</span>
							</td>
							<td>
							<span class="one-liner" *ngIf="rowData.information && rowData.information.trim()">
								<i style="cursor: pointer;" class="ml-2 fa-solid fa-info-circle" (click)="information = rowData.information; documentInformation.show($event)"></i>
							</span>
							</td>
							<td>
								<i *ngIf="rowData.material" class="fa-solid fa-paperclip text-success" pTooltip="{{'Material' | translate}}" tooltipPosition="left" (click)="onViewDocument(rowData.material)"></i>
							</td>
							<td>
						<span class="one-liner">
							<span class="mobile-table-label text-nowrap">{{'Comply with' | translate}}: </span>
							<p-checkbox [(ngModel)]="rowData.comply" binary="true" name="required" [ngModelOptions]="{standalone: true}" [disabled]="readOnly" pTooltip="{{'Currently Comply with' | translate}}"></p-checkbox>
						</span>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</p-panel>
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1">
					<span class="font-weight-bold" style="font-size: 16px">{{'General Compliance' | translate}}</span>
				</p-header>
				<p-table #fdt [value]="otherCompliances" dataKey="id" [responsive]="true" [columns]="cols">
					<ng-template pTemplate="header" let-columns>
						<tr class="text-left">
							<th>#</th>
							<th>
							<span class="one-liner">
								{{'Document Name' | translate}}
							</span>
							</th>
							<th class="text-center">
							<span class="one-liner">
								{{'Details' | translate}}
							</span>
							</th>
							<th>
								<span class="one-liner" pTooltip="{{'Material' | translate}}" tooltipPosition="left">{{'Material' | translate}}</span>
							</th>
							<th>
								<span class="one-liner">{{'Currently Comply with' | translate}}</span>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-i="rowIndex">
						<tr [pSelectableRow]="rowData">
							<td>{{i + 1}}</td>
							<td>
							<span class="one-liner">
								<span class="mobile-table-label text-nowrap">{{'Document Name' | translate}}: </span>
								{{rowData.documentName}}
							</span>
							</td>
							<td>
							<span class="one-liner" *ngIf="rowData.information && rowData.information.trim()">
								<i style="cursor: pointer;" class="ml-2 fa-solid fa-info-circle" (click)="information = rowData.information; documentInformation.show($event)"></i>
							</span>
							</td>
							<td>
								<i *ngIf="rowData.material" class="fa-solid fa-paperclip text-success" pTooltip="{{'Material' | translate}}" tooltipPosition="left" (click)="onViewDocument(rowData.material)"></i>
							</td>
							<td>
						<span class="one-liner">
							<span class="mobile-table-label text-nowrap">{{'Comply with' | translate}}: </span>
							<p-checkbox [(ngModel)]="rowData.comply" binary="true" name="required" [ngModelOptions]="{standalone: true}" [disabled]="readOnly" pTooltip="{{'Currently Comply with' | translate}}"></p-checkbox>
						</span>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</p-panel>
		</p-panel>
	</p-panel>

	<div class="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button *ngIf="mode == 'edit' || mode == 'update'" (click)="onCancel()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button *ngIf="mode == 'view'" (click)="onCancel()" class="btn btn-orange mx-1"><i class="fa-solid fa-arrow-left mr-2"></i>{{'Back' | translate}}</button>
			<button *ngIf="mode == 'view'" type="submit" (click)="editCompanySetting()" class="btn btn-info mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit' | translate}}</button>
			<button *ngIf="mode == 'update'" type="submit" (click)="modifyCompanySetting(settingsForm)" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</div>
	</div>
</form>

<p-dialog baseZIndex="1000" *ngIf="displayEditModal" [(visible)]="displayEditModal" [modal]="true" showEffect="fade" [showHeader]="true" [dismissableMask]="true" [closable]="true"  [style]="{ 'width':'90%', 'max-width':'900px', 'max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}</span>
	</p-header>
	<app-company-other-setting-history [compaynId]="companySettingFormData.id" [historyType]="historyType"></app-company-other-setting-history>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="displayEditModal = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-overlayPanel #documentInformation appendTo="body">
	<div style=" max-width: 500px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			Information
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;" [innerHTML]="information">
		</div>
	</div>
</p-overlayPanel>

<p-dialog header="{{'View Document' | translate}}" [(visible)]="showViewFileDialog" [style]="{width: '50vw'}">
	<img class="w-100" [src]="fileUrl" alt="">
</p-dialog>

<p-overlayPanel #op1 [dismissable]="true" appendTo="body" style="padding: unset !important;">
	<div class="card bg-light m-0 p-0" style="max-width: 500px;">
		<div class="card-header"><i class="fa-solid fa-info-circle text-danger mr-2"></i>{{'information' | translate}}</div>
		<div class="card-body">
			<h5 class="card-title">{{'Select this option to display a reminder for any incomplete documents each time you log in.' | translate}}</h5>
		</div>
	</div>
</p-overlayPanel>

